import BigNumber from 'big.js';
import { API } from '@xbto/api-client';
import { SendDestinationType, SendForm } from './types';
import { Enriched, EnrichedCurrencyInformation } from '../../../types';
import { BigOrZero } from '../../big';
import { formatters } from '../../formatters';

export const getSendRemainingBalance = (
  ccyInfo: EnrichedCurrencyInformation | null,
  fundBalance: BigNumber,
  formValues: SendForm,
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if (
    simulation?.isExternal &&
    (formValues?.destinationType === SendDestinationType.crypto ||
      formValues?.destinationType === SendDestinationType.book)
  ) {
    const sendingAmount = BigOrZero(
      (simulation as Enriched.WithdrawalSimulation)?.sendingAmount
    );
    const fee = BigOrZero(
      (simulation as Enriched.WithdrawalSimulation)?.transactionFee
    );

    const amount = fundBalance.sub(sendingAmount).sub(fee);

    return formatters.getAmount(amount, ccyInfo?.decimals);
  }

  return formatters.getAmount(
    BigOrZero(fundBalance).minus(BigOrZero(formValues?.amount)),
    ccyInfo?.decimals
  );
};

export const getSendAmountSent = (
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if (!simulation) return '';

  return simulation.formatted.sendingAmountWithCurrencyCode;
};

export const getSendAmountWithdrawn = (
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  return simulation?.formatted.amountWithCurrencyCodeAndSign ?? '';
};

export const getSendFees = (
  ccyCode: string | null | undefined,
  formValues: SendForm,
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if (formValues?.destinationType === SendDestinationType.transfer) {
    return `FREE`;
  }
  if (!simulation || !ccyCode) {
    return ``;
  }
  const sim = simulation as Enriched.WithdrawalSimulation;
  if (sim.feeMode === API.WithdrawalFeeChargeMode.ChargedImmediately) {
    return sim.formatted.transactionFeeWithCurrencyCode;
  }

  return sim.formatted.invoicedFeeUsdWithCurrencyCode;
};
