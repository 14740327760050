import { API } from '@xbto/api-client';
import { Enriched } from '../../types';
import { formatters } from '../formatters';

export const enrichTrade = (trade: API.Trade | null): Enriched.Trade | null => {
  if (
    !trade ||
    !trade.currencyFrom ||
    !trade.currencyFrom.code ||
    !trade.currencyTo ||
    !trade.currencyTo.code
  ) {
    return null;
  }

  const amountFrom = formatters.getAmount(
    trade.amountFrom,
    trade.currencyFrom.decimals
  );
  const amountFromWithCurrencyCode = formatters.getCurrency(
    trade.amountFrom,
    undefined, // use fallback mantissa computation
    trade.currencyFrom.displayCode || trade.currencyFrom.code
  );
  const amountFromWithCurrencyCodeAndSign = formatters.forceSign(
    amountFromWithCurrencyCode,
    '-'
  );

  const amountTo = formatters.getAmount(
    trade.amountTo,
    trade.currencyTo.decimals
  );
  const amountToWithCurrencyCode = formatters.getCurrency(
    trade.amountTo,
    undefined, // use fallback mantissa computation
    trade.currencyTo.displayCode ?? trade.currencyTo.code
  );

  return {
    ...trade,
    formatted: {
      amountFrom,
      amountFromWithCurrencyCode,
      amountFromWithCurrencyCodeAndSign,
      amountTo,
      amountToWithCurrencyCode,
    },
  };
};
