import React, { FC } from 'react';
import cx from 'classnames';
import BigNumber from 'big.js';
import { formatters } from '../../utils';

export interface Props {
  prefix?: JSX.Element | string;
  value: BigNumber;
  showColor?: boolean;
  textSizeCls?: string;
  fontWeightCls?: string;
  cls?: string;
}
// TODO(Hadrien): Replace with UC AmountChangePercent or AmountPercent depending on usage
export const PriceCell: FC<Props> = ({
  prefix,
  value,
  showColor = true,
  cls = 'flex flex-row',
  textSizeCls = 'text-sm',
  fontWeightCls = 'font-bold',
}) => {
  return (
    <span
      className={cx(
        cls,
        textSizeCls,
        fontWeightCls,
        'gap-x-0.125',
        {
          'text-success': value.gt(0) && showColor,
        },
        {
          'text-failure': value.lt(0) && showColor,
        },
        {
          'text-primary': value.eq(0) && showColor,
        }
      )}
    >
      {!!prefix && <span className="text-primary">{prefix}</span>}
      {showColor && <span>{value.gt(0) ? '+' : ''}</span>}
      <span>{formatters.getAmount(value.toString(), 2)}%</span>
    </span>
  );
};
