import { FC } from 'react';
import { useEffectOnce } from 'react-use';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { OpenLayout } from '../layouts/open-layout';
import { DataStore } from '~/store';

export const AccountLocked: FC = () => {
  /**
   * Store
   */
  const { setAccessToken } = DataStore.useStoreActions(a => ({
    setAccessToken: a.user.setAccessToken,
  }));

  /**
   * Hooks
   */
  useEffectOnce(() => {
    setAccessToken(null);
  });

  /**
   * DOM
   */
  return (
    <OpenLayout
      icon="negative-circle-shield"
      title="Your account has been locked."
      cta={
        <Link
          role="button"
          className="app-button-primary mt-12"
          style={{ width: 275 }}
          to={APP_ROUTES.NON_AUTH_HOME}
        >
          Go to sign in
        </Link>
      }
      withAppWrapper
    >
      <>
        You will need to reset your password, please{' '}
        <strong>check your email</strong> for instructions to unlock your
        account.
        <br />
        <br />
        If you need further assistance please{' '}
        <Link to={APP_ROUTES.NON_AUTH_CONTACT_US}>
          <strong>contact support.</strong>
        </Link>
      </>
    </OpenLayout>
  );
};
