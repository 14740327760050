import { API } from '@xbto/api-client';

import {
  EnrichedCurrencyInformation,
  EnrichedInternalTransfer,
} from '../../types';
import { formatters } from '../formatters';
import { FIAT_SYMBOLS } from '../../constants';
import BigNumber from 'big.js';

export function createEnrichedInternalTransfer(
  transfer: API.InternalTransfer | API.InternalTransferWithTimeline,
  currencies: EnrichedCurrencyInformation[]
): EnrichedInternalTransfer {
  const amount = transfer.amount || '0';
  const amountUsd = transfer.amountUsd || '0';
  const sendingAmount = transfer.sendingAmount || '0';
  const sendingAmountUsd = transfer.sendingAmountUsd || '0';
  const fee = transfer.fee || '0';
  const feeUsd = transfer.feeUsd || '0';
  const totalAmountUsd = transfer.totalAmountUsd || '0';

  const currencyCode = transfer.currencyCode ?? '';
  const currency = currencyCode
    ? currencies.find(_currency => _currency.code === currencyCode)
    : undefined;

  const displayCurrencyCode = currency?.displayCode ?? currency?.code ?? '';

  const formattedAmount = formatters.getCurrency(
    amount,
    currency?.decimals,
    displayCurrencyCode
  );

  const formattedSendingAmount = `${sendingAmount} ${displayCurrencyCode}`;
  // formatters.getCurrency(
  //   sendingAmount,
  //   currency?.decimals,
  //   displayCurrencyCode,
  // );

  return {
    ...transfer,
    amount: BigNumber(amount),
    amountUsd: BigNumber(amountUsd),
    currencyCode,
    fee: BigNumber(fee),
    feeUsd: BigNumber(feeUsd),
    totalAmountUsd: BigNumber(totalAmountUsd),
    formattedAmount,
    formattedAmountAndSign: formatters.forceSign(formattedAmount, '+'),
    formattedAmountUsd: formatters.getBaseCurrency(amountUsd),
    formattedSendingAmount,
    formattedSendingAmountAndSign: formatters.forceSign(
      formattedSendingAmount,
      '-'
    ),
    formattedSendingAmountUsd: formatters.getBaseCurrency(sendingAmountUsd),
    formattedFee: FIAT_SYMBOLS[displayCurrencyCode]
      ? formatters.getCurrency(fee, currency?.decimals, displayCurrencyCode)
      : `${fee} ${displayCurrencyCode}`,
    formattedFeeUsd: formatters.getBaseCurrency(feeUsd),
    // TODO: Rename to formattedTotalAmountUsdWithCurrencyCode, formattedSendingAmountUsdWithCurrencyCode, etc...
    // TODO: Move to formatted.*
    formattedTotalAmountUsd: formatters.getBaseCurrency(totalAmountUsd),
    timeline: (transfer as API.InternalTransferWithTimeline).timeline ?? null,
  };
}
