import numbro from 'numbro';

import esES from 'numbro/languages/es-ES';
import frFR from 'numbro/languages/fr-FR';

import { DEFAULTS } from '../constants';
import { BigOrZero } from './big';

numbro.registerLanguage(esES);
numbro.registerLanguage(frFR);

export function setNumbroLanguage(languageTag: string) {
  numbro.setLanguage(languageTag, 'en-US');
}

export const getFallbackMantissa = (value?: number | string | null) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number
  //   > The mantissa's precision is 2-52 (obtainable via Number.EPSILON),
  //   > or about 15 to 17 decimal places; arithmetic above that level of precision is subject to rounding.
  // Given that blockchain number can have 18 decimals, we can end up with weird rounding :
  // numbro(12300.72).format({ mantissa: 18, trimMantissa: true })
  //   => 12,300.720000000001 instead of 12,300.72
  // So we read the decimal places from the number itself
  // read position to ensure reading AFTER . and manage integer
  // Some small numbers like 0.0000008 are converted automatically to 8e-7
  // by the browser when parsing JSON, this also take care of that
  const valueStr = value?.toString() || '';
  const result = BigOrZero(valueStr).toFixed().split('.')[1]?.length || 0;

  if (result <= 1) {
    return DEFAULTS.DECIMAL_SCALE;
  }
  return result;
};
