import jwtDecode from 'jwt-decode';
import { getValueOfKey } from './get-value-of-key';

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'stablehouse-auth-jwt',

  // TODO: Rename local storage key to start clean?
  // Unify local storage key with SECURE_KEYS.AUTH0_TOKEN ?
  // ACCESS_TOKEN: 'stablehouse-access-token',
};

export const JWT_KEYS = {
  REFERRER_FIRSTNAME: 'referrer-firstname',
  REFERRED: 'referred',
  IDENTIFIER:
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  STORAGE_KEY: 'stablehouse-auth-jwt',
  REQUIRES_EMAIL_VERIFICATION: 'RequiresEmailVerification',
  ENABLE_TALOS_TRADING: 'EnableTalosTrading',
};

export interface Identity {
  token: string;
  email: string;
  expiration: Date;
  id: string;
  username: string;
  accounts: string[];
  requiresEmailVerification: boolean;
  firstName: string;
  lastName: string;
  referredFirstname: string;
  referred: boolean;
  referralCode: string;
  enableTalosTrading: boolean;
}

export const decodeIdentity = (token: string | null): Identity | null => {
  if (!token) {
    return null;
  }

  try {
    const decoded = jwtDecode(token) as any;
    const { email, exp, Firstname, Lastname, referralCode } = decoded;
    const accounts = getValueOfKey<string[]>(
      'account-',
      decoded,
      // JWT currently has other meta data under key `account-type-X: Regular`
      ['account-type-']
    );
    const referredFirstname = decoded[JWT_KEYS.REFERRER_FIRSTNAME];
    const referred = decoded[JWT_KEYS.REFERRED] === 'True' || false;
    const needsVerification =
      decoded[JWT_KEYS.REQUIRES_EMAIL_VERIFICATION] === 'True' || false;
    const enableTalosTrading =
      decoded[JWT_KEYS.ENABLE_TALOS_TRADING] === 'True' || false;

    const endResult: Identity = {
      token,
      email,
      requiresEmailVerification: needsVerification,
      expiration: new Date(exp * 1000),
      id: decoded[JWT_KEYS.IDENTIFIER],
      username: `${Firstname} ${Lastname}`,
      accounts,
      firstName: Firstname,
      lastName: Lastname,
      referredFirstname,
      referred,
      referralCode,
      enableTalosTrading,
    };

    if (endResult.expiration.getTime() < new Date().getTime()) {
      return null;
    }
    return endResult;
  } catch (e) {
    console.error(e, 'error');
    return null;
  }
};

export const getJwtExpirationTime = (jwt: string): number | null => {
  try {
    const { exp } = jwtDecode<{ exp: number }>(jwt);

    return exp * 1000;
  } catch (err) {
    console.error(err, 'getJwtExpirationTime');
    return null;
  }
};
