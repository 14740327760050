import * as Yup from 'yup';

export const ERROR_MESSAGES = {
  DEFAULT: `An error occurred. Please try again`,
  NOT_APPLICABLE: 'N/A',
  REQUIRED_VALUE: `Value is required`,
  AMOUNT_MORE_THAN_0: `Amount must be greater than 0`,
  INVALID_VALUE: `Given value is invalid`,
  TAG_NOT_EXIST: `Given tag does not exist`,
  INVALID_DESTINATION: `Given destination information is invalid`,
  TAG_IS_SELF: `You can't send money to yourself`,
  ADDRESS_IS_SELF: `You can't send money to yourself`,
  INVALID_STABLETAG: `Given tag is invalid`,
  INVALID_BANK_CURRENCY_MISMATCH: `Currency used for withdrawal does not match bank account currency`,
  INVALID_BANK_ACCOUNT_NOT_VERIFIED: `The selected bank account has not been verified`,
  INVALID_IBAN: `This IBAN is invalid. Please enter a valid IBAN number`,
  INVALID_ABA: `This ABA / Routing number is invalid. Please enter a valid ABA / Routing number`,
  INVALID_BIC: `This BIC / Swift code is invalid. Please enter a valid BIC / Swift code`,
  INVALID_SORT_CODE: `This Sort code is invalid. Please enter a valid Sort code`,
  TWO_FA_ALLOWED_LENGTH: `Must be exactly 6 digits`,
  PASSWORD_MUST_MATCH: 'Passwords must match',
  INVALID_EMAIL: `Given email is invalid`,
  INVALID_REFERRAL_CODE: `Given referral code is invalid`,
  ONLY_DIGITS: `Must be only digits`,
  INVALID_PASSWORD: `1 digit | 1 special | min 8 | max 64 character(s)`,
  VERIFY_CODE_ALLOWED_LENGTH: `Must be exactly 4 digits`,
  MIN_2_CHARACTERS: 'Must be at least 2 characters long',
  MIN_8_CHARACTERS: 'Password must be at least 8 characters long',
  MAX_64_CHARACTERS: 'Password must be at most 64 characters long',
  MIN_1_SPECIAL_CHAR: 'Password must contain at least one special character',
  MIN_1_DIGIT: 'Password must contain at least one digit',
  MIN_1_CAPITAL: 'Password must contain at least one capital letter',
  MAX_2000_CHARACTERS: 'Can be at most 2,000 characters long',
  MAX_3_LETTER_COUNTRY_CODE: 'Must be a three letter country code',
  MAX_17_CHARACTERS: 'Can be at most 17 characters long',
  MAX_255_CHARACTERS: 'Can be at most 255 characters long',
  MAX_500_CHARACTERS: 'Can be at most 500 characters long',
  MAX_1024_CHARACTERS: 'Can be at most 1024 characters long',
  DEPOSITS_NOT_AVAILABLE:
    'Deposits are currently not available for this currency',
  WITHDRAWALS_NOT_AVAILABLE:
    'Withdrawals are currently not available for this currency',
  BIOMETRICS_FAIL: `Biometric verification failed. Please try again`,
  RECAPTCHA_FAIL: `Recaptcha verification failed. Please try again`,
  TWO_FA_CODE_INVALID: `Invalid Google Authenticator code`,
  INVALID_RECIPIENT: `Given recipient information is invalid. Please enter valid information`,
  WARN_CANNOT_DISABLE_ACCOUNT: `Cannot disable account with non-zero balance`,
  FIAT_DEPOSIT_EXISTS: `A previously initiated deposit transaction is still pending. Please follow below steps to deposit funds`,
  INSUFFICIENT_INVENTORY: `Stablehouse has insufficient inventory to complete transaction`,
  INSUFFICIENT_BALANCE: `Insufficient balance to complete transaction`,
  RATE_EXPIRED_RETRY: `The rate has expired, try again after confirming your new rate`,
  API_502:
    'We are performing an update shortly. Services are expected to be unavailable for less than 45 minutes.',
  API_503:
    'We are performing an update shortly. Services are expected to be unavailable for less than 45 minutes.',
  API_403:
    'You are not authorized to perform this operation. Please contact support.',
  API_401:
    'You are not authorized to perform this operation. Please contact support.',
  API_429:
    'Your application has sent too many requests in a given amount of time',
  CONTACT_US_FAILED: `Something went wrong. Please try again.`,
  UNPROCESSABLE_ENTITY: `Unprocessable Entity`,
  STATEMENT_DOWNLOAD: 'Could not download statement',
  STATEMENT_DOWNLOAD_MISSING: 'No statement available',
  LOGOUT_SECURITY:
    'You were logged out for security reasons.\nPlease sign in again.',
  UNAUTHORIZED_COUNTRY: 'Your bank country is not authorized',
  INVALID_COUNTRY_BIC: `Invalid bank country for selected BIC / Swift code.`,
  INVALID_ACCOUNT_ID: 'This account id does not exist',
  INVALID_COUNTRY_ABA: `Invalid bank country for selected ABA / Routing number.`,
};

export const DEFAULTS = {
  DECIMAL_SCALE: 2,
  MAX_DECIMALS: 18,
  PAGE: 1,
  PAGE_SIZE: 20,
  MIN_AGE: 18,
  MIN_TWO_FA_LENGTH: 6,
  MAX_TWO_FA_LENGTH: 6,
  TWO_CODE_LENGTH: 6,
  VERIFICATION_CODE_LENGTH: 6,
  CREDIT_CARD_PAYMENT_REFRESH_TIMER: 5000,
  CHAR_LENGTH_2: 2,
  CHAR_LENGTH_255: 255,
  CHAR_LENGTH_17: 17,
  CHAR_LENGTH_500: 500,
  MAX_EXPORT_RECORDS: 500,
  TFA_LENGTH: 6,
  PIN_LENGTH: 4,
  INPUT_MAX_LENGTH: 1024,
};

export const TOAST_OPTIONS = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const DATE_FORMATS = {
  DEFAULT: 'dd MMM yyyy HH:mm a z',
  DD_MMM_YYYY: 'dd MMM yyyy',
  MMM_YYYY: 'MMM yyyy',
  SHORTHAND_DATE: 'MM.dd.yyyy',
  HH_MM_TZ: 'HH:mm a z',
  SHORTMONTH_D_YYYY: 'MMM d yyyy',
  D_SHORTMONTH_YYYY: 'd MMM yyyy',
  MONTH_D_YYYY: 'LLLL d yyyy',
  MONTH_DDO: 'LLLL do',
  DD_MONTH_YYYY: 'dd LLLL yyyy',
  H_MM_AAA_Z: 'H:mm z',
  D_LLL_Y: 'd LLL y -',
  PPP: 'PPP',
  Pp: 'Pp',
  H_MM_D_LLL_Y: 'H:mm - d LLL y',
  RECEIPT: 'd LLL y - H:mm',
  RECEIPT_Z: 'd LLL y',
  MM_dd_yyyy: 'MM/dd/yyyy',
};

// Note: months in javascript start at 0
export const referAFriendBonusLastDate = new Date(2022, 7, 31);
export const welcomeBonusLastDate = new Date(2022, 6, 31);

export const TIMERS = {
  TOAST: 3500, // 3.5 seconds,
  PING: 60000 * 10, // 1 minute * X
  INPUT_DEBOUNCE: 300,
  SIMULATE_DEBOUNCE: 850,
  RETRY_SIMULATION: 5000,
  BANNER_REFRESH: 5000,
  AUTO_RESIMULATE_IN_SECONDS: 5,
  PUSHER_DEBOUNCE: 4000,
  SHOW_MESSAGE: 5000,
  COPY_FEEDBACK: 3000,
  FORMIK_DEBOUNCE: 300,
  HEALTHCHECK_PING: 10_000,
  AUTH_CHECK: 5000,
  POLL_CURRENCIES: 60 * 1000 * 15, // 15 min
  EXIT_500: 2000,
  MOBILE_OTA_CHECK_UPDATES_TIMEOUT: 10000,
  MOBILE_OTA_RELOAD_APP: 300,
  BOTTOM_SHEET_NAVIGATION_TRANSITION: 300,
};

export const API_ERROR_CODES = {
  RATE_EXPIRED: [2009, 40000],
  LOGIN_ERROR: 5003,
  INVALID_REQUEST: 1002,
  USER_ACOUNT_LOCKED: 5020,
  USER_ACOUNT_DISABLED: 5005,
  USER_PIN_NOT_FOUND: 5040,
  ACCOUNT_LOCKED_FAILED_CONFIRM_ATTEMPTS: 5021,
  WITHDRAW_TRANSFER_ABORTED: 4007,
  LOGIN_UNSUPPORTED_AUTHENTICATION: 5011, // happens when say a user tries to login with legacy auth when Auth0 is enabled for the user
  INVALID_ACCOUNT_ID: 1022,
  UNKNOWN_ACCOUNT_ID: 1030,
};

export const API_HTTP_CODES = {
  UNAUTHORIZED: 401,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
};

export const REGEX = {
  ONLY_DIGITS: /^[0-9]+$/,
  /**
   * This REGEX is to validate a given stabletag
   * 1. Start with `@` character
   * 2. Allow case insentive alphanumeric characters
   * 3. Between 3 and 15 characters in length
   */
  STABLEHOUSE_TAG: /^@[a-zA-Z0-9]{3,15}$/,
  ALPHANUMERIC:
    /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9,'` _.-]+)$/,
  EMAIL_ADDRESS: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  PREFINERY_REFERRAL_CODE: /^[A-Za-z0-9]{5}$/i,
  STABLEHOUSE_REFERRAL_CODE: /^[A-Za-z0-9]{6}$/i,
  //
  SORT_CODE: /^\d{2}(-|)\d{2}\1\d{2}$/,
  /**
   * REGEX validates below:
   * 1. Consists only of digits
   * 2. Allows for decimal or comma (. or ,) as seperators
   * 3. Is between 0 and 8 decimal length
   */
  FLOATING_NUMBER: /(^$)|(^\d+[.,]?\d{0,8}$)/,
  /**
   * REGEX to validate if given string allow alpha numeric values without spaces
   */
  ALPHA_NUMERIC_NO_SPACE: /^[A-Za-z0-9]*[A-Za-z0-9][A-Za-z0-9]*$/,
  /**
   * This REGEX is to check if password is secure based on below rules:
   * 1. Has case insensitive alpha numeric characters
   * 2. Has at least 1 special character
   * 3. Has at least 1 digit/ number
   * 4. Between 8 and 64 characters in length
   */
  SECURE_PASSWORD:
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./])[A-Za-z\d-!$%^&*()_+|~=`{}[\]:";'<>?,./]{8,64}$/,
  NIL_DECIMAL: /^[,.]$/,
};

export const COLORS = {
  FFFFFF: '#fff',
  F6F6F6: '#f6f6f6',
  EDEDED: '#EDEDED',
  PRIMARY: '#11152d',
  SECONDARY: '#586291',
  GRADIENT: 'linear-gradient(96.41deg, #1dbf73 10.1%, #9516a0 101.76%)',
  STATUS: {
    SUCCESS: `#1DBF73`,
    FAILURE: 'orangered',
    WARNING: '#FEAD10',
    ATTENTION: '#FFD600',
    INFO: '#4EBDFB',
  },
};

export const FIAT_SYMBOLS: Record<string, string> = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  AUD: 'AU$',
  CAD: 'CA$',
  CHF: 'SFr',
  JPY: '¥',
  BMD: 'BD$',
  // XAU: 'XAU', Ignore. XAU is marked as fiat in backend but that would format amounts like so: XAU20.00.
};

export const YUP_VALIDATIONS = {
  TWO_FA_CODE: Yup.string()
    .matches(REGEX.ONLY_DIGITS, ERROR_MESSAGES.ONLY_DIGITS)
    .min(6, ERROR_MESSAGES.TWO_FA_ALLOWED_LENGTH)
    .max(6, ERROR_MESSAGES.TWO_FA_ALLOWED_LENGTH)
    .required(ERROR_MESSAGES.REQUIRED_VALUE)
    .nullable(),
};

export const cammelCaseToWords = (input: string) => {
  if (!input) {
    return input;
  }
  return input
    .replace(/([A-Z])/g, ' $1')
    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

export const NUMBER_FORMAT = Object.freeze({
  THOUSANDS_SEPARATOR: ',',
  DECIMAL_SEPARATOR: '.',
  DEFAULT_DECIMAL_VALUE: `0.`,
});

export const EMAIL = {
  SUPPORT: `support@stablehouse.com`,
};

export const APP_META_DATA = {
  title: 'Stablehouse - Institutional access to digital assets',
  description:
    'A secure custodial framework built for institutions: mitigating risk with security, bankruptcy protection and regulatory compliance',
};

export const CRITICAL_ENDPOINTS = [
  /\/v1\/configuration$/,
  /\/v1\/account\/alerts\/get$/,
  /\/v1\/kyc\/get-kyc-status$/,
  /\/v1\/authentication\/settings$/,
  /\/v1\/settings\/account$/,
  /\/v1\/currencies?Tenant=(.+)$/,
  /\/v1\/fx\/get-rates$/,
  /\/v1\/funds\/get-funds$/,
  /\/v1\/activity\/get-history$/,
  /\/v1\/realtime\/authenticate$/,
  /\/v1\/withdrawal-addresses\/get-withdrawal-addresses$/,
  /\/v1\/fiat\/get-bank-accounts$/,
  /\/v1\/account\/tag\/get$/,
  /\/v1\/profile$/,
  /\/v1\/rewards\/get-rewards$/,
  /\/v1\/organization\/get-account-details$/,
  /\/v1\/organization\/list-asset-holdings$/,
  /\/v1\/organization\/list-accounts$/,
];
