import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Col, TextBody, TextSubheading, TextTitle } from '../../atoms';
import { BadgeIcon } from '../badge-icon';
import { sizes } from '../../theme';
export const Callout = memo(({ children, icon, message, small = false, title, variant = 'default', }) => {
    const TitleEl = small ? TextSubheading : TextTitle;
    return (_jsxs(Col, Object.assign({ style: $.container }, { children: [_jsxs(Col.C, { children: [!!icon && (_jsx(Col, Object.assign({ mb: "xs" }, { children: _jsx(BadgeIcon, { small: small, variant: variant, name: icon }) }))), _jsx(TitleEl, Object.assign({ center: true }, { children: title })), _jsx(TextBody, Object.assign({ mt: small ? 'xs' : 'm', center: true }, { children: message }))] }), !!children && _jsx(Col, Object.assign({ mt: "l" }, { children: children }))] })));
});
const $ = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: sizes.l,
        paddingHorizontal: sizes.m,
    },
});
