var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { Row, TextBody } from '../../atoms';
import { Icon } from '../icon';
import { Surface } from '../../atoms/surface';
import { StyleSheet } from 'react-native';
export const Message = memo((_a) => {
    var { icon, title, variant } = _a, rest = __rest(_a, ["icon", "title", "variant"]);
    const textColor = useMemo(() => {
        switch (variant) {
            case 'success':
                return 'success';
            case 'warning':
                return 'warning';
            case 'info':
                return 'info';
            case 'error':
                return 'error';
            default:
                return 'primary';
        }
    }, [variant]);
    return (_jsx(Surface, Object.assign({ variant: variant }, rest, { style: $.container }, { children: _jsxs(Row.LR, { children: [_jsx(TextBody, Object.assign({ color: textColor, style: { lineHeight: 14 } }, { children: title })), !!icon && _jsx(Icon, { name: icon, color: textColor, size: 16 })] }) })));
});
const $ = StyleSheet.create({
    container: {
        height: 40,
        justifyContent: 'center',
    },
});
