import { jsx as _jsx } from "react/jsx-runtime";
import { API } from '@xbto/api-client';
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { Icon } from '../icon';
export function SortDirectionIcon({ active = false, dir, onChange }) {
    // Handlers
    const handlePress = useCallback(() => {
        onChange === null || onChange === void 0 ? void 0 : onChange(dir === API.SortDirection.Ascending
            ? API.SortDirection.Descending
            : API.SortDirection.Ascending);
    }, [dir, onChange]);
    // Render
    return (_jsx(Pressable, Object.assign({ disabled: !onChange, onPress: handlePress }, { children: _jsx(Icon, { name: dir === API.SortDirection.Descending ? 'arrow-down' : 'arrow-up', color: active ? 'primary' : 'tertiary', size: "xs" }) })));
}
