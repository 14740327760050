import { jsx as _jsx } from "react/jsx-runtime";
import { Pressable, StyleSheet } from 'react-native';
import { useCallback } from 'react';
import { theme } from '@xbto/design-system';
import { sizes } from '../../theme';
export const PaginationButton = ({ disabled = false, icon, page, onPress, }) => {
    // Handlers
    const handlePress = useCallback(() => {
        onPress === null || onPress === void 0 ? void 0 : onPress(page);
    }, [onPress, page]);
    // Render
    return (_jsx(Pressable, Object.assign({ style: [$.container, disabled && $.disabled], disabled: disabled || !onPress, onPress: handlePress }, { children: icon })));
};
const $ = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: sizes.xs,
        borderWidth: 1,
        borderColor: theme.color.border.primary,
        borderRadius: Number(theme.radius.sm),
    },
    disabled: {
        opacity: 0.4,
    },
});
