var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { _DateFormat } from '../../molecules/date-format';
// TODO: Docs
// Convention:
// "<Date*" components should not format Time (as opposed to "<DateTime*" or "<Time*" components)
// Note: "Numeric" stands for "01/01/2024" dates (as opposed to "1 Jan 2024" formatted dates, which contain month names / text)
export const DateNumeric = memo((_a) => {
    var { short = false } = _a, rest = __rest(_a, ["short"]);
    return _jsx(_DateFormat, Object.assign({}, rest, { format: `dd/MM/${short ? 'yy' : 'yyyy'}` }));
});
