import { FC, useEffect, useState } from 'react';
import { API } from '@xbto/api-client';
import { DATE_FORMATS, EnrichedApiKey } from 'common';
import { format } from 'date-fns';
import cls from 'classnames';
import { AppIcon, AppDialog } from 'common';
import { DataStore } from '../../store';
import { CreateApiKey } from '../apikeys/add';
import { DeleteApiKey } from '../apikeys/delete';
import { ShowApiKey } from '../apikeys/show';

const left = 'left' as const;
const right = 'right' as const;

const COLUMNS = {
  NAME: { title: 'Name', style: { textAlign: left, width: '30%' } },
  API_KEY: { title: 'Api Key', style: { textAlign: right, width: '15%' } },
  ACCOUNT_ID: {
    title: 'Account number',
    style: { textAlign: right, width: '20%' },
  },
  CREATION_DATE: {
    title: 'Creation date',
    style: { textAlign: right, width: '10%' },
  },
  LAST_USAGE_DATE: {
    title: 'Last used',
    style: { textAlign: right, width: '10%' },
  },
  // STATUS: { title: 'Status', style: {textAlign: right, width: '10%',  }},
  Action: { title: '', style: { textAlign: right, width: '5%' } },
};
const COLUMN_IDS = Object.keys(COLUMNS);

const Header: FC = () => {
  return (
    <div className="flex flex-row items-center text-gray-400 text-sm justify-between p-4 border-grey-bright border-l border-r border-t">
      {COLUMN_IDS.map(colId => (
        <div key={colId} style={COLUMNS[colId].style}>
          {COLUMNS[colId].title}
        </div>
      ))}
    </div>
  );
};

interface RowProps {
  apiKeys: EnrichedApiKey[] | null;
  onShow: (key: string) => void;
}

const ApiKeys: FC<RowProps> = ({ apiKeys, onShow }) => {
  const keys = apiKeys || [];
  return (
    <>
      {keys.map((apiKey, idx) => {
        return (
          <div
            key={apiKey.key}
            className={cls(
              'flex flex-row items-center hover-bg-grey-brighter text-gray-400 text-sm',
              'justify-between p-4 cursor-pointer outline:none focus:outline-none border-t border-l border-r',
              keys.length - 1 === idx && 'border-b rounded-4'
            )}
            onClick={() => onShow(apiKey.key!)}
          >
            <div className="text-primary text-left" style={COLUMNS.NAME.style}>
              {apiKey.name}
            </div>
            <div
              className="text-primary text-right"
              style={COLUMNS.API_KEY.style}
            >
              {apiKey.key ? mask(apiKey.key) : '-'}
            </div>
            <div
              className="text-primary text-right flex flex-col"
              style={COLUMNS.ACCOUNT_ID.style}
            >
              <span>{apiKey.accountLabel}</span>
              <span className="text-grey-darker">{apiKey.accountNumber}</span>
            </div>
            <div
              className="text-primary text-right"
              style={COLUMNS.CREATION_DATE.style}
            >
              {format(apiKey.creationDate, DATE_FORMATS.MM_dd_yyyy)}
            </div>
            <div
              className="text-primary text-right"
              style={COLUMNS.LAST_USAGE_DATE.style}
            >
              {apiKey.lastUsageDate
                ? format(apiKey.lastUsageDate, DATE_FORMATS.MM_dd_yyyy)
                : '-'}
            </div>
            <div
              className="text-primary text-right"
              style={COLUMNS.Action.style}
            >
              <AppIcon
                icon="chevron-right"
                size={14}
                bg="bg-transparent"
                cls="ml-6"
                fill="fill-current-color-primary"
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const mask = (str: string) => `${str.slice(0, 4)}...${str.slice(-4)}`;

export const SettingsApiKeys: FC = () => {
  /**
   * Store
   */
  const apiKeys = DataStore.useStoreState(s => s.settings.apiKeys);
  const getApiKeys = DataStore.useStoreActions(a => a.settings.getApiKeys);
  const setError = DataStore.useStoreActions(a => a.setError);

  /**
   * State
   */
  const [showAddApiKeyDialog, setShowAddApiKeyDialog] = useState(false);
  const [toDeleteApiKeyId, setToDeleteApiKeyId] = useState<string | null>(null);
  const [toShowApiKey, setToShowApiKey] = useState<
    API.CreateApiKeyResponse | EnrichedApiKey | null
  >(null);

  /**
   * Hooks
   */
  useEffect(() => {
    getApiKeys();
  }, []);
  useEffect(() => {
    if (!toDeleteApiKeyId) {
      setError(null);
      getApiKeys();
    }
  }, [toDeleteApiKeyId]);

  /**
   * DOM
   */

  const isEmpty = (apiKeys || []).length === 0;

  return (
    <div className="">
      {/* content  */}
      <section className="flex flex-col">
        <nav className="flex flex-row justify-between items-center pb-3">
          <p className="font-bold">Api Keys</p>
          <button
            className="font-bold flex flex-row items-center"
            onClick={e => {
              e.preventDefault();
              setShowAddApiKeyDialog(true);
            }}
          >
            <span className="hidden sm:inline mr-1">Create a new API Key</span>
            <span className="sm:hidden mr-1">Add new</span>
            <AppIcon icon="plus-accent" size="sm" />
          </button>
        </nav>
        {/* table  */}
        <main>
          {/* header  */}
          <Header />
          {/* content  */}

          {isEmpty && (
            <div className="flex flex-col items-center">
              <AppIcon
                icon="institutions"
                cls="mt-10 mb-4 p-3 justify-center items-center text-secondary"
              />
              <h4 className="text-center">You have no API Key</h4>
              <button
                type="button"
                className="app-button-accent flex-1 flex items-center justify-center mt-6 mb-10"
                onClick={e => {
                  e.preventDefault();
                  setShowAddApiKeyDialog(true);
                }}
              >
                Add an API Key
              </button>
            </div>
          )}
          {!isEmpty && (
            <ApiKeys
              apiKeys={apiKeys}
              onShow={key =>
                setToShowApiKey(apiKeys?.find(i => i.key == key) || null)
              }
            />
          )}
        </main>
      </section>

      {/* add api key dialog  */}
      <AppDialog
        isOpen={showAddApiKeyDialog}
        onClose={() => {
          setShowAddApiKeyDialog(false);
        }}
      >
        <CreateApiKey
          onClose={(apiKey: API.CreateApiKeyResponse | null) => {
            setShowAddApiKeyDialog(false);
            setToShowApiKey(apiKey);
          }}
        />
      </AppDialog>

      {/* delete api key dialog  */}
      <AppDialog
        isOpen={toDeleteApiKeyId !== null}
        onClose={() => setToDeleteApiKeyId(null)}
      >
        <DeleteApiKey
          apiKeyId={toDeleteApiKeyId as string}
          onClose={() => setToDeleteApiKeyId(null)}
        />
      </AppDialog>

      {/* view api key dialog  */}
      <AppDialog
        isOpen={!!toShowApiKey}
        onClose={() => setToShowApiKey(null)}
        widthCls="max-w-2xl"
      >
        <ShowApiKey
          apiKey={toShowApiKey!}
          onClose={() => setToShowApiKey(null)}
          onDelete={apiKey => setToDeleteApiKeyId(apiKey)}
        />
      </AppDialog>
    </div>
  );
};
