import { AppIcon, ThunkResult } from 'common';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { TextInput, ValidationProvider } from '@xbto/universal-components';
import { accountSchema } from '@xbto/universal-components';

interface AccountEditProps {
  accountId: string;
  accountName?: string;
  accountNumber?: string;
  error?: string | null;
  onChange: (
    accountId: string,
    accountName: string
  ) => Promise<ThunkResult<void>>;
  clearError: () => void;
}

export const AccountEdit: FC<AccountEditProps> = ({
  accountId,
  accountName = '',
  accountNumber = '',
  onChange,
  error,
  clearError,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(accountName);

  /** Handlers Methods */
  const handleOnTextChange = (val: string) => {
    setValue(val);
  };

  const handleConfirmChange = async () => {
    if (accountName !== value) {
      const { isSuccessful } = await onChange(accountId, value);
      if (isSuccessful) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setValue(accountName);
    clearError();
  };

  const handleKeyDown = useCallback(
    e => {
      if (e.key !== 'Enter') {
        return;
      }
      handleConfirmChange();
    },
    [handleConfirmChange]
  );

  if (isEditing) {
    return (
      <div className="flex flex-row items-start justify-start">
        {/* Account Label and Number */}
        <ValidationProvider>
          {({ validateField, setError }) => {
            useEffect(() => {
              setError('Account name', error || '');
            }, [error]);
            return (
              <>
                <TextInput
                  field="Account name"
                  placeholder="Account name"
                  value={value}
                  defaultValue={accountName}
                  onValueChange={handleOnTextChange}
                  onKeyPress={handleKeyDown}
                  maxLength={27}
                  validate={validateField}
                  schema={accountSchema}
                />
                <div className="flex flex-row items-center ml-2 py-2 mt-1 mb-0.5">
                  <button disabled={!value} onClick={handleConfirmChange}>
                    <AppIcon icon="check" size={30} bg="" />
                  </button>
                  <button onClick={handleCancelClick}>
                    <AppIcon icon="close-button" size="sm" bg="" />
                  </button>
                </div>
              </>
            );
          }}
        </ValidationProvider>
      </div>
    );
  }

  return (
    <div className="flex flex-row items-start gap-2">
      {/* Account Label and Number */}
      <div className="flex flex-col items-start">
        <span className="text-primary font-bold">{accountName}</span>
        {accountNumber && (
          <span className="text-grey-darker text-sm">{accountNumber}</span>
        )}
      </div>
      {/* Edit Button */}
      <button onClick={handleEditClick}>
        <AppIcon cls="mt-0.5" icon="pencil-line" size={20} bg="" />
      </button>
    </div>
  );
};
