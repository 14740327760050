import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { theme } from '@xbto/design-system';
import { sizes } from '../../theme';
import { TextBody } from '../text';
export function RadioButton({ onPress, title, value, style, }) {
    /**
     * Methods
     */
    const handlePress = useCallback(() => {
        onPress === null || onPress === void 0 ? void 0 : onPress(value);
    }, [onPress, value]);
    /**
     * DOM
     */
    return (_jsx(Pressable, Object.assign({ onPress: handlePress, style: ({ pressed, hovered }) => [
            $.container,
            (pressed || hovered) && $.active,
            style,
        ] }, { children: ({ pressed, hovered }) => {
            const texColor = pressed || hovered ? 'inverse' : 'primary';
            return (_jsx(TextBody, Object.assign({ em: "600", color: texColor, center: true }, { children: title })));
        } })));
}
const $ = StyleSheet.create({
    container: {
        borderRadius: Number(theme.radius.sm),
        backgroundColor: theme.color.background.tertiary,
        padding: sizes.m,
        justifyContent: 'center',
        alignContent: 'center',
    },
    active: {
        backgroundColor: theme.color.background.primary,
    },
});
