import { API } from '@xbto/api-client';
import { action, Action, computed, thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { getApiErrorCode, getApiErrorMessage } from '../utils';
import { Identity, decodeIdentity } from '../utils/decode-identity';
import { runThunk } from '../utils/run-thunk';
import { runThunkUnmanaged } from '../utils/run-thunk-unmanaged';
import {
  ClientUserType,
  AdditionalOptionsXHR,
  AppThunk,
  AppComputed,
  Injections,
} from './types';
import { API_ERROR_CODES, DEFAULTS } from '../constants';
import { BaseModel, createBaseModel } from './base-store';

interface Profile {
  userHasSeenKycSuccessBanner?: boolean;
}

type UserAction<Payload = void> = Action<UserModel, Payload>;

type UserComputed<Result> = AppComputed<UserModel, Result>;

type UserThunk<Payload = undefined, Result = any> = AppThunk<
  UserModel,
  Payload,
  Result
>;

type UserThunkOn = ThunkOn<UserModel, Injections>;

export interface UserModel extends BaseModel {
  // reset
  resetStore: UserThunk;
  // state
  accessToken: string | null;
  defaultAccountId: string | null; // first account id obtained from JWT
  identity: Identity | null;
  twoFaToken: string | null;
  passwordEntropy: API.PasswordEntropyResponse | null;
  profile: Profile | null;
  isSecurityEnabled: boolean;
  isValidReferralCode: boolean | null;
  userList: API.UserAndRole[] | null;
  isAccountDisabled: boolean;
  isAccountLocked: boolean;
  clientUserType: ClientUserType | null;
  isClient: boolean | null;
  // computed
  isAuthenticated: UserComputed<boolean>;
  isEmailVerified: UserComputed<boolean>;
  isKycVerified: UserComputed<boolean>;
  // actions
  setAccessToken: UserAction<string | null>;
  setAccessTokenSilently: UserAction<string | null>;
  _setDefaultAccountId: UserAction<string | null>;
  setIdentity: UserAction<Identity | null>;
  setTwoFaToken: UserAction<string | null>;
  setPasswordEntropy: UserAction<API.PasswordEntropyResponse | null>;
  setProfile: UserAction<Profile>;
  setIsValidReferralCode: UserAction<boolean | null>;
  setUserList: UserAction<API.UserAndRole[] | null>;
  disableAccount: UserAction;
  lockAccount: UserAction;
  disableSecurity: Action<UserModel, void>;
  enableSecurity: Action<UserModel, void>;
  // thunk
  init: UserThunk<'web' | 'mobile', void>;
  _setIdentityToken: UserThunk<string, Promise<Identity | null>>;
  getIdentity: UserThunk<void, Promise<Identity | null>>;
  authenticate: UserThunk<
    API.AuthenticateRequest & {
      targetPath: string | null;
    },
    Promise<Partial<API.AuthenticateResponseApiResponse>>
  >;
  completeChallenge: UserThunk<
    API.CompleteChallengeRequest,
    Promise<Partial<API.AuthenticateResponseApiResponse>>
  >;
  logout: UserThunk<undefined, Promise<void>>;
  sendResetPasswordEmail: UserThunk<API.ResetPasswordEmailRequest>;
  getPasswordEntropy: UserThunk<
    API.PasswordEntropyRequest,
    Promise<API.PasswordEntropyResponse | null>
  >;
  getProfile: UserThunk<AdditionalOptionsXHR, Promise<void>>;
  renewToken: UserThunk<
    void,
    Promise<{ user: Identity | null; jwt: string | null }>
  >;
  verifyEmailAddress: UserThunk<string, Promise<void>>;
  updateYieldPromptAction: UserThunk<{
    request: API.YieldPromptRequest;
    accountId: string;
  }>;
  validateReferralCode: UserThunk<string, Promise<void>>;
  toggleAccountStateOperation: UserThunk<boolean, Promise<void>>;
  toggleUserStateOperation: UserThunk<
    API.ToggleUserStatusOperationRequest,
    Promise<void>
  >;
  listAccountUsers: UserThunk<undefined, Promise<void>>;
  disable2FA: UserThunk<undefined, Promise<boolean>>;
  redirectAfterLogin: string | null;
  setRedirectAfterLogin: UserAction<string | null>;
  // Side effects
  syncAccessToken: UserThunkOn;
  syncDefaultAccountId: UserThunkOn;
}

export const userModel: UserModel = {
  ...createBaseModel(),

  // reset
  resetStore: thunk(actions => {
    actions.setAccessToken(null);
    actions.setIdentity(null);
  }),
  // state
  accessToken: null,
  identity: null,
  defaultAccountId: null,
  twoFaToken: null,
  passwordEntropy: null,
  profile: null,
  isValidReferralCode: null,
  userList: null,
  isAccountDisabled: false,
  isAccountLocked: false,
  redirectAfterLogin: null,
  clientUserType: null,
  isClient: null,
  isSecurityEnabled: true,
  // computed
  isAuthenticated: computed(state => state.identity !== null),
  isKycVerified: computed(
    [(_state, storeState) => storeState.settings.globalAppSettings?.kycTier],
    kycLevel => (kycLevel ? kycLevel > 0 : false)
  ),
  isEmailVerified: computed(
    state => state.identity != null && !state.identity.requiresEmailVerification
  ),
  // action
  setRedirectAfterLogin: action((state, path) => {
    state.redirectAfterLogin = path;
  }),
  setAccessToken: action((state, token) => {
    state.accessToken = token;
  }),
  setAccessTokenSilently: action((state, token) => {
    state.accessToken = token;
  }),
  _setDefaultAccountId: action((state, id) => {
    state.defaultAccountId = id;
  }),
  setIdentity: action((state, payload) => {
    state.identity = payload;
  }),
  setTwoFaToken: action((state, payload) => {
    state.twoFaToken = payload;
  }),
  setPasswordEntropy: action((state, payload) => {
    state.passwordEntropy = payload;
  }),
  setProfile: action((state, profile) => {
    state.profile = profile;
  }),
  setIsValidReferralCode: action((state, payload) => {
    state.isValidReferralCode = payload;
  }),
  setUserList: action((state, payload) => {
    state.userList = payload;
  }),
  disableAccount: action(state => {
    state.isAccountDisabled = true;
  }),
  lockAccount: action(state => {
    state.isAccountLocked = true;
  }),
  disableSecurity: action(state => {
    state.isSecurityEnabled = false;
  }),
  enableSecurity: action(state => {
    state.isSecurityEnabled = true;
  }),
  // thunk
  init: thunk((actions, client, { injections }) => {
    if (client === 'mobile') {
      // We need to sync the access token in store when we refresh token (in apiClient)
      // @see packages/mobile/src/api/client.ts@refreshAccessToken:L99
      injections.apiClient.subscribe('accessToken', value => {
        // We sync silently in order to _not_ trigger syncAccessToken side-effect
        actions.setAccessTokenSilently(value);
      });
    }
  }),
  _setIdentityToken: thunk(async (actions, idToken, { getStoreActions }) => {
    try {
      const _identity = decodeIdentity(idToken);

      // Important
      // 1- set default account ID first (for api client)
      actions._setDefaultAccountId(_identity?.accounts?.[0] ?? null);
      // 2- set identity second (for isAuthenticated = true)
      actions.setIdentity(_identity);

      return _identity;
    } catch (error) {
      const storeActions = getStoreActions();

      console.error('Something wrong happened while setting the JWT ', error);
      const message = getApiErrorMessage(error);
      storeActions.setError(message);

      return null;
    }
  }),
  getIdentity: thunk(async (actions, _void, { injections }) => {
    const { isSuccessful, result } = await injections.apiClient.identity();

    if (isSuccessful && result?.jwt) {
      return actions._setIdentityToken(result.jwt);
    }

    return null;
  }),
  authenticate: thunk(
    async (
      actions,
      { targetPath, ...req },
      { getStoreActions, injections }
    ) => {
      const storeActions = getStoreActions();
      actions.setRedirectAfterLogin(targetPath);

      try {
        storeActions.setBusy(true);

        const response = await injections.apiClient.authenticate(req);

        if (!response) {
          // this means that there was a hard redirect
          // instead of an exception.
          // so we need a safe escape hatch
          // to the catch() block
          throw new Error('');
        }
        const { isSuccessful, errorMessage, result } = response;
        const redirectToChallenge = !!(result?.challenge && !result.jwt);

        if (redirectToChallenge && result?.challenge) {
          console.log('Authentication requires 2FA');
          // require 2fa login
          actions.setTwoFaToken(result?.challenge?.token);
        } else {
          actions.setTwoFaToken(null);

          if (result?.jwt) {
            await actions._setIdentityToken(result.jwt);
          }
        }
        storeActions.setBusy(false);

        if (!isSuccessful && errorMessage) {
          storeActions.setError(errorMessage);
        }
        return response;
      } catch (error) {
        console.error(
          'Something wrong happened during the authentication: ',
          error
        );
        storeActions.setBusy(false);
        const errorMessage = getApiErrorMessage(error);
        const errorCode = getApiErrorCode(error);
        storeActions.setError(errorMessage);
        return { isSuccessful: false, errorMessage, errorCode };
      }
    }
  ),
  completeChallenge: thunk(
    async (actions, req, { getStoreActions, injections, getState }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        const token = getState().twoFaToken || '';
        const response = await injections.apiClient.completeChallenge({
          code: req.code,
          token,
        });
        const { isSuccessful, errorMessage, result } = response;
        storeActions.setBusy(false);
        if (isSuccessful && result?.jwt) {
          await actions._setIdentityToken(result.jwt);
        }
        if (!isSuccessful && errorMessage) {
          storeActions.setError(errorMessage);
        }
        return response;
      } catch (error) {
        storeActions.setBusy(false);
        const errorMessage = getApiErrorMessage(error);
        const errorCode = getApiErrorCode(error);
        storeActions.setError(errorMessage);
        return { isSuccessful: false, errorMessage, errorCode };
      }
    }
  ),
  logout: thunk(async (_actions, _payload, { injections, getStoreActions }) => {
    const storeActions = getStoreActions();
    storeActions.setBusy(true);

    try {
      await injections.apiClient.logout();
    } catch (error) {
      console.error('Authentication service: logout failed', error);
    } finally {
      storeActions.resetStore();
    }
  }),

  sendResetPasswordEmail: thunk(
    async (_actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();

      try {
        storeActions.setBusy(true);
        storeActions.setError(null);

        await injections.apiClient.sendResetPasswordEmail(payload);

        storeActions.setBusy(false);
      } catch (err) {
        const message = getApiErrorMessage(err);
        storeActions.setError(message);
        throw err;
      }
    }
  ),
  getPasswordEntropy: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();

      try {
        storeActions.setBusy(true);
        storeActions.setError(null);

        const { isSuccessful, result, errorMessage } =
          await injections.apiClient.validatePassword(payload);

        storeActions.setBusy(false);

        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          return null;
        }

        actions.setPasswordEntropy(result);
        return result;
      } catch (err) {
        const errorMessage = getApiErrorMessage(err);

        storeActions.setBusy(false);
        storeActions.setError(errorMessage);
        return null;
      }
    }
  ),
  getProfile: thunk(async (actions, payload, helpers) => {
    const setBusy = !payload.isBackgroundXHR;
    await runThunk<{ result: API.GetUserProfileResponse }>(
      helpers,
      {
        execute: async () =>
          await helpers.injections.apiClient.getUserProfile(),
        onSucccess: response => {
          const newProfile = response.result?.rawProfileData
            ? JSON.parse(response.result?.rawProfileData)
            : {};
          actions.setProfile(newProfile);
        },
        onError: message => {
          if (payload.throwOnError) {
            throw new Error('Error on getProfile: ' + message);
          }
        },
      },
      setBusy
    );
  }),
  renewToken: thunk(async (actions, _void, { injections }) => {
    // TODO: make this more like the other thunks
    const { result } = await injections.apiClient.renewToken();

    if (result?.jwt) {
      const _identity = await actions._setIdentityToken(result.jwt);

      return { user: _identity, jwt: result.jwt };
    }

    return { user: null, jwt: null };
  }),
  verifyEmailAddress: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.verifyEmailAddress({ token: payload });
  }),
  updateYieldPromptAction: thunk(
    async (_actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        storeActions.setError(null);

        injections.apiClient.setAdditionalHeaders({
          'x-account-id': payload.accountId,
        });
        const response = await injections.apiClient.updateYieldPromptAction(
          payload.request
        );
        const { isSuccessful, errorMessage } = response;
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return;
        }
        storeActions.setBusy(false);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
      }
    }
  ),
  validateReferralCode: thunk(async (actions, payload, helpers) => {
    await runThunk<{ result: API.ValidateReferralCodeResponse }>(helpers, {
      execute: async () =>
        await helpers.injections.apiClient.validateReferralCode({
          referralCode: payload,
        }),
      onSucccess: response => {
        const isValid = response.result?.isValid || false;
        actions.setIsValidReferralCode(isValid);
      },
      onError: (_message, errorCode) =>
        errorCode === API_ERROR_CODES.INVALID_REQUEST &&
        actions.setIsValidReferralCode(false),
    });
  }),
  toggleAccountStateOperation: thunk(async (_actions, payload, helpers) => {
    await runThunkUnmanaged(
      helpers,
      {
        execute: async () => {
          const result =
            await helpers.injections.apiClient.toggleAccountStateOperation({
              state: payload,
            });
          helpers.getStoreActions().admin.getFundAccounts({
            page: DEFAULTS.PAGE,
            pageSize: DEFAULTS.PAGE_SIZE,
            search: '',
          });
          return result;
        },
      },
      true,
      true,
      helpers.getStoreState().additionalHeaders
    );
  }),
  toggleUserStateOperation: thunk(async (_actions, payload, helpers) => {
    await runThunkUnmanaged(helpers, {
      execute: async () =>
        await helpers.injections.apiClient.toggleUserStateOperation(payload),
    });
  }),
  listAccountUsers: thunk(async (actions, _payload, helpers) => {
    runThunk<{ result: API.UserAndRole[] | null }>(
      helpers,
      {
        execute: async () => await helpers.injections.apiClient.listUsers(),
        onSucccess: response => {
          actions.setUserList(response.result);
        },
      },
      true,
      true,
      helpers.getStoreState().additionalHeaders
    );
  }),
  disable2FA: thunk(
    async (
      _actions,
      _payload,
      { injections, getStoreActions, getStoreState }
    ) => {
      const storeState = getStoreState();
      const storeActions = getStoreActions();

      try {
        storeActions.setBusy(true);
        storeActions.setError(null);

        injections.apiClient.setAdditionalHeaders(storeState.additionalHeaders);

        await injections.apiClient.disableAuth0Mfa({});

        return true;
      } catch (error) {
        storeActions.setError(getApiErrorMessage(error));

        return false;
      } finally {
        storeActions.setBusy(false);
      }
    }
  ),

  // Side effects
  syncAccessToken: thunkOn(
    actions => actions.setAccessToken,
    async (actions, { payload }, { injections }) => {
      if (payload) {
        // Runtime
        await injections.apiClient.setAccessToken(payload);

        // Persist
        await injections.storage.setAccessToken(payload);
      } else {
        // Clear access token
        await injections.apiClient.setAccessToken(null);
        await injections.storage.setAccessToken(null);

        // Clear default account ID
        actions._setDefaultAccountId(null);

        // Clear runtime identity
        actions.setIdentity(null);
      }
    }
  ),
  syncDefaultAccountId: thunkOn(
    actions => actions._setDefaultAccountId,
    async (_actions, { payload }, { injections }) => {
      await injections.apiClient.setDefaultAccountId(payload);
    }
  ),
};
