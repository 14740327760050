var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { getBondAssetColWith, BOND_HEADERS as HEADERS, } from './market-table-header';
import { TableRow } from '../../molecules/table-row';
import { TableCell } from '../../molecules/table-cell';
import { AmountPrice } from '../../organisms/amount-price';
import { TextBody } from '../../atoms/text';
import { AmountIssuance } from '../../organisms/amount-issuance';
import { DateMaturity } from '../../organisms/date-maturity';
import { AmountChangePercent } from '../../organisms/amount-change-percent';
import { TableCellAsset } from './table-cell-asset';
export const MarketTableItemBond = memo((_a) => {
    var _b, _c, _d, _e, _f, _g, _h;
    var { actions, currencyCode, data, onPress, showYieldColumn = true, showCouponColumn = true } = _a, rest = __rest(_a, ["actions", "currencyCode", "data", "onPress", "showYieldColumn", "showCouponColumn"]);
    // Handlers
    const handlePress = useCallback(() => {
        onPress === null || onPress === void 0 ? void 0 : onPress(currencyCode);
    }, [currencyCode, onPress]);
    const cellProps = {
        align: 'right',
        onPress: onPress ? handlePress : undefined,
    };
    if (!data)
        return null;
    // Render
    return (_jsxs(TableRow, { children: [_jsx(TableCellAsset, Object.assign({}, rest, { onPress: onPress ? handlePress : undefined, width: getBondAssetColWith(showYieldColumn, showCouponColumn) })), showYieldColumn && (_jsx(TableCell, Object.assign({ width: (_b = HEADERS[1]) === null || _b === void 0 ? void 0 : _b.width }, cellProps, { renderInShadowDOM: true }, { children: _jsx(AmountChangePercent, { value: data.yieldPercent, em: true, signed: false }) }))), showCouponColumn && (_jsx(TableCell, Object.assign({ width: (_c = HEADERS[2]) === null || _c === void 0 ? void 0 : _c.width }, cellProps, { renderInShadowDOM: true }, { children: _jsx(AmountChangePercent, { value: data.couponPercent, em: true, signed: false }) }))), _jsx(TableCell, Object.assign({ width: (_d = HEADERS[3]) === null || _d === void 0 ? void 0 : _d.width }, cellProps, { children: _jsx(AmountPrice, { color: "primary", value: data.price, em: true }) })), _jsx(TableCell, Object.assign({ width: (_e = HEADERS[4]) === null || _e === void 0 ? void 0 : _e.width }, cellProps, { renderInShadowDOM: true }, { children: _jsx(DateMaturity, { color: "primary", value: data.maturity, em: true }) })), _jsx(TableCell, Object.assign({ width: (_f = HEADERS[5]) === null || _f === void 0 ? void 0 : _f.width }, cellProps, { renderInShadowDOM: true }, { children: _jsx(TextBody, Object.assign({ color: "primary", em: true }, { children: data.paymentFrequency })) })), _jsx(TableCell, Object.assign({ width: (_g = HEADERS[6]) === null || _g === void 0 ? void 0 : _g.width }, cellProps, { renderInShadowDOM: true }, { children: _jsx(AmountIssuance, { color: "primary", currency: data.issuanceAmountCurrency, value: data.issuanceAmount, em: true }) })), _jsx(TableCell, Object.assign({ width: (_h = HEADERS[7]) === null || _h === void 0 ? void 0 : _h.width }, { children: actions }))] }));
});
