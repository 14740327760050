import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, TextBody, TextHeading } from '../../atoms';
import { Keyboard, KeyboardAvoidingView, Platform, SafeAreaView, StyleSheet, TouchableWithoutFeedback, } from 'react-native';
import { NotaBeneLogo } from '../../molecules';
import { sizes } from '../../theme';
export const _KytWrapper = ({ title, subtitle, children, childrenContainerStyle, }) => {
    return (_jsx(SafeAreaView, Object.assign({ style: $.outer }, { children: _jsx(KeyboardAvoidingView, Object.assign({ behavior: Platform.OS === 'ios' ? 'padding' : 'height', style: $.outer, keyboardVerticalOffset: 50 }, { children: _jsx(TouchableWithoutFeedback, Object.assign({ onPress: () => Keyboard.dismiss(), accessible: false }, { children: _jsxs(Col.C, Object.assign({ style: $.container }, { children: [_jsxs(Col.C, Object.assign({ style: $.fillContainer }, { children: [_jsx(TextHeading, { children: title }), !!subtitle && (_jsx(TextBody, Object.assign({ center: true, style: $.subheading }, { children: subtitle }))), _jsx(Col, Object.assign({ style: [$.children, childrenContainerStyle] }, { children: children }))] })), _jsx(Col, Object.assign({ style: $.footer }, { children: _jsx(NotaBeneLogo, {}) }))] })) })) })) })));
};
const $ = StyleSheet.create({
    outer: {
        flex: 1,
        alignItems: 'center',
        // Prevent modal height from change between each steps on web
        minHeight: Platform.select({ web: 512, default: undefined }),
        maxHeight: Platform.select({ web: 512, default: undefined }),
    },
    container: {
        flex: 1,
        maxWidth: Platform.select({ web: 520, default: 328 }),
        paddingHorizontal: 0,
        paddingVertical: sizes.l,
        minWidth: 'auto',
        outlineStyle: 'none',
    },
    fillContainer: { flex: 1 },
    subheading: {
        paddingTop: Platform.select({
            web: sizes.xs,
            default: sizes.m,
        }),
    },
    children: {
        paddingVertical: sizes.xxl,
    },
    footer: {
        position: Platform.select({
            web: undefined,
            default: 'absolute',
        }),
        bottom: 0,
    },
});
