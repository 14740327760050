import { API } from '@xbto/api-client';
import { ApiResponse } from './types';

export class ApiError extends Error {
  public status: number;
  public code: number;

  constructor(status: number, message: string, code: number) {
    super(message);

    this.code = code;
    this.status = status;
  }
}

export const getErrorCode = (exception: any) => {
  if (exception instanceof ApiError) {
    return exception.code;
  }

  if (exception instanceof API.ApiException) {
    const result = exception.result as ApiResponse<any>;
    return result.errorCode;
  }

  if ('errorCode' in exception && 'errorMessage' in exception) {
    const result = exception as ApiResponse<any>;
    return result.errorCode;
  }

  return undefined;
};

export const errorCodes = {
  invalidKycState: 12000,
  onfidoCannotBeUsedWithCorporateAccounts: 12002,
  accountHasNoUsers: 12003,
  invalidStateToPerformChecks: 12004,
  tooManyFailedKycs: 12005,
  kycSubmissionNotFound: 12006,
  invalidFileSubmitted: 12007,
  accountIsBlocked: 12008,
  kycDenied: 12010,
  countryNotAuthorized: 12010,
  misconfiguredChart: 19004,
};
