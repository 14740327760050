import { FC } from 'react';
import cx from 'classnames';
import { AppIcon, ValidateAddressResponseProps } from 'common';
import { Error } from '~/components/common/error';

// TODO(Hadrien): UC SH-12290
export const AddressVerificationNote: FC<ValidateAddressResponseProps> = ({
  variant,
  message,
}) => {
  if (variant === 'error') return <Error message={message} />;

  return (
    <div
      className={cx(
        'flex flex-row mt-1 justify-between items-center px-4 py-3 gap-3 rounded-lg',
        {
          'bg-success-light  text-success': variant === 'success',
          'bg-warning-fair text-warning-dark': variant === 'warning',
        }
      )}
    >
      <span className="text-sm font-bold">{message}</span>
      <AppIcon
        size={24}
        bg="bg-transparent"
        cls="fill-current-color"
        icon="information-circle"
      />
    </div>
  );
};
