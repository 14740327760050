import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { TextBody } from '../../atoms';
export const DynamicText = ({ parts, containerProps }) => {
    /**
     * DOM
     */
    if (typeof parts === 'string') {
        return null;
    }
    return (_jsx(TextBody, Object.assign({}, containerProps, { children: parts.map(p => {
            return (_jsxs(TextBody, Object.assign({ color: "secondary", em: p.type === 'bold' }, { children: [p.value, "\u00A0"] }), p.value));
        }) })));
};
