// this hook is used to synchronize JWTs accross tabs.
// If one tab renews the token, the other tab must be aware of it

import React from 'react';
import { LOCAL_STORAGE_KEYS } from 'common';

export const useLocalAccessTokenUpdates = (
  callback: (token: string | null) => void
) => {
  const listener = React.useCallback((e: StorageEvent) => {
    if (e.key === LOCAL_STORAGE_KEYS.ACCESS_TOKEN) {
      callback(e.newValue);
    }
  }, []);

  window.addEventListener('storage', listener);
  return () => window.removeEventListener('storage', listener);
};
