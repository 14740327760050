import { jsx as _jsx } from "react/jsx-runtime";
import { StyleSheet } from 'react-native';
import { theme } from '@xbto/design-system';
import { sizes } from '../../theme';
import { Col } from '../col';
import { TextCaption } from '../text';
export const Label = ({ small = false, title }) => {
    return (_jsx(Col.C, Object.assign({ style: [$.container, small && $.containerSmall] }, { children: _jsx(TextCaption, Object.assign({ em: true, color: "info" }, { children: title })) })));
};
const $ = StyleSheet.create({
    container: {
        backgroundColor: theme.color.background.info,
        borderRadius: 999,
        padding: sizes.xs,
    },
    containerSmall: {
        paddingVertical: sizes.xxs,
        paddingHorizontal: 6,
    },
});
