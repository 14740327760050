import { FC, memo, useCallback, useEffect } from 'react';
import {
  dateRanges,
  Enriched,
  FilterField,
  FilterProps,
  SelectInput,
  textItemTemplate,
  textSelectedItemTemplate,
  useStatementsFilters,
} from 'common';
import {
  accountItemTemplate,
  accountSelectedItemTemplate,
} from '~/components/app-selector/templates';
import { DataStore } from '~/store';
import { handleNavigation } from '~/components/shared/url-params/url-params';

interface Props {
  onSubmit: (f?: FilterProps) => void;
  isLoading?: boolean;
}

const DateFilterOptions = Object.entries(dateRanges).map(
  ([, value]) => value?.text
);

export const FilterNavigation: FC<Props> = memo(({ onSubmit, isLoading }) => {
  const { getList } = DataStore.useStoreActions(_ => ({
    getList: _.statements.getList,
  }));

  const {
    applyParams,
    queryParams: { accountId: accId },
  } = handleNavigation<'accountId'>();

  const onReset = useCallback(() => {
    applyParams({ accountId: undefined }, { replace: true });
  }, [applyParams]);

  const {
    handleReset,
    handleSubmit,
    handleChangeDateRange,
    handleChangeAccount,
    accountId,
    accounts,
    dateRangeId,
  } = useStatementsFilters(DataStore, onSubmit, onReset, {
    accountId: accId as string,
  });

  useEffect(() => {
    getList({
      limit: null,
      accountId: !!accId ? (accId as string) : null,
      startDate: null,
      endDate: null,
      assetCode: null,
    });
  }, []);

  /**
   * Methods
   */
  const handleSelection = useCallback((key?: FilterField, val?: string) => {
    if (key === FilterField.accountId) {
      handleChangeAccount(val || '');
    }
    if (key === FilterField.dateRange) {
      handleChangeDateRange(val || '');
    }
  }, []);

  const hasValues = !!accountId || !!dateRangeId;

  return (
    <div className="relative text-primary h-full">
      <div>
        <div className="flex flex-col">
          <div className="p-6 border-b">
            <h3 className="font-bold text-base">Filter</h3>
          </div>
          <div className="flex flex-col gap-6 p-6">
            <div className="flex flex-col flex-1">
              <label
                className="pb-1 text-sm text-grey-darker"
                htmlFor={FilterField.dateRange}
              >
                Date range
              </label>
              <SelectInput
                name={FilterField.dateRange}
                disabled={isLoading}
                placeHolder="Choose date range"
                values={DateFilterOptions || []}
                value={dateRangeId}
                onChange={value =>
                  handleSelection(FilterField.dateRange, value)
                }
                getSelectedItemTemplate={textSelectedItemTemplate}
                getItemTemplate={textItemTemplate}
              />
            </div>
            <div className="flex flex-col flex-1">
              <label
                className="pb-1 text-sm text-grey-darker"
                htmlFor={FilterField.accountId}
              >
                Account
              </label>
              <SelectInput
                name={FilterField.accountId}
                disabled={isLoading}
                placeHolder="Choose account"
                values={accounts}
                value={accountId}
                getSelectedItemTemplate={accountId =>
                  accountSelectedItemTemplate(
                    accounts.find(acc => acc.account?.accountId === accountId),
                    true
                  )
                }
                getItemTemplate={(account, selected, active) =>
                  accountItemTemplate(account, selected, active, true)
                }
                onChange={(acc?: Enriched.ListAccountItem) => {
                  handleSelection(
                    FilterField.accountId,
                    acc?.account?.accountId
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b p-6">
          <button
            type="button"
            disabled={!hasValues || isLoading}
            className="app-button-outline button-lg sm:button-xl text-sm w-full sm:mr-3 flex-1"
            onClick={handleReset}
          >
            Clear
          </button>
          <button
            role="button"
            type="button"
            disabled={!hasValues || isLoading}
            className="app-button-primary button-lg sm:button-xl text-sm w-full flex-1"
            onClick={handleSubmit}
          >
            Apply filters
          </button>
        </div>
      </div>
    </div>
  );
});
