import { API } from '@xbto/api-client';
import BigNumber from 'big.js';
import { SORT_ASSETS_FIELDS, SORT_DIR } from '../../config';
import {
  AccountLevelSettings,
  ActionId,
  Enriched,
  EnrichedAccountDetail,
  EnrichedAccountDetailAsset,
  EnrichedCurrencyInformation,
  GlobalAppSettings,
} from '../../types';
import { orderBy } from '../order-by';
import { formatters } from '../formatters';
import { DATE_FORMATS, DEFAULTS } from '../../constants';
import { createBalanceChange } from './balance-change';
import {
  HoldingsTableColumn,
  HoldingsTableColumnId,
  HoldingsTableColumnTitle,
} from '../../store/portfolio/types';
import { DataModel } from '../../store';
import { formatReceiptDate } from '../date-utils';
import { maskLead } from '../mask-lead';
import { formatDate } from './enrich-list-asset-details';
import { BigOrZero } from '../big';

const getColumns = (
  account: API.AccountProperties | null,
  isAdmin: boolean,
  client: DataModel['client']
): HoldingsTableColumn[] => {
  const isFundAccount = account?.accountType === API.AccountType.Fund;

  const result: HoldingsTableColumn[] = [
    {
      id: HoldingsTableColumnId.Asset,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Asset],
      sortable: true,
      sx: { width: '160%' },
    },
    {
      id: HoldingsTableColumnId.Quantity,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Quantity],
      sortable: true,
      sx: { width: '80%' },
    },
  ];

  if (!isFundAccount) {
    result.push({
      id: HoldingsTableColumnId.Price,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Price],
      sortable: true,
      sx: { width: '60%' },
    });
    result.push({
      id: HoldingsTableColumnId.Value,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Value],
      sortable: true,
      sx: { width: '60%' },
    });
    result.push({
      id: HoldingsTableColumnId.Change24h,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Change24h],
      sortable: true,
      sx: { width: '40%' },
    });
  } else {
    result.push({
      id: HoldingsTableColumnId.RateOfReturnMTD,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.RateOfReturnMTD],
      sortable: true,
      sx: { width: '50%' },
    });
    result.push({
      id: HoldingsTableColumnId.RateOfReturnYTD,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.RateOfReturnYTD],
      sortable: true,
      sx: { width: '50%' },
    });
    result.push({
      id: HoldingsTableColumnId.TotalReturn,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.TotalReturn],
      sortable: true,
      sx: { width: '50%' },
    });
  }

  if (account?.isYieldEligible) {
    result.push({
      id: HoldingsTableColumnId.APY,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.APY],
      sortable: true,
      sx: { width: '50%' },
    });
    result.push({
      id: HoldingsTableColumnId.TotalInterestEarned,
      title:
        HoldingsTableColumnTitle[HoldingsTableColumnId.TotalInterestEarned],
      sortable: true,
      sx: { width: '50%' },
    });
    result.push({
      id: HoldingsTableColumnId.AccruedInterestThisWeek,
      title:
        HoldingsTableColumnTitle[HoldingsTableColumnId.AccruedInterestThisWeek],
      sortable: true,
      sx: { width: '50%' },
    });
  }

  // is not admin user & only show action ... in web app
  if (
    (!isAdmin && client === 'web') ||
    (isFundAccount && client === 'mobile')
  ) {
    result.push({
      id: HoldingsTableColumnId.Action,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Action],
      sortable: false,
      sx: { width: '25%' },
    });
  }

  return result;
};

export const enrichAccountDetail = (
  client: DataModel['client'],
  accountDetail: API.AccountDetail,
  currencies: EnrichedCurrencyInformation[],
  accounts: Enriched.ListAccountItem[],
  globalAppSettings: GlobalAppSettings | null,
  accountLevelSettings?: AccountLevelSettings | null
) => {
  if (!accountDetail?.assets || !currencies || !currencies.length) {
    return null;
  }

  const isAccountOfTypeProTrading =
    accountDetail.account?.accountType === API.AccountType.Trading ||
    accountDetail.account?.accountType === API.AccountType.ProTrading;
  const isAccountOfTypeVault =
    accountDetail.account?.accountType === API.AccountType.Custody ||
    accountDetail.account?.accountType === API.AccountType.Vault;
  const isAccountOfTypeFund =
    accountDetail.account?.accountType === API.AccountType.Fund;

  const enrichedAssets: EnrichedAccountDetailAsset[] = [];

  accountDetail.assets.forEach(item => {
    const currency = currencies.find(c => c.code === item.currency?.code);

    if (currency) {
      const balance = BigOrZero(item.quantity);
      const balanceUsd = BigOrZero(item.quantityUsd);
      enrichedAssets.push({
        ...item,
        canStabletagTransfer: false, // Note: https://stablehouse.atlassian.net/browse/SH-5156
        account: accountDetail.account,
        currency,
        isAccountOfTypeProTrading,
        isAccountOfTypeVault,
        isAccountOfTypeFund,
        balance,
        balanceUsd,
        quantity: balance,
        hasBalance: balance.gt(0),
        formatted: {
          earnedInterestThisWeekUsd: formatters.getBaseCurrency(
            item.earnedInterestThisWeekUsd
          ),
          earnedInterestTotalUsd: formatters.getBaseCurrency(
            item.earnedInterestTotalUsd
          ),
          balance: formatters.getAmount(item.quantity, currency?.decimals),
          balanceWithCurrencyCode: formatters.getCurrency(
            item.quantity,
            currency?.decimals,
            currency.displayCode
          ),
          balanceUsd: formatters.getAmount(item.quantityUsd, 2),
          balanceUsdWithCurrencyCode: formatters.getBaseCurrency(
            item.quantityUsd
          ),
          pendingBalance: formatters.getAmount(
            item.pendingQuantity,
            currency?.decimals
          ),
          balanceIncludingPending: formatters.getAmount(
            item.quantityIncludingPending,
            currency.decimals
          ),
          balanceIncludingPendingWithCurrencyCode: formatters.getCurrency(
            item.quantityIncludingPending,
            currency.decimals,
            currency.displayCode
          ),
          balanceIncludingPendingUsdWithCurrencyCode:
            formatters.getBaseCurrency(item.quantityIncludingPendingUsd),
          tradableQuantity: item.tradableQuantity || '0',
          tradableQuantityUsd: formatters.getAmount(
            item.tradableQuantityUsd,
            2
          ),
          transferableQuantity: item.transferableQuantity || '0',
          transferableQuantityUsd:
            item.transferableQuantityUsd ||
            formatters.getAmount(
              item.transferableQuantityUsd,
              DEFAULTS.DECIMAL_SCALE
            ),
          transferableQuantityUsdWithCurrencyCode: formatters.getBaseCurrency(
            item.transferableQuantityUsd
          ),
          withdrawableQuantity: item.withdrawableQuantity || '0',
          //   formatters.getAmount(
          //   item.withdrawableQuantity,
          //   DEFAULTS.MAX_DECIMALS,
          //   true,
          //   true
          // ),
          withdrawableQuantityUsd: formatters.getAmount(
            item.withdrawableQuantityUsd,
            DEFAULTS.DECIMAL_SCALE
          ),
          withdrawableQuantityUsdWithCurrencyCode: formatters.getBaseCurrency(
            item.withdrawableQuantityUsd
          ),
          pendingBalanceUsd: formatters.getAmount(item.pendingQuantityUsd, 2),
          value: formatters.getBaseCurrency(item.quantityIncludingPendingUsd),
          mtdReturn: formatters.getCurrency(
            item.mtdNetReturns,
            undefined,
            item.fundDetails?.secondaryAsset?.displayCode || ''
          ),
          rateOfReturnPercentMTD: Number(item.mtdPercent ?? '0'),
          ytdReturn: formatters.getCurrency(
            item.ytdNetReturns,
            undefined,
            item.fundDetails?.secondaryAsset?.displayCode || ''
          ),
          rateOfReturnPercentYTD: Number(item.ytdPercent ?? '0'),
          totalReturnPercent: Number(item.allReturnsPercent ?? '0'),
          totalReturn: formatters.getCurrency(
            item.allReturns,
            undefined,
            item.fundDetails?.secondaryAsset?.displayCode || ''
          ),
          valueAsOfDateTime: item.fundDetails?.unitCostAsOf
            ? 'Value as of ' +
              formatDate(
                new Date(item.fundDetails?.unitCostAsOf),
                DATE_FORMATS.DD_MMM_YYYY,
                true
              )
            : '',
          balanceSecondaryAssetWithCurrencyCode:
            item.fundDetails?.valueIncludingPendingInSecondaryAsset &&
            item.fundDetails.secondaryAsset &&
            item.fundDetails.secondaryAsset.displayCode
              ? formatters.getCurrency(
                  item.fundDetails.valueIncludingPendingInSecondaryAsset,
                  undefined,
                  item.fundDetails.secondaryAsset.displayCode
                )
              : undefined,
        },
      });
    }
  });

  const orderedEnrichedAssets = orderBy(
    enrichedAssets,
    isAccountOfTypeFund ? [SORT_DIR.ASC] : [SORT_DIR.DESC, SORT_DIR.ASC],
    isAccountOfTypeFund
      ? [`currency.${SORT_ASSETS_FIELDS.NAME}`]
      : [SORT_ASSETS_FIELDS.BALANCE_USD, `currency.${SORT_ASSETS_FIELDS.NAME}`]
  );

  const enrichedAssetsWithBalanceOrEarnedInterest =
    orderedEnrichedAssets.filter(a => {
      return (
        a.quantity.gt(0) ||
        BigOrZero(a.earnedInterestThisWeek).gt(0) ||
        BigOrZero(a.pendingQuantity).gt(0)
      );
    });

  const earnedInterestTotalUsd = BigNumber(
    accountDetail.earnedInterestTotalUsd ?? '0'
  );
  const earnedInterestThisWeekUsd = BigNumber(
    accountDetail.earnedInterestThisWeekUsd ?? '0'
  );

  const totalWithdrawableUsd = BigNumber(
    accountDetail.totalWithdrawableUsd ?? '0'
  );
  const totalTradableUsd = BigNumber(accountDetail.totalTradableUsd ?? '0');
  const totalPendingSettlementUsd = BigNumber(
    accountDetail.totalPendingSettlementUsd ?? '0'
  );
  const totalBalanceIncludingPendingUsd = BigNumber(
    accountDetail.totalBalanceIncludingPendingUsd ?? '0'
  );
  const totalOpenOrdersUsd = BigNumber(accountDetail.totalOpenOrdersUsd ?? '0');
  const totalPendingOutgoingUsd = BigNumber(
    accountDetail.totalPendingOutgoingUsd ?? '0'
  );
  const totalPendingSendUsd = BigNumber(
    accountDetail.totalPendingSendUsd ?? '0'
  );
  const totalPendingOutgoingTransferOutUsd = BigNumber(
    accountDetail.totalPendingOutgoingTransferOutUsd ?? '0'
  );
  const totalPendingIncomingUsd = BigNumber(
    accountDetail.totalPendingIncomingUsd ?? '0'
  );
  const totalPendingReceiveUsd = BigNumber(
    accountDetail.totalPendingReceiveUsd ?? '0'
  );
  const totalPendingIncomingTransferInUsd = BigNumber(
    accountDetail.totalPendingIncomingTransferInUsd ?? '0'
  );
  const totalFiatUsd = BigNumber(accountDetail.totalFiatUsd ?? '0');
  const totalCryptoUsd = BigNumber(accountDetail.totalCryptoUsd ?? '0');
  const totalBalanceUsd = BigNumber(accountDetail.totalBalanceUsd ?? '0');

  const hasBalance = totalBalanceUsd.gt(0);
  const hasCrypto = totalCryptoUsd.gt(0);
  const hasFiat = totalFiatUsd.gt(0);

  const allowedActions: ActionId[] = [];

  // Trade
  if (accountDetail.canTrade && hasBalance) {
    allowedActions.push(ActionId.Buy);
    allowedActions.push(ActionId.Sell);
  }

  // Send
  if (accountDetail.canSendCrypto && hasCrypto && hasBalance) {
    allowedActions.push(ActionId.Send);
  }

  // Receive
  if (accountDetail.canReceiveCrypto) {
    allowedActions.push(ActionId.Receive);
  }

  // Transfer
  const hasManyAccounts = accounts.length > 1;

  if (
    hasManyAccounts &&
    (accountDetail.canReceiveAccountTransfer ||
      (hasBalance && accountDetail.canSendAccountTransfer))
  ) {
    allowedActions.push(ActionId.Transfer);
  }

  // Add cash
  if (accountDetail.canReceiveCash) {
    allowedActions.push(ActionId.AddCash);
  }

  // Widthdraw cash
  if (accountDetail.canSendCash && hasFiat && hasBalance) {
    allowedActions.push(ActionId.WithdrawCash);
  }

  let balanceChangeValue: string | null;
  let balanceChangePercent: string | null;

  const isFundAccount =
    accountDetail.account?.accountType === API.AccountType.Fund;

  if (isFundAccount) {
    balanceChangeValue = accountDetail.balanceChange1MonthUsd;
    balanceChangePercent = accountDetail.balanceChange1MonthPercent;
  } else {
    balanceChangeValue = accountDetail.balanceChange24HoursUsd;
    balanceChangePercent = accountDetail.balanceChange24HoursPercent;
  }

  const result: EnrichedAccountDetail = {
    ...accountDetail,
    balanceChange: createBalanceChange(
      balanceChangeValue,
      balanceChangePercent
    ),
    canStabletagTransfer: false, // Note: https://stablehouse.atlassian.net/browse/SH-5156
    assets: orderedEnrichedAssets,
    assetsWithBalanceOrEarnedInterest:
      enrichedAssetsWithBalanceOrEarnedInterest,
    settings: accountLevelSettings ?? null,
    allowedActions,
    formatted: {
      earnedInterestTotalUsd: formatters.getAmount(earnedInterestTotalUsd, 2),
      earnedInterestThisWeekUsd: formatters.getAmount(
        earnedInterestThisWeekUsd,
        2
      ),
      totalWithdrawableUsd: formatters.getAmount(totalWithdrawableUsd, 2),
      totalTradeableUsd: formatters.getAmount(totalTradableUsd, 2),
      totalPendingSettlementUsd: formatters.getAmount(
        totalPendingSettlementUsd,
        2
      ),
      totalFiatUsd: formatters.getAmount(totalFiatUsd, 2),
      totalCryptoUsd: formatters.getAmount(totalCryptoUsd, 2),
      totalBalanceUsd: formatters.getAmount(totalBalanceUsd, 2),
      totalBalanceIncludingPendingUsd: formatters.getAmount(
        totalBalanceIncludingPendingUsd,
        2
      ),
      totalOpenOrdersUsd: formatters.getAmount(totalOpenOrdersUsd, 2),
      totalPendingOutgoingUsd: formatters.getAmount(totalPendingOutgoingUsd, 2),
      totalPendingSendUsd: formatters.getAmount(totalPendingSendUsd, 2),
      totalPendingOutgoingTransferOutUsd: formatters.getAmount(
        totalPendingOutgoingTransferOutUsd,
        2
      ),
      totalPendingIncomingUsd: formatters.getAmount(totalPendingIncomingUsd, 2),
      totalPendingReceiveUsd: formatters.getAmount(totalPendingReceiveUsd, 2),
      totalPendingIncomingTransferInUsd: formatters.getAmount(
        totalPendingIncomingTransferInUsd,
        2
      ),
      maskedAccountNumber: accountDetail.account?.accountNumber
        ? maskLead(accountDetail.account.accountNumber)
        : null,
    },
    hasEarnedInterestEver:
      !!accountLevelSettings?.canEarnYield &&
      (earnedInterestTotalUsd.gt(0) || earnedInterestThisWeekUsd.gt(0)),
    isAccountOfTypeProTrading,
    isAccountOfTypeVault,
    isAccountOfTypeFund,
    hasBalance,
    hasCrypto,
    hasFiat,
    holdingsTable: {
      columns: getColumns(
        accountDetail.account,
        globalAppSettings?.userType === API.UserType.ClientAdminReadWrite,
        client
      ),
    },
    labels: {
      holdingsTableTitle: isFundAccount ? `Holdings` : `Holdings and prices`,
      holdingsTableSubtitle: isFundAccount
        ? `Updated on a monthly basis`
        : `Updated as of ${formatReceiptDate(new Date())}`,
    },
  };
  return result;
};
