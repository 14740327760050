import { FC } from 'react';
import cx from 'classnames';
import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import {
  EnrichedCurrencyInformation,
  formatters,
  getCurrencyInfoForCode,
} from 'common';

interface Props {
  interactive?: boolean;
  fxRate: string | undefined | null;
  fxRateContra: string | undefined | null;
  fxSymbol: string | undefined | null;
  currencies: EnrichedCurrencyInformation[] | null;
  currencyFrom: string | undefined | null;
  currencyTo: string | undefined | null;
  cls?: string;
  isReversed?: boolean;
}

export const ConversionRate: FC<Props> = ({
  interactive,
  fxRate,
  isReversed,
  fxRateContra,
  fxSymbol,
  currencies,
  currencyFrom,
  currencyTo,
  cls,
}) => {
  /**
   * State
   */
  const [reversed, setReversed] = useState(isReversed ?? false);

  /**
   * DOM
   */
  if (!currencyFrom || !currencyTo || !currencies || !fxRate || !fxSymbol) {
    return null;
  }

  const fromCcyInfo = getCurrencyInfoForCode(currencyFrom, currencies);
  const toCcyInfo = getCurrencyInfoForCode(currencyTo, currencies);

  if (!fromCcyInfo || !toCcyInfo) {
    return null;
  }

  const [fromCcySymbol] = fxSymbol.split('/');
  const isFromRate =
    fromCcySymbol.toLocaleLowerCase() ===
    fromCcyInfo?.code?.toLocaleLowerCase();
  const fromRate = isFromRate ? fxRate : fxRateContra;
  const toRate = isFromRate ? fxRateContra : fxRate;
  if (!fromRate || !toRate) {
    return <>N/A</>;
  }
  const normal = (
    <>
      1&nbsp;{fromCcyInfo.displayCode} =&nbsp;
      {formatters.getCurrency(
        fromRate,
        undefined,
        toCcyInfo.displayCode || toCcyInfo.code,
        true
      )}
    </>
  );
  const inverted = (
    <>
      1&nbsp;{toCcyInfo.displayCode} =&nbsp;
      {formatters.getCurrency(
        toRate,
        undefined,
        fromCcyInfo.displayCode || fromCcyInfo.code,
        true
      )}
    </>
  );

  if (!interactive) {
    return (
      <div
        className={cx('flex flex-col items-end font-bold text-primary', cls)}
      >
        <div className="flex flex-row items-center">{inverted}</div>
        <div className="flex flex-row items-center">{normal}</div>
      </div>
    );
  }

  return (
    <div className="text-primary flex flex-row items-center justify-center font-semibold mb-2">
      {!reversed ? normal : inverted}
      {!isReversed && (
        <button
          className="text-success ml-1"
          onClick={e => {
            e.preventDefault();
            setReversed(!reversed);
          }}
        >
          <SwitchHorizontalIcon className="w-4 h-4 fill-current-color" />
        </button>
      )}
    </div>
  );
};
