import { FC, useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import { useParams } from 'react-router-dom';
import { useSendForm } from 'common';
import { SettingsAddressBookDetails } from '~/components/settings/address-book';
import { DataStore } from '~/store';

import { SettingsLayout, SettingsMenuIds } from './layout';
import { apiClient } from '~/api/client';

export const AddressBookDetails: FC = () => {
  const { addressId } = useParams<{ addressId: string }>();
  /**
   * Store
   */
  const isLoading = DataStore.useStoreState(
    a => a.settings.cryptoAddresses.busy
  );
  const error = DataStore.useStoreState(a => a.settings.cryptoAddresses.error);
  const withdrawalAddress = DataStore.useStoreState(
    a => a.settings.cryptoAddresses.withdrawalAddress
  );
  const getCurrentAddress = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.getCurrentAddress
  );

  const { setWithdrawalAddressId } = useSendForm({ DataStore, apiClient });

  /**
   * Handlers
   */
  const handlePressSend = useCallback(() => {
    if (addressId) {
      setWithdrawalAddressId(addressId);
    }
  }, [addressId]);

  /**
   * Hooks
   */
  useEffectOnce(() => {
    if (addressId) {
      getCurrentAddress(addressId);
    }
  });

  /**
   * DOM
   */
  return (
    <SettingsLayout
      hasPaddings={false}
      menuId={SettingsMenuIds.CRYPTO_ADDRESSES}
    >
      {!withdrawalAddress && !isLoading && (
        <div className="flex h-full w-full items-center justify-center">
          Address not found
        </div>
      )}
      {withdrawalAddress && (
        <SettingsAddressBookDetails
          error={error}
          address={withdrawalAddress}
          onPressSend={handlePressSend}
          isLoading={isLoading}
        />
      )}
    </SettingsLayout>
  );
};
