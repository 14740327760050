import BigNumber from 'big.js';
import { API } from '@xbto/api-client';
import { ERROR_MESSAGES } from '../../../constants';
import { EnrichedAccountDetailAsset } from '../../../types';
import { validateAmountWithInBalance } from '../../validations/amount-within-balance';
import { validateDestination } from '../../validations/destination';
import { SendForm } from './types';
import { FormErrors } from '../../form';

export async function validateSendForm(
  {
    currencyCode,
    destinationType,
    destination,
    amount,
    withdrawalAddressId,
    description,
  }: SendForm,
  asset: EnrichedAccountDetailAsset | null,
  apiClient: API.StablehouseClient,
  accountId: string,
  forceWhitelistAddress: boolean
) {
  const errors: FormErrors<SendForm> = {};

  // asset
  if (!currencyCode) {
    errors.currencyCode = ERROR_MESSAGES.REQUIRED_VALUE;
  } else if (asset && !asset.hasBalance) {
    errors.currencyCode = ERROR_MESSAGES.INSUFFICIENT_BALANCE;
  }

  // withdraw method
  if (!destinationType) {
    errors.destinationType = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  if (description && description.length > 1024) {
    errors.description = ERROR_MESSAGES.MAX_1024_CHARACTERS;
  }

  if (forceWhitelistAddress) {
    // white list address
    if (!withdrawalAddressId) {
      errors.withdrawalAddressId = ERROR_MESSAGES.REQUIRED_VALUE;
    }
  } else {
    // destination
    if (!destination && !withdrawalAddressId) {
      errors.destination = ERROR_MESSAGES.REQUIRED_VALUE;
    } else if (destination) {
      //
      // Note: we don't need to validate an address from the address-book (`withdrawalAddressId`)
      //

      const blockchainCode = asset?.currency.blockchain ?? '';

      const { isValid, message } = await validateDestination(
        destination,
        blockchainCode,
        apiClient,
        accountId,
        destinationType
      );

      if (!isValid) {
        errors.destination = message || ERROR_MESSAGES.INVALID_DESTINATION;
      }
    }
  }

  // amount
  if (!amount) {
    errors.amount = ERROR_MESSAGES.REQUIRED_VALUE;
  } else {
    if (BigNumber(amount).lte(0)) {
      errors.amount = ERROR_MESSAGES.AMOUNT_MORE_THAN_0;
    }

    const isWithinBalance = validateAmountWithInBalance(amount, asset);
    if (!isWithinBalance) {
      errors.amount = ERROR_MESSAGES.INSUFFICIENT_BALANCE;
    }
  }

  return errors;
}
