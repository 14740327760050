var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@xbto/design-system';
import { sizes } from '../../theme';
import { Col } from '../col';
export const Surface = memo((_a) => {
    var { variant, children, style } = _a, rest = __rest(_a, ["variant", "children", "style"]);
    return (_jsx(Col, Object.assign({}, rest, { style: [$.container, $[variant], style] }, { children: children })));
});
const $ = StyleSheet.create({
    container: {
        paddingHorizontal: sizes.s,
        paddingVertical: sizes.m,
        borderRadius: Number(theme.radius.sm),
    },
    brand: {
        // TODO(Hadrien): Update when new tokens are imported
        backgroundColor: theme.color.brand['100'],
    },
    success: {
        backgroundColor: theme.color.background.success,
    },
    warning: {
        backgroundColor: theme.color.background.warning,
    },
    info: {
        backgroundColor: theme.color.background.info,
    },
    neutral: {
        backgroundColor: theme.color.background.tertiary,
    },
    error: {
        backgroundColor: theme.color.background.error,
    },
});
