import { API } from '@xbto/api-client';
import BigNumber from 'big.js';
import {
  EnrichedAllHolding,
  EnrichedAllHoldings,
  EnrichedCurrencyInformation,
  GlobalAppSettings,
} from '../../types/enriched';
import { formatters } from '../formatters';
import { DEFAULTS } from '../../constants';
import {
  HoldingsTableColumn,
  HoldingsTableColumnId,
  HoldingsTableColumnTitle,
} from '../../store/portfolio/types';
import { DataModel } from '../../store';
import { BigOrZero } from '../big';

const getColumns = (
  isAdmin: boolean,
  client: DataModel['client']
): HoldingsTableColumn[] => {
  const result: HoldingsTableColumn[] = [
    {
      id: HoldingsTableColumnId.Asset,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Asset],
      sortable: true,
      sx: { width: '160%' },
    },
    {
      id: HoldingsTableColumnId.Quantity,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Quantity],
      sortable: true,
      sx: { width: '80%' },
    },
    {
      id: HoldingsTableColumnId.Price,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Price],
      sortable: true,
      sx: { width: '60%' },
    },
    {
      id: HoldingsTableColumnId.Value,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Value],
      sortable: true,
      sx: { width: '60%' },
    },
    {
      id: HoldingsTableColumnId.Change24h,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Change24h],
      sortable: true,
      sx: { width: '40%' },
    },
  ];

  // is not admin user & only show action ... in web app
  if (!isAdmin && client === 'web') {
    result.push({
      id: HoldingsTableColumnId.Action,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Action],
      sortable: false,
      sx: { width: '25%' },
    });
  }

  return result;
};

export const enrichAllHoldings = (
  holdings: API.GetAllAssetsHoldingsResponse | null,
  currencies: EnrichedCurrencyInformation[],
  globalAppSettings: GlobalAppSettings | null,
  client: DataModel['client']
): EnrichedAllHoldings | null => {
  if (!holdings?.currencies) {
    return null;
  }

  const assets: EnrichedAllHolding[] = [];

  holdings.currencies.forEach(_holding => {
    const currency = currencies.find(
      _currency => _currency.code === _holding.currency?.code
    );
    if (currency) {
      const balance = BigOrZero(_holding.totalQty);
      const balanceUsd = BigOrZero(_holding.totalQtyUsd);
      const pendingBalance = BigNumber(0); // Note: API does not provide it at this layer
      const pendingBalanceUsd = BigOrZero(_holding.totalPendingUsd);
      const balanceIncludingPending = balance.add(pendingBalance);
      const balanceIncludingPendingUsd = balanceUsd.add(pendingBalanceUsd);
      const value = currency.fxRate.rate.mul(balanceIncludingPending);
      assets.push({
        currency,
        balance,
        balanceUsd,
        hasBalance: balance.gt(0),
        isAccountOfTypeProTrading: false,
        isAccountOfTypeVault: false,
        isAccountOfTypeFund: false,
        formatted: {
          earnedInterestThisWeekUsd: '0',
          earnedInterestTotalUsd: '0',
          balance: formatters.getAmount(_holding.totalQty, currency?.decimals),
          balanceWithCurrencyCode: formatters.getCurrency(
            _holding.totalQty,
            currency?.decimals,
            currency.displayCode
          ),
          balanceUsd: formatters.getAmount(_holding.totalQtyUsd, 2),
          balanceUsdWithCurrencyCode: formatters.getBaseCurrency(
            _holding.totalQtyUsd
          ),
          pendingBalance: formatters.getAmount(
            pendingBalance,
            currency?.decimals
          ),
          pendingBalanceUsd: formatters.getAmount(pendingBalanceUsd, 2),
          balanceIncludingPending: formatters.getAmount(
            balanceIncludingPending.toFixed(),
            undefined // use fallback mantissa calc
          ),
          balanceIncludingPendingWithCurrencyCode: formatters.getCurrency(
            balanceIncludingPending.toFixed(),
            undefined, // use fallback mantissa calc
            currency.displayCode
          ),
          balanceIncludingPendingUsdWithCurrencyCode:
            formatters.getBaseCurrency(balanceIncludingPendingUsd),
          tradableQuantity: '0',
          tradableQuantityUsd: formatters.getAmount(
            _holding.totalTradableUsd,
            2
          ),
          transferableQuantity: '0',
          transferableQuantityUsd: '0',
          transferableQuantityUsdWithCurrencyCode: '0',
          withdrawableQuantity: '0',
          withdrawableQuantityUsd: formatters.getAmount(
            _holding.totalWithdrawableUsd,
            DEFAULTS.DECIMAL_SCALE
          ),
          withdrawableQuantityUsdWithCurrencyCode: formatters.getBaseCurrency(
            _holding.totalWithdrawableUsd
          ),
          value: formatters.getBaseCurrency(value),
          rateOfReturnPercentMTD: 0,
          rateOfReturnPercentYTD: 0,
          totalReturnPercent: 0,
          totalReturn: '0',
          mtdReturn: '0',
          ytdReturn: '0',
        },
      });
    }
  });

  return {
    assets,
    holdingsTable: {
      columns: getColumns(
        globalAppSettings?.userType === API.UserType.ClientAdminReadWrite,
        client
      ),
    },
  };
};
