import assetsAbout from '../data/generated/assets-description.json';

export interface AssetAbout {
  description: string;
  usecase: string | null;
  procon: string | null;
  ctaLink?: string | null;
  ctaTitle?: string | null;
  headerImgWeb?: string | null;
  headerImgMobile?: string | null;
}

export function getAssetInfo(
  currencyCode: string | null | undefined
): AssetAbout | null {
  if (!currencyCode) {
    return null;
  }
  return assetsAbout[currencyCode] || null;
}
