import { API } from '@xbto/api-client';
import {
  action,
  Action,
  actionOn,
  ActionOn,
  computed,
  Computed,
  Thunk,
  thunk,
} from 'easy-peasy';
import { factories, getApiErrorMessage } from '../../utils';
import { DataModel } from '../data-store';
import { Injections } from '../types';
import { BaseModel, createBaseModel } from '../base-store';
import { DEFAULTS } from '../../constants';
import { Enriched } from '../../types';
import { _extendPausableModel, _PausableModel } from '../_pausable/store';

const _getAssetCodes = (listResult: API.SimplifiedListAsset[] | null) => {
  const result: string[] = [];
  if (!listResult) {
    return result;
  }
  for (const item of listResult) {
    if (!item.code) {
      continue;
    }
    result.push(item.code);
  }
  return result;
};

type MarketData = {
  pagination: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };
  result: API.ListAssetDetailsResponse[];
};

type RemoveNullable<T> = {
  [K in keyof T]: NonNullable<T[K]>;
};

export type MarketFilters = RemoveNullable<API.MarketListAssetsRequest>;

export interface MarketsModel extends BaseModel, _PausableModel {
  _onViewChange: ActionOn<MarketsModel, DataModel>;

  _filters: MarketFilters;
  _setFilters: Action<MarketsModel, MarketFilters>;

  // >> `<api>/market/list`
  _data: MarketData | null;
  _setData: Action<MarketsModel, MarketData | null>;
  data: Computed<MarketsModel, Enriched.MarketData | null, DataModel>;
  getData: Thunk<
    MarketsModel,
    | {
        body?: API.MarketListAssetsRequest;
        reFetch?: boolean;
      }
    | undefined,
    Injections,
    DataModel
  >;
  startAggregatorTracking: Thunk<
    MarketsModel,
    undefined,
    Injections,
    DataModel
  >;
}

export const initialMarketFilters: MarketFilters = {
  page: 1,
  // Note: We agreed in product requirements that this would be a large number so for first implementation we do not show pagination control
  pageSize: DEFAULTS.PAGE_SIZE * 5, // default page size is 20, setting markets v2 page to 100
  search: '',
  types: [API.MarketAssetType.Crypto],
  categories: [] as API.MarketAssetCategory[],
  sortDirection: API.SortDirection.Descending,
  sortColumn: API.MarketListAssetsSortType.MarketCap,
};

export const marketsModel: MarketsModel = {
  ...createBaseModel(),
  ..._extendPausableModel(),

  _onViewChange: actionOn(
    (_actions, storeActions) => storeActions.setCurrentViewName,
    (state, target) => {
      state.paused =
        target.payload !== 'markets-list' && target.payload !== 'asset-details';
    }
  ),

  _filters: { ...initialMarketFilters },
  _setFilters: action((state, payload) => {
    state._filters = payload;
  }),

  _data: null,
  _setData: action((state, payload) => {
    state._data = payload;
  }),
  data: computed([s => s._data], _marketsData => {
    if (!_marketsData) {
      return null;
    }
    const _rows = factories.enrichListAssetDetails(_marketsData?.result || []);
    const result: Enriched.MarketData = {
      rows: _rows,
      pagination: _marketsData.pagination,
    };
    return result;
  }),
  getData: thunk(
    async (
      actions,
      _payload,
      { injections: { apiClient, marketsDataAggregator }, getState }
    ) => {
      try {
        if (!_payload?.reFetch) {
          return;
        }

        actions.setBusy(true);

        const body =
          _payload?.body || getState()._filters || initialMarketFilters;
        const listResponse = await apiClient.listAssets(body);

        if (listResponse.errorCode || !listResponse.isSuccessful) {
          actions.setError(listResponse.errorMessage);
          actions.setBusy(false);
          return;
        }

        const pagination = {
          page: listResponse?.page,
          pageSize: listResponse?.pageSize,
          totalCount: listResponse?.totalCount,
          totalPages: listResponse?.totalPages,
        };

        const _assetCodes = _getAssetCodes(listResponse.result);

        if (!_assetCodes.length) {
          actions._setData({
            result: [],
            pagination,
          });
          actions.setBusy(false);
          return;
        }

        /**
         * Note:
         * When a user switches a tab,
         * we want to fetch the details on first pass immediately for FMP reasons
         * This happens below:
         * 1. if it is in the aggregator we get it form there
         * 2. for the rest we make an xhr call
         *
         * Over the lifetime of the app the aggregator will have all the data via `ws` updating & fetch/ rest api will become rare
         */
        const { hasAllData, assetCodesWithoutData, aggregatedData } =
          marketsDataAggregator.get(_assetCodes);
        const result: API.ListAssetDetailsResponse[] = [];

        if (!hasAllData) {
          const detailsResponse = await apiClient.listAssetDetails({
            assets: assetCodesWithoutData,
            /**
             * Note:
             * if we pass the list responses page options here, we will get empty array on the details,
             * as the details is simply an endpoint to get optionally more info and is not married to paging
             */
            page: 1,
            pageSize: assetCodesWithoutData.length,
          });

          if (
            detailsResponse.errorCode ||
            !detailsResponse.isSuccessful ||
            !detailsResponse.result
          ) {
            actions.setError(detailsResponse.errorMessage);
            actions.setBusy(false);
            return;
          }

          const combined = [...aggregatedData, ...detailsResponse.result];
          for (const _code of _assetCodes) {
            const _item = combined.find(c => c.code === _code);
            if (!_item) {
              continue;
            }
            result.push(_item);
          }
        } else {
          result.push(...aggregatedData);
        }

        actions._setData({
          result,
          pagination,
        });
        actions.setBusy(false);

        marketsDataAggregator.receive(assetCodesWithoutData);
      } catch (error) {
        const message = getApiErrorMessage(error);
        actions.setError(message);
        actions.setBusy(false);
      }
    }
  ),
  startAggregatorTracking: thunk(
    (
      actions,
      _payload,
      { injections: { marketsDataAggregator }, getState }
    ) => {
      marketsDataAggregator.feed.subscribe(value => {
        const _state = getState();
        if (_state.paused) {
          // console.log(`skip market data update`);
          return;
        }

        if (!_state._data) {
          return;
        }
        if (!value || !value.code) {
          return;
        }
        const _dataHasValue = _state._data.result.find(
          d => d.code === value.code
        );
        if (!_dataHasValue) {
          return;
        }

        // console.debug(
        //   `Updating for ${value.code} ${value.data?.cryptoMarketDetail?.price}`
        // );
        const newResult = _state._data.result.map(r => {
          if (r.code === value.code) {
            return value;
          }
          return r;
        });
        const _newData: MarketData = {
          result: newResult,
          pagination: _state._data.pagination,
        };
        actions._setData(_newData);
      });
    }
  ),
};
