import BigNumber from 'big.js';

export const getPercentageChange = (
  oldValue: number | BigNumber,
  newValue: number | BigNumber
): number => {
  const _old = BigNumber(oldValue);
  const _new = BigNumber(newValue);

  const decreaseValue = _new.sub(_old);
  const result = decreaseValue.div(_old).mul(100);

  return result.toNumber(); // ok to loose precision here (%).
};
