var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { ValidationError } from 'yup';
export function ValidationProvider({ children }) {
    const [errors, setErrors] = useState({});
    const setError = useCallback((field, error) => {
        setErrors(prev => (Object.assign(Object.assign({}, prev), { [field]: error })));
    }, []);
    const validateField = useCallback((field, value, schema, label) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield schema.label(label || field).validate(value);
            setError(field, null);
            return { valid: true, error: undefined };
        }
        catch (err) {
            if (err instanceof ValidationError) {
                setError(field, err.message);
                return { valid: false, error: err.message };
            }
            else {
                console.error('Unexpected error during validation', err);
                return { valid: false, error: 'An unknown error occurred' };
            }
        }
    }), [setError]);
    const hasErrors = Object.values(errors).some(err => !!err);
    return _jsx(_Fragment, { children: children({ errors, setError, validateField, hasErrors }) });
}
