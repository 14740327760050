var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement, useCallback, useMemo, useState } from 'react';
import { Platform, TextInput as RNTextInput, StyleSheet, View, } from 'react-native';
import { theme } from '@xbto/design-system';
import { sizes } from '../../theme';
import { Row } from '../row';
import { fontBoldStyle, fontMediumStyle } from '../text';
import { useInputFocusManager } from '../../hooks';
import { isWeb } from '../../utils';
import { Col } from '../col';
import { ValidationMessage } from '../../molecules/validation-message';
const hitMargin = sizes.s;
const inputHitSlop = {
    top: hitMargin,
    bottom: hitMargin,
    left: hitMargin,
    right: hitMargin,
};
export const TextInput = props => {
    const { value, onValueChange, onKeyPress, validate, schema, field, maxLength = 256, placeholder = 'Search', style = undefined, } = props;
    /**
     * Hooks
     */
    const { isFocused, onFocus, onBlur } = useInputFocusManager(props);
    const [error, setError] = useState(undefined);
    /**
     * Methods
     */
    const handleOnChange = useCallback((newValue) => __awaiter(void 0, void 0, void 0, function* () {
        if (!onValueChange) {
            return;
        }
        if (newValue === value) {
            return;
        }
        if (validate && schema) {
            const { error } = yield validate(placeholder, newValue, schema, field);
            setError(error);
        }
        onValueChange(newValue);
    }), [onValueChange, value, validate, schema, placeholder, field]);
    /**
     * DOM
     */
    const computedInputStyles = useMemo(() => {
        const styles = [$.textInput];
        if (isFocused && isWeb) {
            styles.push({
                // @ts-expect-error - web specific property
                outline: 'none',
            });
        }
        if (value === null || value === void 0 ? void 0 : value.length) {
            styles.push($.value);
        }
        else {
            styles.push($.placeholder);
        }
        return styles;
    }, [isFocused, value]);
    return (_jsx(InputLabel, Object.assign({ style: $.label }, { children: _jsxs(Col.LRC, Object.assign({ style: $.validationWrapper }, { children: [_jsx(Row.L, Object.assign({ px: "s", py: "xs", style: [
                        $.inputContainer,
                        style,
                        isFocused && $.focused,
                        !!error && $.errored,
                        // TODO(Hadrien): Manage disabled && inputStyles.disabled,
                    ] }, { children: _jsx(RNTextInput, { style: computedInputStyles, value: value, onChangeText: handleOnChange, onKeyPress: onKeyPress, maxLength: maxLength, placeholder: placeholder, keyboardType: "web-search", returnKeyType: "search", allowFontScaling: false, autoCapitalize: "none", autoCorrect: false, keyboardAppearance: "dark", numberOfLines: 1, 
                        // TODO(@benoit/ @hadrien): once we have tokens
                        placeholderTextColor: "#B8B8B8" // theme.color.text.placeholder
                        , selectionColor: theme.color.text.primary, underlineColorAndroid: "transparent", onBlur: onBlur, onFocus: onFocus, hitSlop: inputHitSlop }) })), _jsx(ValidationMessage, { title: error, variant: "error" })] })) })));
};
// eslint-disable-next-line react/prop-types
const InputLabel = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return Platform.OS === 'web' ? (createElement('label', props, children)) : (_jsx(View, Object.assign({}, props, { children: children })));
};
const $ = StyleSheet.create({
    inputContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: Number(theme.radius.sm),
        backgroundColor: theme.color.background.tertiary,
        borderColor: theme.color.border.primary,
        borderWidth: 1,
        maxHeight: Platform.select({ web: 52, default: 48 }),
        paddingVertical: Platform.select({ web: 16, default: 12 }),
        paddingHorizontal: Platform.select({ web: 16, default: 14 }),
    },
    textInput: {
        fontSize: Platform.select({ web: 14, default: 12 }),
        lineHeight: Platform.select({ web: 20, default: 16 }),
        height: 20,
        color: theme.color.text.primary,
        flexGrow: 1,
        backgroundColor: theme.color.background.tertiary,
        borderColor: 'transparent',
        borderRadius: Number(theme.radius.sm),
    },
    // @ts-expect-error web specific property
    label: Platform.select({ web: { cursor: 'pointer' }, default: {} }),
    validationWrapper: { rowGap: theme.spacing['2'] },
    focused: {
        borderColor: theme.color.border.tertiary,
    },
    errored: {
        borderColor: theme.color.border.error,
    },
    value: Object.assign({}, fontMediumStyle),
    placeholder: Object.assign({}, fontBoldStyle),
});
