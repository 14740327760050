import { API } from '@xbto/api-client';
import { getSimulationRateLabel } from '../simulation';
import { Enriched, EnrichedCurrencyInformation } from '../../types';
import { formatters } from '../formatters';
import { TradeFormValues } from '../../store';

export const enrichTradeSimulation = (
  simulation: API.TradeSimulation | null,
  formValues: TradeFormValues,
  currencies: EnrichedCurrencyInformation[]
): Enriched.TradeSimulation | null => {
  if (
    simulation?.currencyFrom &&
    simulation?.currencyTo &&
    simulation?.currencyDisplayFrom &&
    simulation?.currencyDisplayTo
  ) {
    const isSell = formValues.side === API.Side.Sell;

    const amountFromWithCurrencyCode = formatters.getCurrency(
      simulation.amountFrom,
      undefined,
      simulation.currencyDisplayFrom
    );
    const amountToWithCurrencyCode = formatters.getCurrency(
      simulation.amountTo,
      undefined,
      simulation.currencyDisplayTo
    );
    const amountUsdFromWithCurrencyCode = formatters.getCurrency(
      simulation.amountFromHomeCurrencyEquivalent,
      undefined,
      'USD'
    );
    const amountUsdToWithCurrencyCode = formatters.getCurrency(
      simulation.amountToHomeCurrencyEquivalent,
      undefined,
      'USD'
    );

    return {
      ...simulation,
      isSell,
      side: formValues.side,
      formatted: {
        amountWithCurrencyCode: isSell
          ? amountFromWithCurrencyCode
          : amountToWithCurrencyCode,
        amountFromWithCurrencyCode,
        amountToWithCurrencyCode,
        amountUsdWithCurrencyCode: isSell
          ? amountUsdFromWithCurrencyCode
          : amountUsdToWithCurrencyCode,
        amountUsdFromWithCurrencyCode,
        amountUsdToWithCurrencyCode,
        rate: getSimulationRateLabel(
          simulation,
          // Buy _BTC_ with USD (From = USD): 1 BTC = 70,348 USD
          // Sell _BTC_ to USD (To = USD): 1 BTC = 70,195 USD
          isSell ? simulation.currencyTo : simulation.currencyFrom, // quote is USD
          currencies
        ),
        rateContra: getSimulationRateLabel(
          simulation,
          // Buy BTC with _USD_ (To = BTC): "1 USD = 0.000014215 BTC"
          // Sell BTC to _USD_ (From = BTC): "1 USD = 0.000014248 BTC"
          isSell ? simulation.currencyFrom : simulation.currencyTo, // quote is BTC
          currencies
        ),
      },
    };
  }
  return null;
};
