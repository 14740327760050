import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@xbto/design-system';
import { Col, Row, TextBody } from '../../atoms';
import { Hypertext, Icon } from '../../molecules';
export const KytLearnMore = memo(() => {
    return (_jsx(Col.C, { children: _jsx(Hypertext, Object.assign({ href: "https://helpcenter.xbto.com/xbto-help_centre/travel-rule-updates-to-crypto-transfer-procedures", hrefAttrs: { target: '_blank' } }, { children: _jsxs(Row.LR, Object.assign({ style: $.link }, { children: [_jsx(TextBody, { children: "Why do we need this information?" }), _jsx(Icon, { name: "arrow-outward", size: "sm" })] })) })) }));
});
const $ = StyleSheet.create({
    link: {
        columnGap: theme.spacing['0.5'],
    },
});
