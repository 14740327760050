import { FC, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { AddCryptoAddressSteps } from './steps';
import { ConfirmCancel } from '../shared/confirm-cancel';
import { APP_ROUTES } from '~/routes';
import { DialogProps } from 'common';

export const ConfirmCancelAddCryptoAddress: FC<
  Partial<StepWizardChildProps> & {
    refreshAddresses: () => void;
  } & Partial<DialogProps>
> = ({ goToNamedStep, refreshAddresses, showCloseDialog }) => {
  /* Store */
  const currentAddress = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.currentAddress
  );
  const cancelWithdrawalAddress = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.cancelWithdrawalAddress
  );

  if (!goToNamedStep) {
    return null;
  }
  const navigate = useNavigate();
  const location = useLocation();

  /*
   * Handlers
   */
  const handleCancel = async () => {
    if (currentAddress?.id) {
      const response = await cancelWithdrawalAddress(currentAddress.id);
      if (response.isSuccessful) {
        goToNamedStep(AddCryptoAddressSteps.Cancelled);
        refreshAddresses();
        if (location.pathname.includes(APP_ROUTES.AUTH_SETTINGS)) {
          navigate(APP_ROUTES.AUTH_SETTINGS_CRYPTO_ADDRESSES);
        }
      }
    }
  };
  const handleNoActionClick = useCallback(() => {
    goToNamedStep(AddCryptoAddressSteps.Code);
  }, []);

  useEffect(() => {
    showCloseDialog?.(false);
    return () => {
      showCloseDialog?.(true);
    };
  }, []);

  /**
   * DOM
   */
  return (
    <ConfirmCancel
      title="Do you want to cancel adding this address?"
      subTitle="We will cancel your request to add this address in your address book."
      noActionText="No, go back"
      submitText="Yes, cancel"
      onNoActionClick={handleNoActionClick}
      onSubmitClick={handleCancel}
    />
  );
};
