import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { TextCaption } from '../../atoms/text';
export function SortableHeaderTitle({ active = false, id, label, onPress, }) {
    // Handlers
    const handlePress = useCallback(() => {
        if (id) {
            onPress === null || onPress === void 0 ? void 0 : onPress(id);
        }
    }, [id, onPress]);
    // Render
    return (_jsx(Pressable, Object.assign({ disabled: !onPress, onPress: handlePress }, { children: _jsx(TextCaption, Object.assign({ em: true, color: active ? 'primary' : 'tertiary' }, { children: label })) })));
}
