import * as yup from 'yup';
const letterRegex = /\p{L}/u; // allow any kind of unicode of character but not emojis
const baseString = yup
    .string()
    .trim()
    .min(1, ({ label }) => `${label !== null && label !== void 0 ? label : 'Field'} is required`)
    .test('has-letter', ({ label }) => `${label !== null && label !== void 0 ? label : 'Field'} must contain at least one letter`, value => letterRegex.test(value || ''))
    .test('no-special-alone', 'Spaces, hyphens, and apostrophes require letters', value => {
    const v = value || '';
    const isOnlySpecials = /^[\s\-']*$/.test(v);
    return !isOnlySpecials;
});
export const nameSchema = baseString.matches(/^[\p{L}\s\-']+$/u, ({ label }) => `${label !== null && label !== void 0 ? label : 'Field'} has invalid characters`);
export const accountSchema = baseString.matches(/^[\p{L}\p{N}\s\-']+$/u, ({ label }) => `${label !== null && label !== void 0 ? label : 'Field'} has invalid characters`);
export const companySchema = baseString.matches(/^[\p{L}\p{N}\s\-'\\.,&\\/()]+$/u, ({ label }) => `${label !== null && label !== void 0 ? label : 'Field'} has invalid characters`);
