import { API } from '@xbto/api-client';

import { formatters } from '../formatters';
import { Enriched } from '../../types';
import { maskLead } from '../mask-lead';
import { BigOrZero } from '../big';

export function enrichListAccountItem(
  item: API.ListAccountItem
): Enriched.ListAccountItem {
  return {
    ...item,
    hasBalance: BigOrZero(item.totalAccountBalanceUsd).gt(0),
    formatted: {
      totalUsd: formatters.getBaseCurrency(item.totalAccountBalanceUsd),
      maskedAccountNumber: item.account?.accountNumber
        ? maskLead(item.account.accountNumber)
        : null,
    },
  };
}
