import { FC } from 'react';
import { useEffectOnce } from 'react-use';
import { APP_ROUTES } from '../../routes';
import { OpenLayout } from '../layouts/open-layout';
import { FallbackCta } from '~/components/404/fallback-cta';
import { DataStore } from '~/store';

export const Unrestricted429: FC = () => {
  /**
   * Store
   */
  const { setAccessToken } = DataStore.useStoreActions(a => ({
    setAccessToken: a.user.setAccessToken,
  }));

  useEffectOnce(() => {
    setAccessToken(null);
  });

  return (
    <OpenLayout
      icon="flag-outline"
      title="Oops... Too many requests"
      cta={
        <FallbackCta
          href={APP_ROUTES.NON_AUTH_HOME}
          label="Go to sign in page"
        />
      }
      withAppWrapper
    >
      You have made too many requests. Please wait a moment and try again
    </OpenLayout>
  );
};
