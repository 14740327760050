import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { _KytWrapper } from './_kyt-wrapper';
import { Button, Col, TextInput } from '../../atoms';
import { useInputValueManager } from '../../hooks';
import { defaultWidthForInputsAndButtons } from './shared';
import { companySchema } from '../../utils/validation/validate-schema';
import { ValidationProvider } from '../../utils/validation/validation-context';
import { KytLearnMore } from '../../organisms/kyt-learn-more';
import { StyleSheet } from 'react-native';
import { sizes } from '../../theme';
const KytOwnerCompanyInner = ({ onNext }) => {
    /**
     * Hooks
     */
    const { value: companyName, onValueChanged: onCompanyNameChanged } = useInputValueManager();
    /**
     * Methods
     */
    const handleOnNext = useCallback(() => {
        onNext({ companyName });
    }, [companyName, onNext]);
    /**
     * DOM
     */
    return (_jsx(ValidationProvider, { children: ({ validateField, hasErrors }) => {
            const isNextDisabled = companyName.length <= 0 || hasErrors;
            return (_jsxs(Col, Object.assign({ style: $.container }, { children: [_jsx(TextInput, { placeholder: "Enter name of company", style: defaultWidthForInputsAndButtons, value: companyName, onValueChange: onCompanyNameChanged, validate: validateField, schema: companySchema, field: "Company" }), _jsx(Col, Object.assign({ mt: "xs" }, { children: _jsx(Button, { variant: "secondary", label: "Next", disabled: isNextDisabled, onPress: handleOnNext }) }))] })));
        } }));
};
export const KytOwnerCompany = ({ onNext }) => {
    /**
     * DOM
     */
    return (_jsxs(_KytWrapper, Object.assign({ title: "Enter the name of the wallet owner", subtitle: "To adhere to rules within your jurisdiction we need to know who owns the wallet you are withdrawing to" }, { children: [_jsx(KytOwnerCompanyInner, { onNext: onNext }), _jsx(KytLearnMore, {})] })));
};
const $ = StyleSheet.create({
    container: {
        marginBottom: sizes.xxl,
        rowGap: sizes.xs,
    },
});
