import {
  AppIcon,
  EnrichedCryptoAddress,
  TextInput,
  useAppDialog,
} from 'common';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Error } from '~/components/common/error';
import { FundAddressesList } from '~/components/fund-addresses-list';
import { AddCryptoAddressFlow } from '~/components/workflows/add-crypto-address';
import { Divider } from '~/components/settings/shared';

interface SettingsAddressBookProps {
  error?: string | null;
  addresses: EnrichedCryptoAddress[];
  refreshAddresses: () => void;
}

export const SettingsAddressBook: FC<SettingsAddressBookProps> = ({
  refreshAddresses,
  addresses,
  error,
}) => {
  /**
   * State
   */
  const [search, setSearch] = useState('');

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { showDialog, hideDialog } = useAppDialog();

  /** Handlers */
  const handleAddressClick = (addressId: string) => {
    navigate(`${addressId}`);
  };
  const handleClickAdd = useCallback(() => {
    showDialog(
      <AddCryptoAddressFlow
        onClose={hideDialog}
        showIntro={!!addresses?.length}
        refreshAddresses={refreshAddresses}
      />
    );
  }, [showDialog, hideDialog, refreshAddresses]);

  /**
   * DOM
   */
  const filteredAddresses = addresses.filter(
    address =>
      address.label
        ?.toLocaleLowerCase()
        ?.includes(search.toLocaleLowerCase()) ||
      address.currencyCode
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase()) ||
      address.address.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  const noAddresses = !addresses || addresses.length === 0;

  if (noAddresses) {
    return (
      <div className="flex flex-col px-6 py-10 gap-4 h-full justify-center items-center text-center">
        <AppIcon icon="external-circle" bg="" size={54} />
        <span className="text-primary text-2xl font-bold max-w-md">
          Adding an address allows you to send assets quicker and easier.
        </span>
        <span className="text-grey-darker text-base max-w-md">
          For ease and convenience, add any address(es) you may frequently use.
          Please ensure that external crypto wallet address details are correct
          - incorrect details could result in loss of assets.
        </span>

        <button className="app-button-primary text-sm" onClick={handleClickAdd}>
          Add new address{' '}
          <AppIcon cls="ml-2 fill-current-color" icon="plus" bg="" size={18} />
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col h-full">
        {/* heading  */}
        <h5 className="px-4 md:px-8 pt-6 pb-2 font-bold typo-20">
          Address Book
        </h5>

        <div className="flex flex-col gap-2 md:flex-row px-4 md:px-8 py-4 justify-between">
          <TextInput
            clsWrapper="md:w-450"
            value={search}
            type="text"
            minLength={2}
            spellCheck={false}
            placeholder="Search"
            leftAddonBorder={false}
            handleClearInput={() => setSearch('')}
            leftAddon={
              <AppIcon icon="search-input" bg="bg-transparent" size={18} />
            }
            onChange={({ target }) => setSearch(target?.value)}
          />
          <button
            className="app-button-outline text-sm"
            onClick={handleClickAdd}
          >
            Add new address{' '}
            <AppIcon
              cls="ml-2 fill-current-color"
              icon="plus"
              bg=""
              size={18}
            />
          </button>
        </div>
        <Divider />

        {/* error */}
        {error && (
          <div className="border-b-1">
            <Error cls="py-4 px-8" message={error} />
          </div>
        )}

        {/* content */}
        <div className="flex flex-col">
          <FundAddressesList
            showTableHeader={false}
            onClickAddress={handleAddressClick}
            addresses={filteredAddresses}
          />
        </div>
      </div>
    </>
  );
};
