import { API } from '@xbto/api-client';
import { Enriched, EnrichedCurrencyInformation } from '../../types';
import { formatters } from '../formatters';
import { BigOrZero } from '../big';

export const enrichFiatWithdrawaSimulation = (
  simulation: API.FiatWithdrawalSimulation | null,
  currencies: EnrichedCurrencyInformation[]
): Enriched.FiatWithdrawalSimulation | null => {
  if (!simulation || !simulation.currencyCode || !simulation.amount) {
    return null;
  }

  const currency = currencies.find(
    _currency => _currency.code === simulation.currencyCode
  );

  const displayCurrencyCode = currency?.displayCode ?? simulation.currencyCode;

  const feeWithCurrencyCode = formatters.getCurrency(
    simulation.fee,
    undefined,
    displayCurrencyCode
  );
  const amountReceivedWithCurrencyCode = formatters.getCurrency(
    simulation?.amount,
    undefined,
    displayCurrencyCode
  );
  const amountSent = BigOrZero(simulation.amount)
    .add(BigOrZero(simulation.fee))
    .toString();

  const amountSentWithCurrencyCode = formatters.getCurrency(
    amountSent,
    undefined,
    displayCurrencyCode
  );

  const fee = BigOrZero(simulation.fee);

  return {
    ...simulation,
    formatted: {
      fee: formatters.getAmount(simulation.fee, undefined),
      feeWithCurrencyCode,
      feeWithCurrencyCodeAndSign: fee.eq(0)
        ? feeWithCurrencyCode
        : formatters.forceSign(feeWithCurrencyCode, '-'),

      amountReceived: formatters.getAmount(simulation.amount, undefined),
      amountReceivedWithCurrencyCode,
      amountReceivedWithCurrencyCodeAndSign: formatters.forceSign(
        amountReceivedWithCurrencyCode,
        '+'
      ),

      amountSent,
      amountSentWithCurrencyCode,
      amountSentWithCurrencyCodeAndSign: formatters.forceSign(
        amountSentWithCurrencyCode,
        '-'
      ),
    },
  };
};
