import { ReactComponent as LogoApex } from 'common/dist/assets/logo/logo-apex.svg';
import cx from 'classnames';
import { FC, useMemo } from 'react';
import {
  AppIcon,
  AppIconType,
  IconSize,
  useComputedConfig,
  useMediaQuery,
} from 'common';

type logoTypeProps = 'header' | 'footer' | 'error';

export interface Props {
  cls?: string;
  fill?: string;
  titleCls?: string;
  icon?: AppIconType;
  withTitle?: boolean;
  logoType?: logoTypeProps;
  variant: 'dark' | 'light';
  size?: IconSize | undefined;
  height?: IconSize | undefined;
}

const AppLogoBrandDefault: FC<Props> = ({
  icon,
  fill,
  size,
  height,
  variant,
  cls = '',
  titleCls,
  withTitle = false,
}) => {
  if (!icon) {
    return null;
  }
  /**
   * DOM
   */
  const hasCustomTitle = withTitle && Boolean(titleCls);
  return (
    <div
      className={cx(
        'cursor-pointer relative svg-stretch-w flex flex-col items-center justify-center',
        cls,
        { 'gap-y-1': hasCustomTitle, 'gap-y-6': !hasCustomTitle }
      )}
    >
      <AppIcon
        size={size}
        height={height}
        bg="bg-transparent"
        fill={
          fill ||
          (variant === 'dark'
            ? 'fill-current-color-dark'
            : 'fill-current-color-white')
        }
        icon={icon}
      />
      {withTitle && (
        <span
          className={cx(titleCls, {
            'text-gray-400 self-center font-bold': !titleCls,
          })}
        >
          Institutional access to digital assets
        </span>
      )}
    </div>
  );
};

export const AppLogo: FC<Props> = props => {
  /**
   * Store
   */
  const { tenant } = useComputedConfig();

  /**
   * Hooks
   */
  const { XS } = useMediaQuery();

  /**
   * DOM
   */
  const logoSizes = useMemo(() => {
    if (tenant === 'Rj') {
      return {
        header: {
          size: 52,
          height: 32,
          icon: 'rj-app-logo-header' as AppIconType,
        },
        footer: {
          size: XS ? 52 : 192,
          height: XS ? 32 : 42,
          icon: (XS
            ? 'rj-app-logo-header'
            : 'rj-app-logo-footer') as AppIconType,
        },
        error: {
          size: 192,
          height: 42,
          icon: 'rj-app-logo-header' as AppIconType,
        },
      }[props?.logoType || 'footer'];
    }

    if (tenant === 'Apex') {
      return {
        header: {
          size: 40,
          height: 34,
          fill: 'fill-current-color-primary',
          icon: 'apex-app-logo-header' as AppIconType,
        },
        footer: {
          size: XS ? 65 : 83,
          height: XS ? 55 : 78,
          fill: 'fill-current-color-primary',
          icon: 'apex-app-logo-header' as AppIconType,
        },
        error: {
          size: 83,
          height: 78,
          icon: 'apex-app-logo-header' as AppIconType,
        },
      }[props?.logoType || 'footer'];
    }

    return {
      header: {
        size: XS ? 89 : 178,
        height: 34,
        icon: (props.variant === 'light'
          ? 'logo-xbtohub-white'
          : 'logo-xbtohub-black') as AppIconType,
      },
      footer: {
        size: XS ? 89 : 178,
        height: XS ? 56 : 34,
        icon: (XS
          ? 'logo-xbtohub-powered-by-stablehouse-stacked'
          : 'logo-xbto-powered-by-stablehouse') as AppIconType,
      },
      error: {
        size: 156,
        height: 37,
        icon: 'logo-xbtohub-black' as AppIconType,
      },
    }[props?.logoType || 'footer'];
  }, [XS, props?.logoType, tenant]);

  return <AppLogoBrandDefault {...props} {...logoSizes} />;
};
