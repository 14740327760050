import React, { FC, ReactElement } from 'react';
import cls from 'classnames';
import { FeeInfoTooltip } from '~/components/workflows/shared/fee-info-tooltip';
import { Note } from 'common';

interface Props {
  feeLabel: string;
  feeFirst?: boolean;
  extraLine?: ReactElement;
}

export const FeeNote: FC<Props> = ({
  feeLabel,
  feeFirst = true,
  extraLine,
}) => {
  return (
    <Note
      cls="flex flex-row items-center text-sm justify-between"
      textSize="sm"
    >
      <div
        className={cls(
          'flex text-grey-darker',
          feeFirst ? 'flex-col' : 'flex-col-reverse'
        )}
      >
        <div>
          Fee: <b className="text-primary">{feeLabel}</b>
        </div>
        <div>{extraLine}</div>
      </div>
      <FeeInfoTooltip />
    </Note>
  );
};
