/**
 * Note:
 *
 * BE has a ticket to respond back with `Asset` object instead of currency in the `list-tradable-assets` endpoint
 * Until then, this convineince factory needs to exist
 */

import { API } from '@xbto/api-client';
import { EnrichedAccountDetailAsset } from '../../types';
import { getFxRate } from '../get-fx-rate';
import { BigOrZero } from '../big';

export const mapTradableCurrenciesToEnrichedAccountDetailAssets = (
  ccys: API.Currency[],
  fxRates: API.FxRate[],
  holdings: EnrichedAccountDetailAsset[]
): EnrichedAccountDetailAsset[] => {
  const result = ccys.reduce((out, ccy) => {
    if (!ccy.code || !ccy.name) {
      return out;
    }

    const asset = holdings?.find(
      h => h.currency.code.toLowerCase() === ccy.code?.toLowerCase()
    );

    const item: EnrichedAccountDetailAsset = {
      // can bools
      fundDetails: null,
      isAccountOfTypeProTrading:
        asset?.account?.accountType === API.AccountType.Trading ||
        asset?.account?.accountType === API.AccountType.ProTrading,
      isAccountOfTypeVault:
        asset?.account?.accountType === API.AccountType.Custody ||
        asset?.account?.accountType === API.AccountType.Vault,
      isAccountOfTypeFund: asset?.account?.accountType === API.AccountType.Fund,
      canTrade: asset?.canTrade || true, // Note: we assume we can trade for sure given this came from tradeable list endpoint

      canSendAccountTransfer: asset?.canSendAccountTransfer || false,
      canReceiveAccountTransfer: asset?.canReceiveAccountTransfer || false,
      canStabletagTransfer: asset?.canStabletagTransfer || false,
      canReceiveCrypto: asset?.canReceiveCrypto || false,
      canSendCrypto: asset?.canSendCrypto || false,
      canReceiveCash: asset?.canReceiveCash || false,
      canSendCash: asset?.canSendCash || false,

      quantity: BigOrZero(asset?.quantity),
      quantityIncludingPending: asset?.quantityIncludingPending || '',
      quantityIncludingPendingDecimal:
        asset?.quantityIncludingPendingDecimal || 0,
      quantityIncludingPendingUsdDecimal:
        asset?.quantityIncludingPendingUsdDecimal || 0,
      quantityIncludingPendingUsd: asset?.quantityIncludingPendingUsd || '',
      pendingSettlementQuantity: asset?.pendingSettlementQuantity || '',
      pendingSettlementQuantityUsd: asset?.pendingSettlementQuantityUsd || '',
      tradableQuantity: asset?.tradableQuantity || '',
      tradableQuantityDecimal: asset?.tradableQuantityDecimal || 0,
      tradableQuantityUsd: asset?.tradableQuantityUsd || '',
      transferableQuantity: asset?.transferableQuantity || '',
      transferableQuantityUsd: asset?.transferableQuantityUsd || '',
      withdrawableQuantity: asset?.withdrawableQuantity || '',
      withdrawableQuantityDecimal: asset?.withdrawableQuantityDecimal || 0,
      withdrawableQuantityUsd: asset?.withdrawableQuantityUsd || '',
      openOrdersQuantity: asset?.openOrdersQuantity || '',
      openOrdersQuantityUsd: asset?.openOrdersQuantityUsd || '',
      pendingIncomingQuantity: asset?.pendingIncomingQuantity || '',
      pendingReceiveQuantity: asset?.pendingReceiveQuantity || '',
      pendingTransferInQuantity: asset?.pendingTransferInQuantity || '',
      pendingOutgoingQuantity: asset?.pendingOutgoingQuantity || '',
      pendingOutgoingQuantityUsd: asset?.pendingOutgoingQuantityUsd || '',
      pendingSendQuantity: asset?.pendingSendQuantity || '',
      pendingSendQuantityUsd: asset?.pendingSendQuantityUsd || '',
      pendingTransferOutQuantity: asset?.pendingTransferOutQuantity || '',
      pendingTransferOutQuantityUsd: asset?.pendingTransferOutQuantityUsd || '',
      pendingIncomingQuantityUsd: asset?.pendingIncomingQuantityUsd || '',
      pendingReceiveQuantityUsd: asset?.pendingReceiveQuantityUsd || '',
      pendingTransferInQuantityUsd: asset?.pendingTransferInQuantityUsd || '',
      quantityUsd: '',
      pendingQuantity: '',
      pendingQuantityUsd: '',
      earnedInterestTotal: '',
      earnedInterestThisWeek: '',
      earnedInterestTotalUsd: '',
      earnedInterestThisWeekUsd: '',
      hasPendingBalance: !!asset?.hasPendingBalance,

      currency: asset?.currency || {
        withdrawalsAllowed: false,
        depositsAllowed: false,
        tradingAllowed: true,
        apy: 0,
        isDefaultFiatConversion: false,
        allowFiatConversion: false,
        hidden: false,
        showInMarkets: false,
        showInTopGainersOrLosers: false,
        name: ccy.name,
        decimals: ccy.decimals,
        pricePrecision: ccy.pricePrecision,
        code: ccy.code,
        displayCode: ccy.displayCode ?? ccy.code,
        blockchain: ccy.blockchain,
        underlyingCurrencyCode: ccy.underlyingCurrencyCode,
        underlyingCurrencyName: ccy.underlyingCurrencyName,
        explorerUrl: ccy.explorerUrl,
        blockchainAssetId: ccy.blockchainAssetId,
        type: ccy.type,
        isStablecoin: ccy.isStablecoin,
        network: null,
        fxRate: getFxRate(ccy.code, fxRates, ccy.pricePrecision),
        isAssetOfTypeFiat: ccy.type === API.CurrencyType.Fiat,
        isAssetOfTypeFund: ccy.type === API.CurrencyType.Fund,
        isAssetOfTypeCrypto: !!(ccy.type === API.CurrencyType.Digital),
      },
      balance: BigOrZero(asset?.balance),
      balanceUsd: BigOrZero(asset?.balanceUsd),
      hasBalance: asset?.hasBalance || false, // Note: important to filter out fromAssets from this enriched data
      formatted: {
        earnedInterestThisWeekUsd:
          asset?.formatted.earnedInterestThisWeekUsd || '',
        earnedInterestTotalUsd: asset?.formatted.earnedInterestTotalUsd || '',
        balance: asset?.formatted.balance || '',
        balanceWithCurrencyCode: asset?.formatted.balanceWithCurrencyCode || '',
        balanceUsd: asset?.formatted.balanceUsd || '',
        balanceUsdWithCurrencyCode:
          asset?.formatted.balanceUsdWithCurrencyCode || '',
        pendingBalance: asset?.formatted.pendingBalance || '',
        pendingBalanceUsd: asset?.formatted.pendingBalanceUsd || '',
        balanceIncludingPending: asset?.formatted.balanceIncludingPending || '',
        balanceIncludingPendingWithCurrencyCode:
          asset?.formatted.balanceIncludingPendingWithCurrencyCode || '',
        balanceIncludingPendingUsdWithCurrencyCode:
          asset?.formatted.balanceIncludingPendingUsdWithCurrencyCode || '',
        tradableQuantity: asset?.formatted.tradableQuantity || '',
        tradableQuantityUsd: asset?.formatted.tradableQuantityUsd || '',
        transferableQuantity: asset?.transferableQuantity || '',
        transferableQuantityUsd: asset?.transferableQuantityUsd || '',
        transferableQuantityUsdWithCurrencyCode:
          asset?.formatted.transferableQuantityUsdWithCurrencyCode || '',
        withdrawableQuantity: asset?.withdrawableQuantity || '',
        withdrawableQuantityUsd: asset?.withdrawableQuantityUsd || '',
        withdrawableQuantityUsdWithCurrencyCode:
          asset?.formatted.withdrawableQuantityUsdWithCurrencyCode || '',
        value: asset?.formatted.value || '',
        rateOfReturnPercentMTD: asset?.formatted.rateOfReturnPercentMTD || 0,
        rateOfReturnPercentYTD: asset?.formatted.rateOfReturnPercentYTD || 0,
        totalReturnPercent: asset?.formatted.totalReturnPercent || 0,
        totalReturn: asset?.formatted.totalReturn || '',
        mtdReturn: asset?.formatted.mtdReturn || '',
        ytdReturn: asset?.formatted.ytdReturn || '',
      },
      account: null,
      ytdPercent: asset?.ytdPercent ?? null,
      qtdPercent: asset?.qtdPercent ?? null,
      mtdPercent: asset?.mtdPercent ?? null,
      ptdPercent: asset?.ptdPercent ?? null,
      ptdNetReturns: asset?.ptdNetReturns ?? null,
      mtdNetReturns: asset?.mtdNetReturns ?? null,
      qtdNetReturns: asset?.qtdNetReturns ?? null,
      ytdNetReturns: asset?.ytdNetReturns ?? null,
      allReturns: asset?.allReturns ?? null,
      allReturnsPercent: asset?.allReturnsPercent ?? null,
    };
    out.push(item);
    return out;
  }, [] as EnrichedAccountDetailAsset[]);

  return result;
};
