import { DependencyList, useEffect } from 'react';

// A custom React hook that executes a callback during the next animation frame,
// ensuring the UI is fully rendered and ready before performing layout-dependent or animation-triggering logic.
export function useAnimationEffect(
  callback: () => void,
  dependencies: DependencyList
) {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const frameId = requestAnimationFrame(callback);

    return () => {
      // eslint-disable-next-line no-undef
      cancelAnimationFrame(frameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export default useAnimationEffect;
