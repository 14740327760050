var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@xbto/design-system';
import { Col, Row, TextBody, TextSubheading } from '../../atoms';
import { sizes } from '../../theme';
import { Icon } from '../icon';
export const Alert = memo((_a) => {
    var { icon, message, title, variant } = _a, rest = __rest(_a, ["icon", "message", "title", "variant"]);
    const textColor = variant;
    return (_jsxs(Col, Object.assign({}, rest, { style: [$.container, $[variant]] }, { children: [_jsxs(Row.L, { children: [!!icon && (_jsx(Col, Object.assign({ mr: "xxs" }, { children: _jsx(Icon, { name: icon, color: textColor, size: 20 }) }))), _jsx(TextSubheading, Object.assign({ color: textColor }, { children: title }))] }), !!message && _jsx(TextBody, Object.assign({ mt: "s" }, { children: message }))] })));
});
const $ = StyleSheet.create({
    container: {
        paddingHorizontal: sizes.m,
        paddingVertical: sizes.s,
        borderRadius: Number(theme.radius.sm),
    },
    error: {
        backgroundColor: theme.color.background.error,
    },
    info: {
        backgroundColor: theme.color.background.info,
    },
    success: {
        backgroundColor: theme.color.background.success,
    },
    warning: {
        backgroundColor: theme.color.background.warning,
    },
});
