import { API } from '@xbto/api-client';
import { Enriched, EnrichedCurrencyInformation } from '../../types';
import { formatters } from '../formatters';
import { StablehousePusherChannel } from '../subscribe-pusher';
import { createSendDestination } from './create-send-destination';

export const enrichTransfer = (
  transfer: API.Transfer | null,
  currencies: EnrichedCurrencyInformation[]
): Enriched.Transfer | null => {
  if (!transfer || !transfer.currencyCode) {
    return null;
  }

  const currency =
    currencies.find(({ code }) => code === transfer.currencyCode) ?? null;

  if (!currency) {
    return null;
  }

  const amountWithCurrencyCode = formatters.getCurrency(
    transfer.amount,
    currency.decimals,
    currency.displayCode
  );

  const amountWithCurrencyCodeAndSign = formatters.forceSign(
    amountWithCurrencyCode,
    '-'
  );

  const amountUsdWithCurrencyCode = formatters.getBaseCurrency(
    transfer.amountUsd
  );

  const feeWithCurrencyCode = formatters.getCurrency(
    transfer.fee,
    currency.decimals,
    currency.displayCode
  );

  const feeUsdWithCurrencyCode = formatters.getBaseCurrency(transfer.feeUsd);

  const totalAmountUsdWithCurrencyCode = formatters.getBaseCurrency(
    transfer.totalAmountUsd
  );

  return {
    ...transfer,
    channelName: StablehousePusherChannel.TRANSFER_STATUS,
    timeline: (transfer as API.TransferWithTimeline).timeline ?? null,
    formatted: {
      amountWithCurrencyCode,
      amountWithCurrencyCodeAndSign,
      amountUsdWithCurrencyCode,
      destination: createSendDestination(transfer, currency),
      feeWithCurrencyCode,
      feeUsdWithCurrencyCode,
      totalAmountUsdWithCurrencyCode,
    },
  };
};
