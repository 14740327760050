import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { TextInput, View, Pressable, StyleSheet, } from 'react-native';
import { theme } from '@xbto/design-system';
import { PlatformsFontRegular, Row } from '../../atoms';
import { Icon } from '../../molecules';
import { sizes } from '../../theme';
import { useInputFocusManager } from '../../hooks';
import { isWeb } from '../../utils';
export const SearchInput = (props) => {
    const { collapsed, maxLength, onChange, onToggleCollapse, style, value } = props;
    /**
     * Vars
     */
    const isCollapsible = !!onToggleCollapse;
    /**
     * Hooks
     */
    const { isFocused, onFocus, onBlur } = useInputFocusManager();
    /**
     * Methods
     */
    const clearInput = useCallback(() => {
        if (isCollapsible && !collapsed && value === '') {
            onToggleCollapse === null || onToggleCollapse === void 0 ? void 0 : onToggleCollapse();
        }
        onChange === null || onChange === void 0 ? void 0 : onChange('');
    }, [collapsed, isCollapsible, value, onChange, onToggleCollapse]);
    /**
     * DOM
     */
    const computedInputStyles = [$.textInput];
    if (isFocused && isWeb) {
        computedInputStyles.push({
            // @ts-expect-error this property is web specific
            outline: 'none',
        });
    }
    return isCollapsible && collapsed ? (_jsx(View, Object.assign({ style: $.collapsedContainer }, { children: _jsx(Pressable, Object.assign({ onPress: onToggleCollapse }, { children: _jsx(Icon, { name: "search-lens", size: "sm", em: true }) })) }))) : (_jsxs(Row.L, Object.assign({ px: "s", py: "xs", style: [
            $.inputContainer,
            style,
            // TODO(Hadrien): Manage disabled && inputStyles.disabled,
            // TODO(Hadrien): Manage error && inputStyles.error,
        ] }, { children: [_jsx(Icon, { name: "search-lens", size: "sm", em: true }), _jsx(TextInput, { style: computedInputStyles, autoFocus: isCollapsible, value: value, onChangeText: onChange, placeholder: "Search", keyboardType: "web-search", returnKeyType: "search", allowFontScaling: false, autoCapitalize: "none", autoCorrect: false, maxLength: maxLength, keyboardAppearance: "dark", numberOfLines: 1, placeholderTextColor: theme.color.text.secondary, selectionColor: theme.color.text.primary, underlineColorAndroid: "transparent", onBlur: onBlur, onFocus: onFocus }), (value.length > 0 || (isCollapsible && !collapsed)) && (_jsx(Pressable, Object.assign({ onPress: clearInput, style: $.clearIcon }, { children: _jsx(Icon, { name: "close-x", size: "sm", em: true }) })))] })));
};
const $ = StyleSheet.create({
    collapsedContainer: {
        borderRadius: Number(theme.radius.sm),
        backgroundColor: theme.color.background.tertiary,
        marginLeft: sizes.s,
        padding: sizes.xs,
    },
    inputContainer: {
        justifyContent: 'center',
        borderRadius: Number(theme.radius.sm),
        backgroundColor: theme.color.background.tertiary,
        borderColor: 'transparent',
        maxHeight: 32,
    },
    textInput: Object.assign(Object.assign({ marginHorizontal: sizes.xs, fontSize: 12, lineHeight: 16, height: 16 }, PlatformsFontRegular), { color: theme.color.text.primary, padding: 0, flexGrow: 1, backgroundColor: theme.color.background.tertiary, borderColor: 'transparent', borderRadius: Number(theme.radius.sm) }),
    clearIcon: {
        position: 'absolute',
        right: 12,
    },
});
