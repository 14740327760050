import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Platform, StyleSheet, useWindowDimensions } from 'react-native';
import { theme } from '@xbto/design-system';
import { Col, Row, TextBody } from '../../atoms';
import { Hypertext, Icon } from '../../molecules';
export const AccountClosedBanner = memo(({ onContactSupport }) => {
    const { width } = useWindowDimensions();
    const isPhone = width < 768;
    const Container = !isPhone ? Row : Col;
    const contactSupport = (_jsx(Col, Object.assign({ style: [$.contactSupport, !isPhone && { marginTop: 0 }] }, { children: _jsx(Hypertext, Object.assign({ color: "primary", em: true, onPress: onContactSupport }, { children: "Contact support" })) })));
    return (_jsxs(Container, Object.assign({ style: [$.container, !isPhone && $.containerRow] }, { children: [_jsxs(Row, Object.assign({ style: [$.content, !isPhone && { alignItems: 'center' }] }, { children: [_jsx(Icon, { name: "info", color: "info", size: "md" }), _jsxs(Col, Object.assign({ style: { width: '100%', flexShrink: 1 } }, { children: [_jsx(TextBody, Object.assign({ em: true, medium: true, style: { fontSize: 14 } }, { children: "Account closed" })), _jsx(TextBody, Object.assign({ style: $.description }, { children: "This account is closed, if you have any questions please contact support" })), isPhone && contactSupport] }))] })), !isPhone && contactSupport] })));
});
const $ = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: theme.color.border.info,
        backgroundColor: theme.color.background.info,
        marginHorizontal: Platform.select({ web: 0, default: 16 }),
        marginBottom: Platform.select({ web: 24, default: 16 }),
        paddingHorizontal: Platform.select({ web: 24, default: 12 }),
        paddingVertical: 16,
        borderRadius: 4,
    },
    containerRow: {
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: 4,
    },
    content: {
        alignItems: 'flex-start',
        columnGap: 12,
    },
    description: { flex: 1, flexWrap: 'wrap' },
    contactSupport: {
        alignItems: 'flex-start',
        marginTop: 12,
    },
});
