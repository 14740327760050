import { EnrichedAccountDetailAsset } from '../../types';
import { BigOrZero } from '../big';

export const validateAmountWithInBalance = (
  amount: string | null,
  asset: EnrichedAccountDetailAsset | null
) => {
  try {
    const value = BigOrZero(amount);
    const balance = BigOrZero(asset?.withdrawableQuantity);
    return value.lte(balance);
  } catch (error) {
    console.error(error);
    return false;
  }
};
