import copy from '../data/generated/copy.json';

export enum CTA_WIDGETS_KEY {
  TWO_FA = 'TWO_FA',
  BUY = 'BUY',
  GET_VERIFIED = 'GET_VERIFIED',
  GIVE_FEEDBACK = 'GIVE_FEEDBACK',
  MAKE_DEPOSIT = 'MAKE_DEPOSIT',
  REFER = 'REFER',
  RATE_APP = 'RATE_APP',
  RISK_MANAGEMENT = 'RISK_MANAGEMENT',
  LEARNING_HUB = 'LEARNING_HUB',
}

export const useCopy = () => {
  return copy;
};

export type DynamicCopyParts =
  | string
  | {
      value: string;
      type: string | 'bold' | 'normal';
    }[];
const replaceRgx = /\{(\w+)}/g;
type CopyValueType = { [key: string]: string | number };
export function useCopyInterpolater(
  copy: DynamicCopyParts,
  copyValues?: CopyValueType
) {
  if (!copyValues) return copy;
  if (Array.isArray(copy)) {
    return interpolateCopyParts(copy, copyValues);
  } else if (typeof copy === 'string') {
    return interpolateCopy(copy, copyValues);
  } else {
    return copy;
  }
}

const interpolateCopy = (copy: string, copyValues: CopyValueType) =>
  copy.replace(replaceRgx, (_, key) => `${copyValues?.[key]}` || '');

const interpolateCopyParts = (
  copy: { value: string; type: string }[],
  copyValues: CopyValueType
) =>
  copy.map(c => ({
    type: c?.type,
    value: c?.value.replace(replaceRgx, (_, key) => `${copyValues[key]}` || ''),
  }));
