import { API } from '@xbto/api-client';
import { Enriched } from '../../types';
import { enrichTransaction } from './enrich-transaction';

export const enrichActivities = (
  activities: API.ActivityTransaction[]
): Enriched.ActivityTransaction[] => {
  return activities.map(activity => enrichTransaction(activity));
};

export const enrichActivitiesPaginatedResponse = (
  res: API.ActivityTransactionArrayPaginatedApiResponse
): Enriched.ActivityTransactionArrayPaginatedApiResponse => {
  const enrichedActivities = enrichActivities(res.result ?? []);

  return {
    ...res,
    result: enrichedActivities,
  };
};
