var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { DATE_FORMATS } from 'common';
import { format as formatDateFns, addMinutes } from 'date-fns';
import { TextCaption, TextBody } from '../../atoms/text';
function formatDate(date, format, toUtc) {
    if (!date)
        return '';
    if (toUtc) {
        return formatDateFns(addMinutes(date, date.getTimezoneOffset()), format);
    }
    return formatDateFns(date, format);
}
export const _DateFormat = memo((_a) => {
    var { format, small = false, toUtc, value } = _a, rest = __rest(_a, ["format", "small", "toUtc", "value"]);
    // Vars
    const str = useMemo(() => {
        if (value === null || value === undefined) {
            return '-';
        }
        const dateValue = value instanceof Date ? value : new Date(value);
        try {
            return formatDate(dateValue, format, toUtc);
        }
        catch (err) {
            // Should not happen !!
            // Error is thrown when date-fns `format` is unrecognized (fix it)
            console.error('Date format error', err);
            return formatDate(dateValue, DATE_FORMATS.DEFAULT);
        }
    }, [format, value, toUtc]);
    const TextEl = small ? TextCaption : TextBody;
    // Render
    return _jsx(TextEl, Object.assign({}, rest, { children: str }));
});
