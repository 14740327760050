import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { API } from '@xbto/api-client';
import { Fragment, useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { SortableHeaderTitle } from '../sortable-header-title';
import { SortDirectionIcon } from '../sortable-direction-icon';
import { Row } from '../../atoms/row';
import { TextCaption } from '../../atoms/text';
import { Col } from '../../atoms/col';
export function SortableHeaders({ items, onSort, rtl = false, sortId, sortDir, }) {
    // Vars
    const isActive = useMemo(() => {
        if (sortId) {
            return items.some(item => item.id === sortId);
        }
        return false;
    }, [items, sortId]);
    // Handlers
    const handlePressId = useCallback((newSortId) => {
        var _a;
        if (newSortId === sortId) {
            // If already active, opposite sort direction
            onSort === null || onSort === void 0 ? void 0 : onSort(newSortId, sortDir === API.SortDirection.Ascending
                ? API.SortDirection.Descending
                : API.SortDirection.Ascending);
        }
        else {
            const item = items.find(_item => _item.id === newSortId);
            if (item) {
                onSort === null || onSort === void 0 ? void 0 : onSort(newSortId, (_a = item.defaultSortDir) !== null && _a !== void 0 ? _a : API.SortDirection.Descending);
            }
        }
    }, [items, onSort, sortDir, sortId]);
    const handleChangeDir = useCallback((newDir) => {
        var _a;
        if (isActive) {
            if (sortId) {
                onSort === null || onSort === void 0 ? void 0 : onSort(sortId, newDir);
            }
        }
        else {
            // If not active yet, grab the first sortable item by default
            const item = items[0];
            if (item === null || item === void 0 ? void 0 : item.id) {
                onSort === null || onSort === void 0 ? void 0 : onSort(item.id, (_a = item.defaultSortDir) !== null && _a !== void 0 ? _a : API.SortDirection.Descending);
            }
        }
    }, [isActive, items, onSort, sortId]);
    // Render
    return (_jsxs(Row.L, Object.assign({ style: [$.container, rtl && $.rtl] }, { children: [_jsx(Row.L, Object.assign({ style: $.space }, { children: items.map((item, index) => (_jsxs(Fragment, { children: [_jsx(SortableHeaderTitle, { id: item.id, label: item.label, active: !!item.id && sortId === item.id, onPress: onSort ? handlePressId : undefined }, item.label), index < items.length - 1 && (_jsxs(TextCaption, Object.assign({ em: true, color: "tertiary" }, { children: [' ', "/", ' '] })))] }, item.label))) })), !!sortDir && (_jsx(Col, Object.assign({ style: $.space }, { children: _jsx(SortDirectionIcon, { active: isActive, onChange: handleChangeDir, dir: isActive ? sortDir : API.SortDirection.Ascending }) })))] })));
}
const HORIZONTAL_SPACE = 2;
const $ = StyleSheet.create({
    container: {
        marginHorizontal: -HORIZONTAL_SPACE,
    },
    // Left-To-Right (default): Title(s) + Arrow (row)
    // Right-To-Left: Arrow + Title(s) (row-reverse)
    rtl: {
        flexDirection: 'row-reverse',
    },
    space: {
        marginHorizontal: HORIZONTAL_SPACE,
    },
});
