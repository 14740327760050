import { API } from '@xbto/api-client';
import BigNumber from 'big.js';
import { FIAT_SYMBOLS } from '../../../constants';
import { EnrichedCurrencyInformation } from '../../../types';
import { WithdrawCashFormikProps } from '../withdraw-cash/types';
import { BigOrZero } from '../../big';
import { formatters } from '../../formatters';

export const _getFiatWithdrawalAmountSent = (
  formValues: WithdrawCashFormikProps | null,
  simulation: API.FiatWithdrawalSimulation | null
) => {
  return simulation ? simulation.amount : formValues?.amount;
};

export const getFiatWithdrawalAmountSent = (
  ccyInfo: EnrichedCurrencyInformation | undefined,
  formValues: WithdrawCashFormikProps | null,
  simulation: API.FiatWithdrawalSimulation | null,
  showSymbol = true
) => {
  const amount = _getFiatWithdrawalAmountSent(formValues, simulation);
  const symbol = showSymbol ? FIAT_SYMBOLS[ccyInfo?.code || 'EUR'] : '';

  return `${symbol}${formatters.getAmount(amount, ccyInfo?.decimals, true)}`;
};

export const getFiatWithdrawalAmountReceive = (
  ccyInfo: EnrichedCurrencyInformation | undefined,
  formValues: WithdrawCashFormikProps | null,
  simulation: API.FiatWithdrawalSimulation | null,
  showSymbol = true
) => {
  const amount = simulation
    ? BigOrZero(simulation.amount).minus(BigOrZero(simulation.fee))
    : BigOrZero(formValues?.amount);
  const symbol = showSymbol ? FIAT_SYMBOLS[ccyInfo?.code || 'EUR'] : '';

  return `${symbol}${formatters.getAmount(amount, ccyInfo?.decimals, true)}`;
};

export const getFiatWithdrawalAmountWithdrawn = (
  ccyInfo: EnrichedCurrencyInformation | undefined,
  formValues: WithdrawCashFormikProps | null,
  simulation: API.FiatWithdrawalSimulation | null,
  showSymbol = true
) => {
  const amount = simulation
    ? BigNumber(simulation.amount ?? '0').add(simulation.fee ?? '0')
    : BigNumber(formValues?.amount ?? '0');
  const symbol = showSymbol ? FIAT_SYMBOLS[ccyInfo?.code || 'EUR'] : '';

  return `${symbol}${formatters.getAmount(amount, ccyInfo?.decimals, true)}`;
};

export const getFiatWithdrawalFees = (
  ccyCode: string | null | undefined,
  simulation: API.FiatWithdrawalSimulation | null,
  showSymbol = true
) => {
  if (simulation) {
    const symbol = ccyCode && showSymbol ? FIAT_SYMBOLS[ccyCode] : '';
    return `${symbol}${simulation.fee}`;
  }
  return `-`;
};
