import { FC } from 'react';
import { SettingsApiKeys as SettingsApiKeysInner } from '../../components/settings/api-keys';
import { SettingsLayout, SettingsMenuIds } from './layout';

export const ApiKeys: FC = () => {
  /**
   * DOM
   */
  return (
    <SettingsLayout
      menuId={SettingsMenuIds.APIKEYS}
      containerWidth="max-w-4xl lg:max-w-4xl 2xl:max-w-6xl"
    >
      <SettingsApiKeysInner />
    </SettingsLayout>
  );
};
