import BigNumber from 'big.js';
import { API } from '@xbto/api-client';
import { formatters } from './formatters';
import { EnrichedCurrencyInformation } from '../types';
import { BigOrZero } from './big';

export function getRateLabel(
  baseDisplayCode: string,
  rateValue: BigNumber,
  quoteCurrency: EnrichedCurrencyInformation
) {
  const formattedRateValue = formatters.getCurrency(
    rateValue,
    undefined,
    quoteCurrency.displayCode,
    true
  );
  return `1 ${baseDisplayCode} = ${formattedRateValue}`;
}

export function getRateValue(
  quoteCode: string,
  rateSymbol: string,
  rate: string,
  rateContra: string
) {
  // Ex:
  // - symbol = 'BTC/USD'
  // - rate = 20,000
  // - rateContra = 0.00005

  // If `quoteCode = 'USD'` (very usual: "1 BTC  = $24,034")
  //  - We take `rate` if `symbol = 'BTC/USD'`
  //  - We take `rateContra` if `symbol = 'USD/BTC'`

  const [, symbolTo] = rateSymbol.split('/');

  return BigOrZero(quoteCode === symbolTo ? rate : rateContra);
}

export function getTradeRateLabel(
  fxDetails: API.FxTradeDetails,
  currencies: EnrichedCurrencyInformation[]
) {
  const baseCurrency = currencies.find(c => c.code === fxDetails.baseCurrency);
  const quoteCurrency = currencies.find(
    c => c.code === fxDetails.quoteCurrency
  );

  if (!baseCurrency || !quoteCurrency) {
    return '';
  }

  return getRateLabel(
    baseCurrency.displayCode,
    BigOrZero(fxDetails.rate),
    quoteCurrency
  );
}

export function getSimulationRateLabel(
  simulation: API.TradeSimulation,
  quoteCode: string,
  currencies: EnrichedCurrencyInformation[]
) {
  const baseCode =
    quoteCode === simulation.currencyFrom
      ? simulation.currencyTo
      : simulation.currencyFrom;

  const baseCurrency = currencies.find(c => c.code === baseCode);
  const quoteCurrency = currencies.find(c => c.code === quoteCode);

  if (
    !baseCurrency ||
    !quoteCurrency ||
    !simulation.fxSymbol ||
    !simulation.fxRate ||
    !simulation.fxRateContra
  ) {
    return '';
  }

  const rateValue = getRateValue(
    quoteCurrency.code,
    simulation.fxSymbol,
    simulation.fxRate,
    simulation.fxRateContra
  );

  return getRateLabel(baseCurrency.displayCode, rateValue, quoteCurrency);
}
