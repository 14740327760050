import React, { FC } from 'react';
import cx from 'classnames';
import { BaseComponentProps } from '../../types';

export interface Props extends BaseComponentProps {
  cls?: string;
  bgColor?: string;
  textColor?: string;
  textSize?: 'xs' | 'sm' | 'base';
  margin?: string;
  border?: string;
}

// TODO(Hadrien): Convert to UC, then use for Error, Warning, Success notes : SH-12290
export const Note: FC<Props> = ({
  children,
  textSize = 'sm',
  cls = 'rounded-4',
  bgColor = 'bg-grey-brighter',
  textColor = 'text-black',
  margin = 'my-1',
  border = 'rounded-4',
}) => {
  /**
   * DOM
   */
  return (
    <div
      className={cx(
        `px-2 py-2 text-${textSize} border`,
        cls,
        textColor,
        bgColor,
        margin,
        border
      )}
    >
      {children}
    </div>
  );
};
