import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Button, Col } from '../../atoms';
import { SearchSuggestInput } from '../../organisms';
import { _KytWrapper } from './_kyt-wrapper';
import { KytLearnMore } from '../../organisms/kyt-learn-more';
import { sizes } from '../../theme';
export const KytPlatform = memo(({ value, onChange, onSelect, platforms, onNext }) => {
    /**
     * DOM
     */
    const isNextDisabled = value.length === 0;
    return (_jsxs(_KytWrapper, Object.assign({ title: "Enter the name of the platform", subtitle: "To adhere to rules within your jurisdiction we need to know who is the platform eg Kraken, Coinbase, Binance, etc", childrenContainerStyle: $.wrapper }, { children: [_jsxs(Col.LRC, Object.assign({ mx: "xl", style: $.form }, { children: [_jsx(SearchSuggestInput, { onChange: onChange, onSelect: onSelect, initialValue: value, suggestions: platforms }), _jsx(Button, { label: "Next", onPress: onNext, variant: "secondary", disabled: isNextDisabled })] })), _jsx(KytLearnMore, {})] })));
});
const $ = StyleSheet.create({
    wrapper: {
        position: 'relative',
        zIndex: 1,
    },
    form: {
        width: 328,
        rowGap: 8,
        position: 'relative',
        zIndex: 1,
        marginBottom: sizes.xxl,
    },
    textCenter: { textAlign: 'center' },
});
