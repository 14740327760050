var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { _MarketListItem } from './_market-list-item';
import { MarketListItemAddonPrice } from './market-list-item-addon-price';
import { AmountAvg } from '../../organisms/amount-avg';
export const MarketListItemCrypto = memo((_a) => {
    var { data, displayCode } = _a, rest = __rest(_a, ["data", "displayCode"]);
    if (!data)
        return null;
    return (_jsx(_MarketListItem, Object.assign({}, rest, { displayCode: displayCode, subtitle: _jsx(AmountAvg, { value: data.marketCap }), addon: _jsx(MarketListItemAddonPrice, { data: data }) })));
});
