import { BlockchainNetworkName } from '../../../types';

export enum SendDestinationType {
  crypto = `Send to a new external address`,
  book = `Send to saved address`,
  transfer = `Transfer to a Stablehouse user`, // Note: https://stablehouse.atlassian.net/browse/SH-5156
}

export type SendForm = {
  currencyCode: string | null;
  destinationType: SendDestinationType;
  destination: string | null;
  withdrawalAddressId: string | null;
  amount: string | null;
  description: string | null;
  network: BlockchainNetworkName | null;
  platform?: string | null;
};
