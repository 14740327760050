import { jsx as _jsx } from "react/jsx-runtime";
import { theme } from '@xbto/design-system';
import { SvgIcons } from '../../icons';
const sizes = {
    xs: 12,
    sm: 16,
    md: 24,
    lg: 48,
};
export function Icon({ color, em = false, name, size: sizeName = 'md', }) {
    const colorName = color !== null && color !== void 0 ? color : (em ? 'primary' : 'secondary');
    const size = typeof sizeName === 'number' ? sizeName : sizes[sizeName];
    const SvgIcon = SvgIcons[name];
    return (_jsx(SvgIcon, { width: size, height: size, fill: theme.color.text[colorName] }));
}
