import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StyleSheet } from 'react-native';
import { API } from '@xbto/api-client';
import { Col, RadioBox } from '../../atoms';
import { _KytWrapper } from './_kyt-wrapper';
import { sizes } from '../../theme';
import { KytLearnMore } from '../../organisms/kyt-learn-more';
export function KytPlatformCategory({ onNext }) {
    /**
     * DOM
     */
    return (_jsxs(_KytWrapper, Object.assign({ title: "Where is this crypto going?" }, { children: [_jsxs(Col, Object.assign({ style: $.content }, { children: [_jsx(RadioBox, { title: "Centralised platform", label: "An exchange or hosted wallet that holds the keys on your behalf (e.g. Coinbase, Binance, etc.)", onPress: onNext, value: API.TravelRuleWalletType.Centralized }), _jsx(RadioBox, { title: "Decentralised platform", label: "A self-hosted wallet or address where you hold the private keys.", onPress: onNext, value: API.TravelRuleWalletType.Decentralized })] })), _jsx(KytLearnMore, {})] })));
}
const $ = StyleSheet.create({
    content: {
        marginBottom: sizes.xxl,
        rowGap: sizes.xs,
    },
});
