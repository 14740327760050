import { useEffectOnce } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { AppLoader } from '~/components/app-loader';
import { APP_ROUTES } from '~/routes';
import { DataStore } from '~/store';
import { Auth0 } from '~/utils/auth0';
import { ApiError } from 'common';

export const OauthCallback = () => {
  /**
   * Store
   */
  const { getIdentity, setAccessToken } = DataStore.useStoreActions(a => ({
    getIdentity: a.user.getIdentity,
    setAccessToken: a.user.setAccessToken,
  }));

  /**
   * Hooks
   */
  const navigate = useNavigate();
  useEffectOnce(() => {
    (async () => {
      try {
        const client = await Auth0.getClient();
        await client.handleRedirectCallback();
        const isAuthenticated = await client.isAuthenticated();

        if (!isAuthenticated) {
          console.log('Redirect to callback but client was not authenticated');
          navigate(APP_ROUTES.NON_AUTH_LOGGED_OUT);
          return;
        }

        console.log('Redirect callback succeeded');

        // Get local access token
        const localAccessToken = await client.getTokenSilently();

        // Hydrate store
        setAccessToken(localAccessToken);

        // Try to get & store identity (will fail if access token is expired)
        await getIdentity();

        console.log('Redirecting to dashboard');
        navigate(APP_ROUTES.AUTH_PORTFOLIO);
      } catch (e: any) {
        console.error('Oauth', e);
        if (e instanceof ApiError && e?.status === 401) {
          // This occurs if you try to login with a user from another Env
          navigate(APP_ROUTES.NON_AUTH_ACCOUNT_UNKNOWN);
        } else {
          navigate(APP_ROUTES.NON_AUTH_LOGGED_OUT);
        }
      }
    })();
  });

  /**
   * DOM
   */
  return <AppLoader spinnerTop="104px" />;
};
