import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@xbto/design-system';
import { Col } from '../../atoms';
import { Icon } from '../icon';
const BADGE_SIZE = 48;
const BADGE_SIZE_SMALL = 40;
const BADGE_SIZE_LARGE = 80;
const ICON_SIZE = 30;
const ICON_SIZE_SMALL = 24;
const ICON_SIZE_LARGE = 50;
export const BadgeIcon = memo(({ large = false, name, small = false, variant = 'default' }) => {
    const isWarning = variant === 'warning';
    const iconColor = isWarning ? 'warning' : 'primary';
    const iconSize = large
        ? ICON_SIZE_LARGE
        : small
            ? ICON_SIZE_SMALL
            : ICON_SIZE;
    return (_jsx(Col.C, Object.assign({ style: [$.container, $[variant], large && $.large, small && $.small] }, { children: _jsx(Icon, { name: name, size: iconSize, color: iconColor }) })));
});
function getSizeStyle(size) {
    return {
        height: size,
        width: size,
        borderRadius: size / 2,
    };
}
const $ = StyleSheet.create({
    container: Object.assign({}, getSizeStyle(BADGE_SIZE)),
    default: {
        backgroundColor: '#defff0', // TODO(Benoit): Map to correct DT when available
    },
    warning: {
        backgroundColor: theme.color.background.warning,
    },
    small: Object.assign({}, getSizeStyle(BADGE_SIZE_SMALL)),
    large: Object.assign({}, getSizeStyle(BADGE_SIZE_LARGE)),
});
