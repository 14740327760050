import { API } from '@xbto/api-client';
import {
  EnrichedAssetHolding,
  EnrichedAssetHoldings,
  EnrichedCurrencyInformation,
  MarketAssetHoldings,
} from '../../types/enriched';
import { formatters } from '../formatters';
import { BigOrZero } from '../big';

const listOfAccountTypes = Object.values(API.AccountType);
const listOfAccountTypesExcludingFund: API.AccountType[] =
  listOfAccountTypes.filter(t => t !== API.AccountType.Fund);
const mapOfAllowedAccountsForAssetType = {
  [API.CurrencyType.Digital]: listOfAccountTypesExcludingFund,
  [API.CurrencyType.Fiat]: listOfAccountTypesExcludingFund,
  [API.CurrencyType.Fund]: [API.AccountType.Fund],
};

export const enrichAssetHoldings = (
  assetHoldings: MarketAssetHoldings | null,
  currencies: EnrichedCurrencyInformation[]
) => {
  if (!assetHoldings || !currencies) {
    return null;
  }

  const enrichedCcy = currencies.find(
    c => c.code === assetHoldings.currency?.code
  );
  if (!enrichedCcy) {
    return null;
  }
  const currency: EnrichedCurrencyInformation = {
    ...enrichedCcy,
    marketRate: {
      rate: assetHoldings.marketRate.price,
      rate24HChange: assetHoldings.marketRate.change24hPercent,
      formatted: {
        rate: formatters.getAmount(assetHoldings.marketRate.price, undefined),
        rateWithCurrencyCode: formatters.getCurrency(
          assetHoldings.marketRate.price,
          undefined,
          'USD'
        ),
        rate24HPercentageChange: formatters.getAmount(
          `${assetHoldings.marketRate.change24hPercent}`,
          undefined
        ),
      },
    },
  };

  const enrichedAccounts = (assetHoldings.accounts || [])
    ?.map(acc => {
      const balance = BigOrZero(acc.quantity);
      const balanceUsd = BigOrZero(acc.quantityUsd);
      const value = enrichedCcy.fxRate.rate.mul(balance);
      const isAccountOfTypeFund =
        acc.account?.accountType === API.AccountType.Fund;
      const fundDetails = acc.fundDetails;

      const result: EnrichedAssetHolding = {
        ...acc,
        isAccountOfTypeProTrading:
          acc.account?.accountType === API.AccountType.Trading ||
          acc.account?.accountType === API.AccountType.ProTrading,
        isAccountOfTypeVault:
          acc.account?.accountType === API.AccountType.Custody ||
          acc.account?.accountType === API.AccountType.Vault,
        isAccountOfTypeFund,
        currency,
        balance,
        balanceUsd,
        quantity: balance,
        hasBalance: balance.gt(0),
        canStabletagTransfer: false, // Note: https://stablehouse.atlassian.net/browse/SH-5156
        formatted: {
          earnedInterestThisWeekUsd: formatters.getBaseCurrency(
            acc.earnedInterestThisWeekUsd
          ),
          earnedInterestTotalUsd: formatters.getBaseCurrency(
            acc.earnedInterestTotalUsd
          ),
          balance: formatters.getAmount(acc.quantity, enrichedCcy?.decimals),
          balanceWithCurrencyCode: formatters.getCurrency(
            acc.quantity,
            enrichedCcy?.decimals,
            enrichedCcy.displayCode ?? enrichedCcy.code
          ),
          balanceUsd: formatters.getAmount(acc.quantityUsd, 2),
          balanceUsdWithCurrencyCode: formatters.getBaseCurrency(
            acc.quantityUsd
          ),
          pendingBalance: formatters.getAmount(
            acc.pendingQuantity,
            enrichedCcy?.decimals
          ),
          pendingBalanceUsd: formatters.getAmount(
            acc.pendingQuantityUsd,
            enrichedCcy?.decimals
          ),
          balanceIncludingPending: formatters.getAmount(
            acc.quantityIncludingPending,
            undefined // use fallback mantissa calc
          ),
          balanceIncludingPendingWithCurrencyCode: isAccountOfTypeFund
            ? formatters.getCurrency(
                fundDetails?.valueIncludingPendingInSecondaryAsset,
                undefined, // use fallback mantissa calc
                (fundDetails?.secondaryAsset?.displayCode ??
                  fundDetails?.secondaryAsset?.code) ||
                  ''
              )
            : formatters.getCurrency(
                acc.quantityIncludingPending,
                undefined, // use fallback mantissa calc
                enrichedCcy.displayCode ?? enrichedCcy.code
              ),
          balanceIncludingPendingUsdWithCurrencyCode:
            formatters.getBaseCurrency(acc.quantityIncludingPendingUsd),
          tradableQuantity: acc.tradableQuantity || '0',
          tradableQuantityUsd: formatters.getAmount(acc.tradableQuantityUsd, 2),
          transferableQuantity: acc.transferableQuantity || '0',
          transferableQuantityUsd: formatters.getAmount(
            acc.transferableQuantityUsd,
            enrichedCcy.decimals
          ),
          transferableQuantityUsdWithCurrencyCode: formatters.getBaseCurrency(
            acc.transferableQuantityUsd
          ),
          withdrawableQuantity: acc.withdrawableQuantity || '0',
          //   formatters.getAmount(
          //   acc.withdrawableQuantity,
          //   DEFAULTS.MAX_DECIMALS,
          //   true,
          //   true
          // ),
          withdrawableQuantityUsd: formatters.getAmount(
            acc.withdrawableQuantityUsd,
            enrichedCcy.decimals
          ),
          withdrawableQuantityUsdWithCurrencyCode: formatters.getBaseCurrency(
            acc.withdrawableQuantityUsd
          ),
          value: formatters.getBaseCurrency(value),
          rateOfReturnPercentMTD: 0,
          rateOfReturnPercentYTD: 0,
          totalReturnPercent: 0,
          totalReturn: '0',
          mtdReturn: '0',
          ytdReturn: '0',
        },
      };
      return result;
    })
    .filter(acc => {
      const allowedList = mapOfAllowedAccountsForAssetType[enrichedCcy.type];
      return (
        acc.account?.accountType &&
        allowedList.includes(acc.account?.accountType)
      );
    });
  const _qty = BigOrZero(assetHoldings.totalQty);
  const _value = enrichedCcy.fxRate.rate.mul(_qty);

  const enriched: EnrichedAssetHoldings = {
    hasBalance: _qty.gt(0),
    currency,
    accounts: enrichedAccounts,
    formatted: {
      totalQuantity: formatters.getCurrency(
        _qty.toFixed(),
        enrichedCcy.decimals,
        enrichedCcy.displayCode ?? enrichedCcy.code
      ),
      value: formatters.getBaseCurrency(_value),
    },
  };
  return enriched;
};
