import { BalanceChange } from '../../types';
import { formatters } from '../formatters';
import { DEFAULTS } from '../../constants';
import { BigOrZero } from '../big';

export function createBalanceChange(
  _usdValue: string | null,
  _percentage: string | null
): BalanceChange | null {
  if (!_usdValue || !_percentage) {
    return null;
  }
  const percentage = Number(_percentage ?? '0');
  const value = BigOrZero(_usdValue);

  return {
    percentage,
    percentageFormatted: formatters.forceSign(
      `${formatters.getAmount(percentage.toString(), DEFAULTS.DECIMAL_SCALE)}%`,
      percentage > 0 ? '+' : ''
    ),
    value,
    // TODO: tech-debt - change this impl
    valueFormatted: `${value.gt(0) ? '+' : ''}${formatters.getBaseCurrency(
      value
    )}`,
  };
}
