import BigNumber from 'big.js';
import { ERROR_MESSAGES } from '../../../constants';
import {
  EnrichedAccountDetail,
  EnrichedCurrencyInformation,
} from '../../../types';
import { validateAmountWithInBalance } from '../../validations/amount-within-balance';
import { TransferFormikProps } from './types';
import { getCurrencyInfoForCode } from '../../get-currency-info-for-code';
import { API } from '@xbto/api-client';

const validate = (
  {
    amount,
    currencyCode,
    destinationAccountId,
    description,
  }: TransferFormikProps,
  currencies: EnrichedCurrencyInformation[],
  sourceAccountDetail?: EnrichedAccountDetail,
  destinationAccountDetail?: API.AccountDetail
) => {
  const errors: Record<string, string> = {};

  if (sourceAccountDetail) {
    const selectedAsset = sourceAccountDetail.assets?.find(
      asset => asset.currency?.code === currencyCode
    );
    if (selectedAsset) {
      const isWithinBalance = validateAmountWithInBalance(
        amount,
        selectedAsset
      );
      if (!isWithinBalance) {
        errors.amount = ERROR_MESSAGES.INSUFFICIENT_BALANCE;
      }
      if (!amount) {
        errors.amount = ERROR_MESSAGES.REQUIRED_VALUE;
      } else if (BigNumber(amount).lte(0)) {
        errors.amount = ERROR_MESSAGES.AMOUNT_MORE_THAN_0;
      }
    }
  }

  if (!amount || (amount && BigNumber(amount).eq(0))) {
    errors.amount = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  if (!currencyCode) {
    errors.currencyCode = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  if (!destinationAccountId) {
    errors.destinationAccountId = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  if (description) {
    if (description.length > 255) {
      errors.description = ERROR_MESSAGES.MAX_255_CHARACTERS;
    }
  }

  if (currencyCode && destinationAccountId && destinationAccountDetail) {
    const currency = getCurrencyInfoForCode(currencyCode, currencies);
    if (currency) {
      if (
        (currency.isAssetOfTypeFiat &&
          !destinationAccountDetail?.canReceiveCash) ||
        (!currency.isAssetOfTypeFiat &&
          !destinationAccountDetail?.canReceiveCrypto)
      ) {
        errors.destinationAccountId = `You cannot transfer ${currencyCode} to this account`;
      }
    }
  }

  return errors;
};

export const validateTransferFormik = validate;
