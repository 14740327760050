var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Platform, Text as RNText, StyleSheet, View, } from 'react-native';
import { theme } from '@xbto/design-system';
import { createBox } from '../../theme';
const themeColorTextKeys = Object.keys(theme.color.text);
const TextBox = createBox(RNText);
// Do not expose TextBase
// Use it to build other Texts
function TextBase(_a) {
    var { center = false, color: colorName, em = false, style } = _a, rest = __rest(_a, ["center", "color", "em", "style"]);
    const computedColor = useMemo(() => {
        return colorName
            ? themeColorTextKeys.includes(colorName)
                ? theme.color.text[colorName]
                : colorName
            : 'secondary';
    }, [colorName]);
    return (_jsx(TextBox, Object.assign({}, rest, { style: [
            style,
            em === true && $.bold,
            em === '600' && $.medium,
            !!colorName && {
                color: computedColor,
            },
            center && $.center,
        ] })));
}
export function TextBody(_a) {
    var { style, small, medium, large } = _a, rest = __rest(_a, ["style", "small", "medium", "large"]);
    return (_jsx(TextBase, Object.assign({}, rest, { style: [
            $.body,
            small && $.bodySmall,
            medium && $.bodyMedium,
            large && $.bodyLarge,
            style,
        ] })));
}
export function TextCaption(_a) {
    var { style } = _a, rest = __rest(_a, ["style"]);
    return _jsx(TextBase, Object.assign({}, rest, { style: [$.caption, style] }));
}
export function TextTitle(_a) {
    var { style } = _a, rest = __rest(_a, ["style"]);
    return _jsx(TextBase, Object.assign({}, rest, { style: [$.title, style] }));
}
export function TextHeading(_a) {
    var { style } = _a, rest = __rest(_a, ["style"]);
    return _jsx(TextBase, Object.assign({}, rest, { style: [$.heading, style] }));
}
export function TextSubheading(_a) {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return _jsx(TextBody, Object.assign({}, rest, { em: "600", color: color }));
}
const textBodyDefaultColorName = 'secondary';
export const TextUnderline = (_a) => {
    var { color: colorName = textBodyDefaultColorName } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(View, Object.assign({ style: [$.underline, { borderBottomColor: theme.color.text[colorName] }] }, { children: _jsx(TextBody, Object.assign({ em: "600" }, rest, { color: colorName })) })));
};
const fontFamilies = {
    InterWeb: { fontFamily: 'Inter' },
    InterRegular: { fontFamily: 'Inter-Regular' },
    InterMedium: { fontFamily: 'Inter-SemiBold' },
    InterBold: { fontFamily: 'Inter-Bold' },
};
export const fontNormalStyle = Platform.select({
    android: Object.assign({}, fontFamilies.InterRegular),
    ios: Object.assign({}, fontFamilies.InterRegular),
    web: Object.assign(Object.assign({}, fontFamilies.InterWeb), { fontWeight: '400' }),
});
export const fontMediumStyle = Platform.select({
    android: Object.assign({}, fontFamilies.InterMedium),
    ios: Object.assign({}, fontFamilies.InterMedium),
    web: Object.assign(Object.assign({}, fontFamilies.InterWeb), { fontWeight: '600' }),
});
export const fontBoldStyle = Platform.select({
    android: Object.assign({}, fontFamilies.InterBold),
    ios: Object.assign({}, fontFamilies.InterBold),
    web: Object.assign(Object.assign({}, fontFamilies.InterWeb), { fontWeight: '700' }),
});
const fontStyles = {
    normal: fontNormalStyle,
    semiBold: fontMediumStyle,
    bold: fontBoldStyle,
};
const $ = StyleSheet.create({
    body: Object.assign(Object.assign({}, fontStyles.normal), { fontSize: Platform.select({ web: 14, default: 12 }), lineHeight: Platform.select({ web: 20, default: 16 }), color: theme.color.text[textBodyDefaultColorName] }),
    bodySmall: {
        fontSize: Platform.select({ web: 12, default: 10 }),
        lineHeight: Platform.select({ web: 16, default: 12 }),
    },
    bodyMedium: {
        fontSize: Platform.select({ web: 16, default: 14 }),
        lineHeight: Platform.select({ web: 24, default: 20 }),
    },
    bodyLarge: {
        fontSize: Platform.select({ web: 20, default: 16 }),
        lineHeight: Platform.select({ web: 28, default: 24 }),
    },
    caption: Object.assign(Object.assign({}, fontStyles.normal), { fontSize: 10, lineHeight: 12, color: theme.color.text.secondary }),
    heading: Object.assign(Object.assign({}, fontStyles.semiBold), { fontSize: Platform.select({ web: 20, default: 16 }), lineHeight: Platform.select({ web: 28, default: 24 }), color: theme.color.text.primary }),
    medium: Object.assign({}, fontStyles.semiBold),
    bold: Object.assign(Object.assign({}, fontStyles.bold), { color: theme.color.text.primary }),
    semiBold: Object.assign(Object.assign({}, fontStyles.semiBold), { color: theme.color.text.primary }),
    underline: {
        borderBottomWidth: 1,
        borderBottomColor: theme.color.border.tertiary,
    },
    title: Object.assign(Object.assign({}, fontStyles.semiBold), { fontSize: 20, lineHeight: 28, color: 'onSurfaceEm' }),
    center: {
        textAlign: 'center',
    },
});
