import ArrowDown from './arrow-down';
import ArrowUp from './arrow-up';
import ChevronLeft from './chevron-left';
import ChevronRight from './chevron-right';
import CloseX from './close-x';
import Info from './info';
import LockClock from './lock-clock';
import ManageSearch from './manage-search';
import Person from './person';
import SearchLens from './search-lens';
import ArrowOutward from './arrow-outward';
export const SvgIcons = {
    'arrow-down': ArrowDown,
    'arrow-up': ArrowUp,
    'arrow-outward': ArrowOutward,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'close-x': CloseX,
    info: Info,
    'lock-clock': LockClock,
    'manage-search': ManageSearch,
    person: Person,
    'search-lens': SearchLens,
};
