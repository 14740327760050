import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Image as RNImage, View, StyleSheet } from 'react-native';
import { theme } from '@xbto/design-system';
import { addDensityToSrc } from './utils';
export function Image({ height, responsive = false, rounded = false, src, width, }) {
    // State
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    // Vars
    const uri = useMemo(() => {
        return responsive ? addDensityToSrc(src) : src;
    }, [responsive, src]);
    const imageSize = useMemo(() => {
        return { height, width };
    }, [height, width]);
    // Handlers
    const handleLoad = useCallback(() => {
        setIsLoading(false);
    }, []);
    const handleError = useCallback(() => {
        setIsLoading(false);
        setHasError(true);
    }, []);
    // Render
    return (_jsxs(View, Object.assign({ style: [$.container, rounded && $.rounded, imageSize] }, { children: [(isLoading || hasError) && (_jsx(View, { style: [$.placeholder, hasError && $.error, imageSize] })), _jsx(RNImage, { source: { uri }, style: [$.image, imageSize], onError: handleError, onLoad: handleLoad })] })));
}
const fixedStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
};
const $ = StyleSheet.create({
    container: {
        position: 'relative',
        overflow: 'hidden',
    },
    loading: Object.assign(Object.assign({}, fixedStyle), { backgroundColor: theme.color.background.tertiary }),
    placeholder: Object.assign(Object.assign({}, fixedStyle), { backgroundColor: theme.color.background.tertiary }),
    error: {
        backgroundColor: theme.color.background.secondary, // Bolder grey placeholder when error
    },
    rounded: {
        borderRadius: 1000,
    },
    image: Object.assign({}, fixedStyle),
});
