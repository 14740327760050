import { KytWizard } from '@xbto/universal-components';
import { FormHeader, KytValues } from 'common';
import { StepWizardChildProps } from 'react-step-wizard';
import { SendSteps } from './steps';
import { DataStore } from '~/store';
import { FC, useCallback } from 'react';

export const Kyt: FC<Partial<StepWizardChildProps>> = ({ goToNamedStep }) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Store
   */
  const { walletOwnerName, walletPlatform } = DataStore.useStoreState(
    s => s.send.withdrawalRequirementsDataRequired
  );
  const searchExchanges = DataStore.useStoreActions(
    a => a.send.searchExchanges
  );
  const setKytValues = DataStore.useStoreActions(a => a.send.setKytValues);

  /**
   * Methods
   */
  const onCancelPreview = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      goToNamedStep(SendSteps.Form);
    },
    [goToNamedStep]
  );
  const onKytComplete = useCallback(
    (values: KytValues) => {
      setKytValues(values);
      goToNamedStep(SendSteps.Preview);
    },
    [goToNamedStep, setKytValues]
  );

  /**
   * DOM
   */
  return (
    <div data-testid="send-preview" className="flex flex-col">
      {/* header */}
      <FormHeader onBack={onCancelPreview} title='Wallet details'/>
      {/* content */}
      <KytWizard
        walletOwnerName={walletOwnerName}
        walletPlatform={walletPlatform}
        searchExchanges={searchExchanges}
        onComplete={onKytComplete}
      />
    </div>
  );
};
