import { FC } from 'react';
import { formatters } from 'common';

type Props = {
  sign?: string | null;
  amount?: string | null | number;
  code?: string | null;
  decimals?: number;
  formatted?: boolean;
  meta?: string | JSX.Element;
};

export const ActivityAmount: FC<Props> = ({
  sign,
  amount,
  decimals,
  formatted = false,
  code,
  meta,
}) => {
  const amountFormatted = formatted
    ? amount
    : code && amount
      ? formatters.getCurrency(`${amount}`, decimals, code)
      : null;

  return (
    <div className="flex flex-col items-end">
      <span>{`${sign ?? ''}${amountFormatted}`}</span>
      {!!meta && <p className="text-secondary mt-1.5">{meta}</p>}
    </div>
  );
};

type PropsFuture = {
  amount: string;
  meta?: string | JSX.Element;
};

export const ActivityAmountFuture: FC<PropsFuture> = ({ amount, meta }) => {
  return (
    <div className="flex flex-col items-end">
      <div className="flex flex-row items-center">{amount}</div>
      {!!meta && <div className="text-secondary mt-1.5">{meta}</div>}
    </div>
  );
};
