import { FC } from 'react';
import { APP_ROUTES } from '../../routes';
import { Link } from 'react-router-dom';
import { AppLogo } from '../app-logo';
import { useUrls } from 'common';
import { DataStore } from '~/store';
import { pdfDownloadMessage } from '~/utils/pdf-download-message';
import { API } from '@xbto/api-client';

export const StablehouseFooter: FC = () => {
  /**
   * Store
   */
  const getFees = DataStore.useStoreActions(a => a.settings.getFees);

  /**
   * Hooks
   */
  const { faqUrl, privacyUrl, termsUrl } = useUrls();
  const handleGetPdf = async () => {
    const { isSuccessful, result, errorMessage } = await getFees({
      type: API.OrganizationDocumentType.FeeSchedule,
    });

    pdfDownloadMessage(
      isSuccessful,
      result?.url,
      errorMessage,
      'view',
      errorMessage || 'Could not download Fees schedule'
    );
  };
  /**
   * DOM
   */
  return (
    <div
      data-testid="app-footer"
      className="relative border-t min-h-20 py-4 sm:py-6 px-8 text-grey-darker bg-grey-brightest"
    >
      <div className="flex flex-col md:flex-row md:justify-between items-center">
        <div className="flex-shrink-0">
          <Link
            to={APP_ROUTES.AUTH_PORTFOLIO}
            className="focus:ring-transparent focus:outline-none"
          >
            <AppLogo variant="dark" cls="" />
          </Link>
        </div>
        <div className="flex flex-row flex-start items-center gap-3 md:gap-8 mt-4 sm:mt-6 md:mt-0 text-lg sm:text-base">
          <button onClick={handleGetPdf} className="text-sm hover:underline">
            Fees
          </button>
          <Link
            to={faqUrl}
            rel="help"
            target="_blank"
            className="text-sm hover:underline"
          >
            Support &amp; FAQs
          </Link>
          <Link
            rel="noreferrer"
            to={APP_ROUTES.NON_AUTH_CONTACT_US}
            className="text-sm hover:underline"
          >
            Contact us
          </Link>
          <Link
            to={termsUrl}
            className="text-sm hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Terms of use
          </Link>
          <Link
            to={privacyUrl}
            className="text-sm hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Privacy policy
          </Link>
        </div>
      </div>
    </div>
  );
};
