import { jsx as _jsx } from "react/jsx-runtime";
import { StyleSheet, View } from 'react-native';
import { theme } from '@xbto/design-system';
import { TextCaption } from '../../atoms';
export function Tag({ em, label, variant = 'neutral' }) {
    return (_jsx(View, Object.assign({ style: [$container.default, $container[variant]] }, { children: _jsx(TextCaption, Object.assign({ style: [$text.default, $text[variant], em && $text.em], numberOfLines: 1 }, { children: label })) })));
}
const $container = StyleSheet.create({
    default: {
        borderRadius: 2,
        marginLeft: 4,
        height: 17,
    },
    neutral: {
        backgroundColor: theme.color.background.secondary,
    },
    info: {
        backgroundColor: theme.color.background.secondary,
    },
});
const $text = StyleSheet.create({
    default: {
        lineHeight: 10,
        padding: 4,
    },
    neutral: {
        color: theme.color.text.primary,
    },
    info: {
        color: theme.color.text.info,
    },
    em: {
        fontWeight: '500',
    },
});
