import { useCallback, useEffect, useRef, useState } from 'react';
import { DataStoreType, TradeFormValues } from '../../../store';
import { API } from '@xbto/api-client';
import { decimalNumberValidation } from '../../../utils/decimal-number-validation';
import { EnrichedCurrencyInformation } from '../../../types';
import { getBuySellSidePreference } from '../../../store/buy-sell-store/utils';
import { FILTER_ASSETS_BY } from '../../../config';
import { API_ERROR_CODES } from '../../../constants';
import { BigOrZero } from '../../../utils';

interface Options {
  DataStore: DataStoreType;
  goToAssetList?: () => void;
}

export const useWorkflowBuySellForm = ({
  DataStore,
  goToAssetList,
}: Options) => {
  /**
   * Usage check
   */
  if (!DataStore) {
    throw new Error(
      `Hook: useWorkflowTrade can only be used inside the DataStore.Provider`
    );
  }

  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.buySell.busy);
  const error = DataStore.useStoreState(s => s.buySell.error);
  const isAccountTypeProTrading = DataStore.useStoreState(
    s => s.portfolio.accountDetail?.isAccountOfTypeProTrading
  );
  const formValues = DataStore.useStoreState(s => s.buySell.formValues);
  const simulation = DataStore.useStoreState(s => s.buySell.simulation);
  const trade = DataStore.useStoreState(s => s.buySell.trade);
  const isResimulating = DataStore.useStoreState(s => s.buySell.isResimulating);

  const setError = DataStore.useStoreActions(s => s.buySell.setError);
  const setFormValues = DataStore.useStoreActions(s => s.buySell.setFormValues);
  const resetState = DataStore.useStoreActions(s => s.buySell.resetState);
  const resetForm = DataStore.useStoreActions(s => s.buySell.resetForm);
  const getAssets = DataStore.useStoreActions(a => a.buySell.getAssets);
  const createTrade = DataStore.useStoreActions(a => a.buySell.createTrade);
  const simulate = DataStore.useStoreActions(a => a.buySell.simulate);

  const setIsResimulating = DataStore.useStoreActions(
    a => a.buySell.setIsResimulating
  );
  const setAssetFilter = DataStore.useStoreActions(
    a => a.buySell.setAssetFilter
  );

  /**
   * State
   */
  const [isRateExpiredError, setRateExpiredError] = useState(false);

  /**
   * Vars
   */
  const isRateInitialized = useRef(false);

  /**
   * Methods
   */
  const _curateAmount = useCallback((amount: string | null | undefined) => {
    if (!amount) {
      return null;
    }
    return amount.replace(/[, ]+/g, '.').trim();
  }, []);
  const _getAmounts = useCallback(
    (
      amountSide: TradeFormValues['amountSide'],
      amount: string | null,
      skipCuration = false
    ) => {
      const isFromAmount = amountSide === 'fromAssetAmount';
      const _fromAmount = isFromAmount ? amount : null;
      const _toAmount = isFromAmount ? null : amount;
      return {
        fromAmount: skipCuration ? _fromAmount : _curateAmount(_fromAmount),
        toAmount: skipCuration ? _toAmount : _curateAmount(_toAmount),
      };
    },
    [_curateAmount]
  );
  const handleOnSide = useCallback(
    (newSide: API.Side) => {
      resetState();
      resetForm({ side: newSide });
    },
    [resetState, resetForm]
  );

  const handleOnFromAsset = useCallback(async () => {
    setError(null);
    setAssetFilter(FILTER_ASSETS_BY.ALL);
    setFormValues({
      ...formValues,
      fromAsset: null,
      toAsset: null,
      fromAmount: null,
      toAmount: null,
    });
    getAssets({
      side: formValues.side,
    });
    if (!goToAssetList) {
      return;
    }
    goToAssetList();
  }, [
    setError,
    setFormValues,
    getAssets,
    goToAssetList,
    formValues,
    setAssetFilter,
  ]);

  const handleOnToAsset = useCallback(() => {
    setError(null);
    setAssetFilter(FILTER_ASSETS_BY.ALL);
    setFormValues({
      ...formValues,
      toAsset: null,
    });
    getAssets({
      side: formValues.side === API.Side.Buy ? API.Side.Sell : API.Side.Buy,
      assetFrom: formValues.fromAsset?.currency.code || undefined,
    });
    if (!goToAssetList) {
      return;
    }
    goToAssetList();
  }, [
    setError,
    setFormValues,
    getAssets,
    goToAssetList,
    formValues,
    setAssetFilter,
  ]);

  const handleOnMax = useCallback(() => {
    setError(null);
    const isSell = formValues.side === API.Side.Sell;
    if (isSell) {
      if (!formValues.fromAsset) {
        return;
      }
      const { fromAmount, toAmount } = _getAmounts(
        formValues.amountSide,
        formValues.fromAsset.formatted.tradableQuantity,
        true
      );
      setFormValues({ ...formValues, fromAmount, toAmount });
    } else {
      if (!formValues.toAsset) {
        return;
      }
      const { fromAmount, toAmount } = _getAmounts(
        formValues.amountSide,
        formValues.toAsset.formatted.tradableQuantity,
        true
      );
      setFormValues({ ...formValues, fromAmount, toAmount });
    }
  }, [formValues, setFormValues, _getAmounts, setError]);

  const handleOnAmount = useCallback(
    (value: string | undefined) => {
      setError(null);
      const amount = decimalNumberValidation(value);
      const { fromAmount, toAmount } = _getAmounts(
        formValues.amountSide,
        amount
      );
      setFormValues({
        ...formValues,
        fromAmount,
        toAmount,
      });
    },
    [setFormValues, formValues, setError, _getAmounts]
  );
  const handleOnAmountSide = useCallback(
    (newAmountSide: TradeFormValues['amountSide'], ignoreDecimals = false) => {
      setError(null);

      if (simulation) {
        const fromAmount =
          newAmountSide === 'toAssetAmount'
            ? null
            : formValues.side === API.Side.Buy
              ? ignoreDecimals
                ? formValues.fromAmount
                : simulation.amountTo
              : ignoreDecimals
                ? formValues.fromAmount
                : simulation.amountFrom;
        const toAmount =
          newAmountSide === 'toAssetAmount'
            ? formValues.side === API.Side.Buy
              ? ignoreDecimals
                ? formValues.toAmount
                : simulation.amountFrom
              : ignoreDecimals
                ? formValues.toAmount
                : simulation.amountTo
            : null;
        setFormValues({
          ...formValues,
          amountSide: newAmountSide,
          fromAmount,
          toAmount,
        });
        return;
      }
      setFormValues({
        ...formValues,
        amountSide: newAmountSide,
        fromAmount: null,
        toAmount: null,
      });
    },
    [setFormValues, formValues, simulation, setError]
  );

  const handleOnResimulate = useCallback(() => {
    setIsResimulating(true);
    handleOnAmountSide(formValues.amountSide, true);
  }, [handleOnAmountSide, formValues, setIsResimulating]);

  const handleOnCreate = useCallback(async () => {
    setRateExpiredError(false);

    if (!simulation || !formValues) {
      return false;
    }
    setError(null);
    const sidePreference = getBuySellSidePreference(formValues);

    const body: API.CreateTradeRequest = {
      currencyFrom: simulation.currencyFrom as string,
      currencyTo: simulation.currencyTo as string,
      amountFrom: simulation.amountFrom,
      amountTo: simulation.amountTo,
      sidePreference,
      clientId: null,
    };

    const { isSuccessful, errorCode } = await createTrade(body);

    const _isRateExpiredError =
      !!errorCode && API_ERROR_CODES.RATE_EXPIRED.includes(errorCode);

    if (_isRateExpiredError) {
      // re-simulate
      simulate(formValues);
    }

    setRateExpiredError(_isRateExpiredError);

    return isSuccessful;
  }, [formValues, simulation, createTrade, setError]);

  /**
   * Effects
   */
  useEffect(() => {
    if (
      !isRateInitialized.current &&
      formValues.fromAsset &&
      formValues.toAsset
    ) {
      isRateInitialized.current = true;

      // Simulate with no amount to get a first indicative rate
      setFormValues({ ...formValues });
    }
  }, [formValues, setFormValues]);

  /**
   * Return
   */
  const isSell = formValues.side === API.Side.Sell;
  const isBuy = !isSell;
  const fromAssetCurrency = formValues.fromAsset?.currency || null;
  const toAssetCurrency = formValues.toAsset?.currency || null;
  const fromAssetCode = formValues.fromAsset?.currency.code || null;
  const toAssetCode = formValues.toAsset?.currency.code || null;
  const isMaxEnabled =
    (isSell && formValues.amountSide === 'fromAssetAmount') ||
    (isBuy && formValues.amountSide === 'toAssetAmount');
  const amountDecimals =
    formValues.amountSide === 'fromAssetAmount'
      ? formValues.fromAsset?.currency.decimals
      : formValues.toAsset?.currency.decimals;
  const fromAssetBalance = BigOrZero(formValues.fromAsset?.balance);
  const toAssetBalance = BigOrZero(formValues.toAsset?.balance);
  const hasBothAssetsSelected = Boolean(
    !!formValues.fromAsset && !!formValues.toAsset
  );
  const isAmountDisabled =
    !hasBothAssetsSelected ||
    (isSell && fromAssetBalance.lte(0)) ||
    (isBuy && toAssetBalance.lte(0));
  const isZeroBalance =
    (isSell && fromAssetBalance.lte(0)) || (isBuy && toAssetBalance.lte(0));
  const _amount =
    formValues.amountSide === 'fromAssetAmount'
      ? formValues.fromAmount
      : formValues.toAmount;
  const isConfirmDisabled = Boolean(
    !simulation || error || isResimulating || busy || !!trade
  );
  const title = `${formValues.side} ${fromAssetCurrency?.displayCode || ''}`;

  return {
    isBuy: !isSell,
    isDisabled: busy,
    isZeroBalance,
    isRateExpiredError,
    isSell,
    amountAsString: _amount || '',
    fromAssetCurrency,
    toAssetCurrency,
    fromAssetCode,
    toAssetCode,
    amountCurrency:
      formValues.amountSide === 'fromAssetAmount'
        ? fromAssetCurrency
        : toAssetCurrency,
    amountCurrencies: [fromAssetCurrency, toAssetCurrency].filter(
      c => !!c
    ) as EnrichedCurrencyInformation[],
    isConfirmDisabled,
    isAmountDisabled,
    isMaxEnabled,
    amountDecimals,
    isResimulating,
    showDisclaimerExecutionTime:
      !!fromAssetCode && !!toAssetCode && !!isAccountTypeProTrading,
    onSide: handleOnSide,
    onFromAsset: handleOnFromAsset,
    onToAsset: handleOnToAsset,
    onMax: handleOnMax,
    onAmount: handleOnAmount,
    onAmountSide: handleOnAmountSide,
    canResimulate: !busy && simulation && !trade && isResimulating === false,
    onResimulate: handleOnResimulate,
    onCreate: handleOnCreate,
    reset: resetState,
    title,
  };
};
