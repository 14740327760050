import { theme } from '@xbto/design-system';
import { createTheme } from 'react-native-themesheet';
// We map spacing design tokens to human friendly size names. Comes handy in Box -> Col, Row components
export const sizes = {
    xxs: theme.spacing['1'],
    xs: theme.spacing['2'],
    s: theme.spacing['3'],
    m: theme.spacing['4'],
    l: theme.spacing['6'],
    xl: theme.spacing['8'],
    xxl: theme.spacing['10'],
};
// https://github.com/eightyfive/react-native-themesheet
// @see lib/box.tsx
export const { createBox } = createTheme({}, sizes);
