import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Col, RadioButton } from '../../atoms';
import { _KytWrapper } from './_kyt-wrapper';
import { defaultWidthForInputsAndButtons } from './shared';
import { travelRuleWalletOwnerTypes, travelRuleWalletOwnerTypeMapping, } from 'common';
import { sizes } from '../../theme';
import { KytLearnMore } from '../../organisms/kyt-learn-more';
export const KytOwnerCategory = ({ onNext }) => {
    /**
     * Methods
     */
    const handleSelection = useCallback((v) => {
        onNext(v);
    }, [onNext]);
    /**
     * DOM
     */
    return (_jsxs(_KytWrapper, Object.assign({ title: "Who owns the wallet?", subtitle: "This transaction meets travel rule requirements, so we need some extra information" }, { children: [_jsx(Col, Object.assign({ style: $.container }, { children: travelRuleWalletOwnerTypes.map(([key, value]) => {
                    const title = travelRuleWalletOwnerTypeMapping[value];
                    return (_jsx(RadioButton, { title: title, value: value, style: defaultWidthForInputsAndButtons, onPress: handleSelection }, key));
                }) })), _jsx(KytLearnMore, {})] })));
};
const $ = StyleSheet.create({
    container: {
        marginBottom: sizes.xxl,
        rowGap: sizes.xs,
    },
});
