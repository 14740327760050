import BigNumber from 'big.js';
import { API } from '@xbto/api-client';
import {
  Balances,
  EnrichedAccountDetail,
  EnrichedAccountDetailAsset,
} from '../../types';
import { DEFAULTS } from '../../constants';
import { BigOrZero } from '../big';
import { formatters } from '../formatters';

type BalancesData = {
  total: BigNumber;
  pendingSettlement: BigNumber;
  pendingIncoming: BigNumber;
  pendingReceive: BigNumber;
  pendingTransferIn: BigNumber;
  pendingOutgoing: BigNumber;
  pendingSend: BigNumber;
  pendingTransferOut: BigNumber;
  openOrders: BigNumber;
  tradable: BigNumber | undefined;
  withdrawable: BigNumber;
  transferable: BigNumber;
};

type CurrencyLike = {
  displayCode: string;
  decimals: number;
};

function createBalances(
  currency: CurrencyLike,
  hasPendingBalance: boolean,
  {
    total,
    pendingSettlement,
    pendingIncoming,
    pendingReceive,
    pendingTransferIn,
    pendingOutgoing,
    pendingSend,
    pendingTransferOut,
    openOrders,
    tradable,
    withdrawable,
    transferable,
  }: BalancesData
): Balances {
  return {
    total,
    hasPendingBalance,
    formattedTotal: formatters.getAmount(total, currency.decimals),
    formattedTotalWithCurrencyCode: `${total} ${currency.displayCode}`,
    pendingSettlement,
    formattedPendingSettlement: `${
      pendingSettlement.gt(0) ? '-' : ''
    }${formatters.getCurrency(
      pendingSettlement,
      currency.decimals,
      currency.displayCode
    )}`,
    pendingIncoming,
    formattedPendingIncoming: `${pendingIncoming.gt(0) ? '-' : ''}${formatters.getCurrency(
      pendingIncoming,
      currency.decimals,
      currency.displayCode
    )}`,
    pendingReceive,
    formattedPendingReceive: formatters.getCurrency(
      pendingReceive,
      currency.decimals,
      currency.displayCode
    ),
    pendingTransferIn,
    formattedPendingTransferIn: formatters.getCurrency(
      pendingTransferIn,
      currency.decimals,
      currency.displayCode
    ),
    pendingOutgoing,
    formattedPendingOutgoing: `${pendingOutgoing.gt(0) ? '-' : ''}${formatters.getCurrency(
      pendingOutgoing,
      currency.decimals,
      currency.displayCode
    )}`,
    pendingSend,
    formattedPendingSend: formatters.getCurrency(
      pendingSend,
      currency.decimals,
      currency.displayCode
    ),
    pendingTransferOut,
    formattedPendingTransferOut: formatters.getCurrency(
      pendingTransferOut,
      currency.decimals,
      currency.displayCode
    ),
    openOrders,
    formattedOpenOrders: `${openOrders.gt(0) ? '-' : ''}${formatters.getCurrency(
      openOrders,
      currency.decimals,
      currency.displayCode
    )}`,
    tradable,
    formattedTradable: tradable
      ? `${formatters.getAmount(tradable, currency.decimals)} ${currency.displayCode}`
      : undefined,
    withdrawable,
    formattedWithdrawable: `${formatters.getAmount(withdrawable, currency.decimals)} ${currency.displayCode}`,
    formattedTransferable: `${formatters.getAmount(transferable, currency.decimals)} ${currency.displayCode}`,
    // formatters.getCurrency(
    //   withdrawable.toString(),
    //   currency.displayCode,
    //   currency.decimals
    // ),
  };
}

export function createBalancesByAsset(
  asset: EnrichedAccountDetailAsset
): Balances {
  return createBalances(asset.currency, asset.hasPendingBalance, {
    total: BigOrZero(asset.quantityIncludingPending),
    pendingSettlement: BigOrZero(asset.pendingSettlementQuantity),
    pendingIncoming: BigOrZero(asset.pendingIncomingQuantity),
    pendingReceive: BigOrZero(asset.pendingReceiveQuantity),
    pendingTransferIn: BigOrZero(asset.pendingTransferInQuantity),
    pendingOutgoing: BigOrZero(asset.pendingOutgoingQuantity),
    pendingSend: BigOrZero(asset.pendingSendQuantity),
    pendingTransferOut: BigOrZero(asset.pendingTransferOutQuantity),
    openOrders: BigOrZero(asset.openOrdersQuantity),
    tradable: BigOrZero(asset.tradableQuantityDecimal),
    withdrawable: BigOrZero(asset.withdrawableQuantity),
    transferable: BigOrZero(asset.withdrawableQuantity),
  });
}

export function createBalancesByListAccounts(data: API.ListAccounts): Balances {
  return createBalances(
    { displayCode: 'USD', decimals: DEFAULTS.DECIMAL_SCALE },
    data.hasPendingBalance,
    {
      total: BigOrZero(data.totalPortfolioBalanceUsd),
      pendingSettlement: BigOrZero(data.pendingSettlementBalanceUsd),
      pendingIncoming: BigOrZero(data.pendingIncomingBalanceUsd),
      pendingReceive: BigOrZero(data.pendingReceiveBalanceUsd),
      pendingTransferIn: BigOrZero(data.pendingTransferInBalanceUsd),
      pendingOutgoing: BigOrZero(data.pendingOutgoingBalanceUsd),
      pendingSend: BigOrZero(data.pendingSendBalanceUsd),
      pendingTransferOut: BigOrZero(data.pendingTransferOutBalanceUsd),
      openOrders: BigOrZero(data.openOrdersBalanceUsd),
      tradable: BigOrZero(data.tradableBalanceUsd),
      withdrawable: BigOrZero(data.withdrawableBalanceUsd),
      transferable: BigNumber('0'),
    }
  );
}

export function createBalancesByAccount(
  accountDetail: EnrichedAccountDetail
): Balances {
  return createBalances(
    { displayCode: 'USD', decimals: DEFAULTS.DECIMAL_SCALE },
    accountDetail.hasPendingBalance,
    {
      total: BigNumber(accountDetail.totalBalanceIncludingPendingUsd || 0),
      pendingSettlement: BigNumber(
        accountDetail.totalPendingSettlementUsdDecimal
      ),
      pendingIncoming: BigNumber(accountDetail.totalPendingIncomingUsdDecimal),
      pendingReceive: BigNumber(accountDetail.totalPendingReceiveUsdDecimal),
      pendingTransferIn: BigNumber(
        accountDetail.totalPendingIncomingTransferInUsdDecimal
      ),
      pendingOutgoing: BigNumber(accountDetail.totalPendingOutgoingUsdDecimal),
      pendingSend: BigNumber(accountDetail.totalPendingSendUsdDecimal),
      pendingTransferOut: BigNumber(
        accountDetail.totalPendingOutgoingTransferOutUsdDecimal
      ),
      openOrders: BigNumber(accountDetail.totalOpenOrdersUsdDecimal),
      tradable: accountDetail.isAccountOfTypeVault
        ? undefined
        : BigNumber(accountDetail.totalTradableUsdDecimal),
      withdrawable: BigNumber(accountDetail.totalWithdrawableUsd || 0),
      transferable: BigNumber(0),
    }
  );
}
