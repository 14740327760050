import { AppIconType, TIMERS, useCopy, useCopyInterpolater } from 'common';
import { FC, useEffect, useState } from 'react';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '~/store';
import { Widgets } from '.';
import { TRANSITION_PROPS } from '~/constants';
import { Auth0 } from '~/utils/auth0';
import { APP_ROUTES } from '~/routes';
import { useNavigate } from 'react-router-dom';
import { DynamicText } from '@xbto/universal-components';

export const widgetContent = () => {
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);
  const wUSDM = currencies.find(c => c.code === 'WUSDM_ERC20');

  /**
   * Hooks
   */
  const {
    carouselWidgets: { gatewayTowUsdm, investments },
  } = useCopy();
  const gatewayTowUsdmDescription = useCopyInterpolater(
    gatewayTowUsdm.description,
    {
      apy: wUSDM?.apy || 5,
    }
  );
  const navigate = useNavigate();

  /**
   * DOM
   */
  return [
    <Widgets.CustomWidget
      key={gatewayTowUsdm.key}
      icon={gatewayTowUsdm.iconWebApp as unknown as AppIconType}
      title={gatewayTowUsdm.title}
      subText={
        <DynamicText
          parts={gatewayTowUsdmDescription}
          containerProps={{ center: true }}
        />
      }
      ctaText={
        <button
          role="button"
          type="button"
          className="app-button-accent text-sm w-336 pt-4"
          onClick={e => {
            navigate(`${APP_ROUTES.AUTH_ASSET}/${gatewayTowUsdm.goToUrl}`);
          }}
        >
          {gatewayTowUsdm.goToText}
        </button>
      }
      iconSize={52}
      fill="fill-current-color-gradient"
      titleCls="text-base font-bold"
    />,
    <Widgets.CustomWidget
      key={investments.key}
      icon={investments.iconWebApp as unknown as AppIconType}
      title={investments.title}
      subText={
        <DynamicText
          parts={investments.description}
          containerProps={{ center: true }}
        />
      }
      ctaText={
        <button
          role="button"
          type="button"
          className="app-button-accent text-sm w-336 pt-4"
          onClick={() => {
            window.open(investments.goToUrl, '_blank');
          }}
        >
          {investments.goToText}
        </button>
      }
      iconSize={52}
      fill="fill-current-color-gradient"
      titleCls="text-base font-bold"
    />,
  ];
};

export const WidgetUserActions: FC = () => {
  /**
   * Store
   */
  const isKycVerified = DataStore.useStoreState(s => !!s.user.isKycVerified);
  const is2FaEnabled = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.is2FaEnabled
  );

  /**
   * State
   */
  const [items, setItems] = useState<JSX.Element[]>(widgetContent());

  /**
   * Hooks
   */
  useEffect(() => {
    (async () => {
      const newItems: JSX.Element[] = [];
      if (!is2FaEnabled && !(await Auth0.isAuthenticated())) {
        newItems.push(<Widgets.WidgetAdd2Fa key="widget-2fa" />);
      }
      if (!isKycVerified) {
        newItems.push(<Widgets.WidgetGetVerified key="widget-get-verified" />);
      }
      setItems([...items, ...newItems]);
    })();
  }, [is2FaEnabled, isKycVerified]);

  /**
   * DOM
   */
  const timeout = (SW?: Partial<StepWizardChildProps>) =>
    setInterval(() => {
      if (!SW?.nextStep || !SW?.firstStep) return;
      if (SW?.currentStep === SW?.totalSteps) {
        SW?.firstStep();
      } else {
        SW?.nextStep();
      }
      clearInterval(timeout());
    }, TIMERS.COPY_FEEDBACK * 20000);

  return (
    <StepWizard
      transitions={TRANSITION_PROPS}
      instance={timeout}
      onStepChange={() => clearInterval(timeout())}
    >
      {items}
    </StepWizard>
  );
};
