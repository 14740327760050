import { createElement, FC } from 'react';
import cx from 'classnames';
import { BaseComponentProps } from 'common';

const appContainerClasses = 'container mx-auto max-w-screen-xl px-4 sm:px-6';

interface Props extends BaseComponentProps {
  as?: keyof HTMLElementTagNameMap;
  cls?: string;
}

export const AppContainer: FC<Props> = ({
  as = 'div',
  cls = appContainerClasses,
  children,
}) => {
  return createElement(
    as,
    { className: cx(appContainerClasses, cls) },
    children
  );
};
