import { API } from '@xbto/api-client';
import { Enriched } from '../../types';
import { formatters } from '../formatters';
import BigNumber from 'big.js';
import { BigOrZero } from '../big';

export const enrichFiatWithdrawal = (
  withdrawal: API.FiatWithdrawal | API.FiatWithdrawalWithTimeline | null
): Enriched.FiatWithdrawal | null => {
  if (!withdrawal || !withdrawal.amount || !withdrawal.currency?.code) {
    return null;
  }

  const hasTimeline =
    (withdrawal as API.FiatWithdrawalWithTimeline).timeline || undefined;

  const feeWithCurrencyCode = formatters.getCurrency(
    withdrawal?.fee,
    withdrawal.currency.decimals,
    withdrawal.currency.displayCode ?? withdrawal.currency.code
  );
  const amountReceivedWithCurrencyCode = formatters.getCurrency(
    withdrawal?.amount,
    withdrawal.currency.decimals,
    withdrawal.currency.displayCode ?? withdrawal.currency.code
  );

  const fee = BigOrZero(withdrawal.fee);
  const amountSent = BigNumber(withdrawal.amount).add(fee).toFixed();

  const amountSentWithCurrencyCode = formatters.getCurrency(
    amountSent,
    withdrawal.currency.decimals,
    withdrawal.currency.displayCode ?? withdrawal.currency.code
  );

  return {
    ...withdrawal,
    timeline: hasTimeline
      ? (withdrawal as API.FiatWithdrawalWithTimeline).timeline
      : null,
    formatted: {
      fee: formatters.getAmount(withdrawal.fee, withdrawal.currency.decimals),
      feeWithCurrencyCode,
      feeWithCurrencyCodeAndSign: fee.eq(0)
        ? feeWithCurrencyCode
        : formatters.forceSign(feeWithCurrencyCode, '-'),

      amountReceived: formatters.getAmount(
        withdrawal.amount,
        withdrawal.currency.decimals
      ),
      amountReceivedWithCurrencyCode,
      amountReceivedWithCurrencyCodeAndSign: formatters.forceSign(
        amountReceivedWithCurrencyCode,
        '+'
      ),

      amountSent,
      amountSentWithCurrencyCode,
      amountSentWithCurrencyCodeAndSign: formatters.forceSign(
        amountSentWithCurrencyCode,
        '-'
      ),
    },
  };
};
