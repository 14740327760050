import { Platform } from "react-native";

type PlatformValue = number | string;
type PlatformTypography =
  | string
  | {
      fontStyle: string;
      fontVariant: string;
      fontWeight: string;
      lineHeight?: number;
      fontSize: number;
      fontFamily: string;
    };

export const theme = {
  color: {
    brand: {
      "100": "#defff0",
      "200": "#c7ffe5",
      "300": "#90ffcc",
      "400": "#74ffbf",
      "500": "#58ffb2",
      "600": "#46cc8e",
      "700": "#3eb37d",
      "800": "#35996b",
      "900": "#2c8059",
    },
    deep_blue: {
      "100": "#806eca",
      "200": "#473b76",
      "300": "#473b76",
      "400": "#3f3569",
      "500": "#372e5c",
      "600": "#30284f",
      "700": "#282142",
      "800": "#201b35",
      "900": "#181428",
    },
    tertiary: {
      "100": "#fefdfb",
      "200": "#fefcf8",
      "300": "#fdf9f4",
      "400": "#fbf5f0",
      "500": "#f9f1ea",
      "600": "#d6bcab",
      "700": "#b38c75",
      "800": "#90604a",
      "900": "#773f2c",
    },
    light_blue: {
      "100": "#f1f7f8",
      "200": "#e4f0f2",
      "300": "#d7e9ec",
      "400": "#cae1e5",
      "500": "#bcdadf",
      "600": "#afd3d9",
      "700": "#a2cbd2",
      "800": "#95c4cc",
      "900": "#87bdc5",
    },
    blue: {
      "100": "#eef0fc",
      "200": "#c5cbf4",
      "300": "#8c96ea",
      "400": "#6f7ce4",
      "500": "#5262df",
      "600": "#4452ba",
      "700": "#3f4b9c",
      "800": "#293170",
      "900": "#1b214a",
    },
    purple: {
      "100": "#eaddf7",
      "200": "#dcc6f1",
      "300": "#b98ee3",
      "400": "#a771dc",
      "500": "#9655d5",
      "600": "#7d47b1",
      "700": "#64398e",
      "800": "#593a78",
      "900": "#321c47",
    },
    brown: {
      "100": "#ffe5e0",
      "200": "#ffd4cb",
      "300": "#ffe5e0",
      "400": "#fe937c",
      "500": "#fe7d62",
      "600": "#d46852",
      "700": "#bd5f4b",
      "800": "#7f3f31",
      "900": "#552a21",
    },
    green: {
      "100": "#f6fdf7",
      "200": "#a7ffb8",
      "300": "#83ff9b",
      "400": "#5dfa7b",
      "500": "#46d862",
      "600": "#32b64b",
      "700": "#229437",
      "800": "#147226",
      "900": "#0a5018",
    },
    red: {
      "50": "#fef3f2",
      "100": "#fee4e2",
      "200": "#fecdca",
      "300": "#fda29b",
      "400": "#f97066",
      "500": "#f04438",
      "600": "#d92d20",
      "700": "#b42318",
      "800": "#912018",
      "900": "#7a271a",
    },
    yellow: {
      "50": "#fffaeb",
      "100": "#fef0c7",
      "200": "#fedf89",
      "300": "#fec84b",
      "400": "#fdb022",
      "500": "#f79009",
      "600": "#dc6803",
      "700": "#b54708",
      "800": "#93370d",
      "900": "#7a2e0e",
    },
    gray: {
      "100": "#fafafa",
      "200": "#f8f8f8",
      "300": "#ececec",
      "400": "#dbdbdb",
      "500": "#b8b8b8",
      "600": "#959595",
      "700": "#808080",
      "800": "#676767",
      "900": "#434343",
      white: "#ffffff",
    },
    background: {
      brand: "#58ffb2",
      primary: "#181428",
      secondary: "#ececec",
      tertiary: "#fafafa",
      success: "#f6fdf7",
      error: "#fef3f2",
      warning: "#fffaeb",
      neutral: "#434343",
      white: "#ffffff",
      info: "#eef0fc",
    },
    border: {
      primary: "#ececec",
      secondary: "#dbdbdb",
      tertiary: "#181428",
      info: "#5262df",
      success: "#229437",
      error: "#b42318",
      warning: "#f79009",
    },
    text: {
      primary: "#181428",
      secondary: "#676767",
      tertiary: "#b8b8b8",
      info: "#293170",
      success: "#0a5018",
      error: "#7a271a",
      warning: "#93370d",
      inverse: "#ffffff",
      placeholder: "#b8b8b8",
      disabled: "#dbdbdb",
    },
    link: {
      default: "#181428",
      hover: "#201b35",
      active: "#282142",
      visited: "#282142",
    },
    button: {
      primary: {
        default: "#58ffb2",
        hover: "#46cc8e",
        pressed: "#3eb37d",
        focused: "#58ffb2",
      },
      secondary: {
        default: "#181428",
        hover: "#201b35",
        pressed: "#282142",
        focused: "#181428",
      },
      tertiary: {
        default: "#f8f8f8",
        hover: "#ececec",
        pressed: "#b8b8b8",
        focused: "#dbdbdb",
      },
      success: {
        default: "#147226",
        pressed: "#229437",
        hover: "#32b64b",
        focused: "#229437",
      },
      error: {
        default: "#fef3f2",
        hover: "#fee4e2",
        pressed: "#fecdca",
        focused: "#fda29b",
      },
    },
    icon: {
      default: "#181428",
      inverse: "#ffffff",
      disabled: "#b8b8b8",
      error: "#7a271a",
      warning: "#93370d",
      info: "#293170",
      success: "#0a5018",
    },
    input: {
      background: "#ffffff",
      border: { default: "#ececec", hover: "#dbdbdb", active: "#181428" },
    },
  },
  spacing: {
    "1": 4,
    "2": 8,
    "3": 12,
    "4": 16,
    "5": 20,
    "6": 24,
    "7": 28,
    "8": 32,
    "9": 36,
    "10": 40,
    "11": 44,
    "12": 48,
    "14": 56,
    "16": 64,
    "20": 80,
    "24": 96,
    "28": 112,
    "32": 128,
    "36": 144,
    "40": 160,
    "44": 176,
    "48": 192,
    "52": 208,
    "56": 224,
    "60": 240,
    "64": 256,
    "72": 288,
    "80": 320,
    "96": 384,
    "0.5": 2,
    "1.5": 6,
  },
  radius: { sm: 4, md: 8, lg: 16, full: 9999 },
  button: { size: { lg: 48, base: 40, sm: 32, xs: 24 } },
  font: { inter: "Inter" },
  fontweight: {
    regular: Platform.select({android:"400",ios:"400",web:"400px" }),
    medium: Platform.select({android:"500",ios:"500",web:"500px" }),
    semibold: Platform.select({android:"600",ios:"600",web:"600px" }),
    bold: Platform.select({android:"700",ios:"700",web:"700px" }),
  },
  fontsize: {
    "3xl": Platform.select<PlatformValue>({android:40,ios:40,web:"48px" }),
    "2xl": Platform.select<PlatformValue>({android:32,ios:32,web:"40px" }),
    xl: Platform.select<PlatformValue>({android:24,ios:24,web:"32px" }),
    lg: Platform.select<PlatformValue>({android:20,ios:20,web:"24px" }),
    md: Platform.select<PlatformValue>({android:16,ios:16,web:"20px" }),
    base: Platform.select<PlatformValue>({android:14,ios:14,web:"16px" }),
    sm: Platform.select<PlatformValue>({android:12,ios:12,web:"14px" }),
    xs: Platform.select<PlatformValue>({android:10,ios:10,web:"12px" }),
    "2xs": Platform.select<PlatformValue>({android:10,ios:10,web:"10px" }),
  },
  lineheight: {
    "lh-9": Platform.select<PlatformValue>({android:48,ios:48,web:"56px" }),
    "lh-8": Platform.select<PlatformValue>({android:40,ios:40,web:"48px" }),
    "lh-7": Platform.select<PlatformValue>({android:32,ios:32,web:"40px" }),
    "lh-6": Platform.select<PlatformValue>({android:28,ios:28,web:"32px" }),
    "lh-5": Platform.select<PlatformValue>({android:24,ios:24,web:"28px" }),
    "lh-4": Platform.select<PlatformValue>({android:20,ios:20,web:"24px" }),
    "lh-3": Platform.select<PlatformValue>({android:16,ios:16,web:"20px" }),
    "lh-2": Platform.select<PlatformValue>({android:12,ios:12,web:"16px" }),
    "lh-1": Platform.select<PlatformValue>({android:12,ios:12,web:"12px" }),
    "lh-0": Platform.select<PlatformValue>({android:0,ios:0,web:"0px" }),
  },
  typography: {
    "3xl": {
      regular:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":48,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":48,"fontFamily":"Inter"},web:"400 48px/56px Inter" }),
      medium:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":48,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":48,"fontFamily":"Inter"},web:"500 48px/56px Inter" }),
      semibold:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":48,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":48,"fontFamily":"Inter"},web:"600 48px/56px Inter" }),
      bold: Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":48,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":48,"fontFamily":"Inter"},web:"700 48px/56px Inter" }),
    },
    "2xl": {
      regular:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":40,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":40,"fontFamily":"Inter"},web:"400 40px/48px Inter" }),
      medium:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":40,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":40,"fontFamily":"Inter"},web:"500 40px/48px Inter" }),
      semibold:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":40,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":40,"fontFamily":"Inter"},web:"600 40px/48px Inter" }),
      bold: Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":40,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":40,"fontFamily":"Inter"},web:"700 40px/48px Inter" }),
    },
    xl: {
      regular:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":32,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":32,"fontFamily":"Inter"},web:"400 32px/40px Inter" }),
      medium:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":32,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":32,"fontFamily":"Inter"},web:"500 32px/40px Inter" }),
      semibold:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":32,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":32,"fontFamily":"Inter"},web:"600 32px/40px Inter" }),
      bold: Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":32,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":32,"fontFamily":"Inter"},web:"700 32px/40px Inter" }),
    },
    lg: {
      regular:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":24,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":24,"fontFamily":"Inter"},web:"400 24px/32px Inter" }),
      medium:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":24,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":24,"fontFamily":"Inter"},web:"500 24px/32px Inter" }),
      semibold:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":24,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":24,"fontFamily":"Inter"},web:"600 24px/32px Inter" }),
      bold: Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":24,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":24,"fontFamily":"Inter"},web:"700 24px/32px Inter" }),
    },
    md: {
      regular:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":20,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":20,"fontFamily":"Inter"},web:"400 20px/28px Inter" }),
      medium:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":20,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":20,"fontFamily":"Inter"},web:"500 20px/28px Inter" }),
      semibold:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":20,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":20,"fontFamily":"Inter"},web:"600 20px/28px Inter" }),
      bold: Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":20,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":20,"fontFamily":"Inter"},web:"700 20px/28px Inter" }),
    },
    base: {
      regular:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":16,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":16,"fontFamily":"Inter"},web:"400 16px/24px Inter" }),
      medium:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":16,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":16,"fontFamily":"Inter"},web:"500 16px/24px Inter" }),
      semibold:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":16,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":16,"fontFamily":"Inter"},web:"600 16px/24px Inter" }),
      bold: Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":16,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":16,"fontFamily":"Inter"},web:"700 16px/24px Inter" }),
    },
    sm: {
      regular:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":14,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":14,"fontFamily":"Inter"},web:"400 14px/20px Inter" }),
      medium:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":14,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":14,"fontFamily":"Inter"},web:"500 14px/20px Inter" }),
      semibold:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":14,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":14,"fontFamily":"Inter"},web:"600 14px/20px Inter" }),
      bold: Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":14,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":14,"fontFamily":"Inter"},web:"700 14px/20px Inter" }),
    },
    xs: {
      regular:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":12,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","fontSize":12,"fontFamily":"Inter"},web:"400 12px/16px Inter" }),
      medium:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":12,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"500","fontSize":12,"fontFamily":"Inter"},web:"500 12px/16px Inter" }),
      semibold:
        Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":12,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","fontSize":12,"fontFamily":"Inter"},web:"600 12px/16px Inter" }),
      bold: Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":12,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"700","fontSize":12,"fontFamily":"Inter"},web:"700 12px/16px Inter" }),
    },
    "2xs": {
      regular: "400 10px/12px Inter",
      medium: "500 10px/12px Inter",
      semibold: "600 10px/12px Inter",
      bold: "700 10px/12px Inter",
    },
    label: {
      default: {
        semibold:
          Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","lineHeight":14,"fontSize":14,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","lineHeight":14,"fontSize":14,"fontFamily":"Inter"},web:"600 14px/100% Inter" }),
        regular:
          Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","lineHeight":14,"fontSize":14,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","lineHeight":14,"fontSize":14,"fontFamily":"Inter"},web:"400 14px/100% Inter" }),
      },
      sm: {
        semibold:
          Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","lineHeight":12,"fontSize":12,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"600","lineHeight":12,"fontSize":12,"fontFamily":"Inter"},web:"600 12px/100% Inter" }),
        regular:
          Platform.select<PlatformTypography>({android:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","lineHeight":12,"fontSize":12,"fontFamily":"Inter"},ios:{"fontStyle":"normal","fontVariant":"normal","fontWeight":"400","lineHeight":12,"fontSize":12,"fontFamily":"Inter"},web:"400 12px/100% Inter" }),
      },
      xs: { semibold: "600 10px/100% Inter", regular: "400 10px/100% Inter" },
    },
  },
};
