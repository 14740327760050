import { FC, useMemo } from 'react';
import { Auth0 } from '~/utils/auth0';
import { AppTabs } from '../app-tabs/app-tabs';
import { SettingsSecurityAuth02faWizard } from './settings-security-auth0-2fa-wizard';
import { SettingsSecurityAuth02ChangePassword } from './settings-security-auth0-change-password';

const securitySettingsTabs = {
  Password: 'password',
  TwoFactorAuthentication: '2fa',
};

enum SECURITY_TAB {
  ChangePassword = 'Change password',
  TwoFactorAuthentication = 'Two-factor authentication',
}

export const SettingsSecurityOverview: FC = () => {
  /**
   * DOM
   */
  const panels = useMemo(() => {
    return [
      <SettingsSecurityAuth02ChangePassword
        key={SECURITY_TAB.ChangePassword}
      />,
      <SettingsSecurityAuth02faWizard
        key={SECURITY_TAB.TwoFactorAuthentication}
      />,
    ];
  }, []);

  return (
    <div>
      {/* change password */}
      <AppTabs
        applyParams
        headerContainerCls="px-8 pt-1.5 h-20"
        headerItemCls="flex flex-col justify-center"
        panelContainerCls="px-8"
        tabs={[
          <div
            key={SECURITY_TAB.ChangePassword}
            className="font-bold text-base md:text-xl"
          >
            {SECURITY_TAB.ChangePassword}
          </div>,
          <div
            key={SECURITY_TAB.TwoFactorAuthentication}
            className="font-bold text-base md:text-xl"
          >
            {SECURITY_TAB.TwoFactorAuthentication}
          </div>,
        ]}
        panels={panels}
      />
    </div>
  );
};
