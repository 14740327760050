var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { _MarketListItem } from './_market-list-item';
import { YieldLabel } from '../../organisms/yield-label';
import { LabeledValueList } from '../../molecules/labeled-value-list';
import { AmountPercent } from '../../organisms/amount-percent';
import { DateMaturity } from '../../organisms/date-maturity';
import { LabeledValue } from '../../molecules/labeled-value';
import { AmountPrice } from '../../organisms/amount-price';
export const MarketListItemBond = memo((_a) => {
    var { data, description, displayCode } = _a, rest = __rest(_a, ["data", "description", "displayCode"]);
    if (!data)
        return null;
    return (_jsx(_MarketListItem, Object.assign({}, rest, { apy: null, displayCode: displayCode, subtitle: description, addon: data.yieldPercent ? (_jsx(YieldLabel, { value: data.yieldPercent })) : undefined, footer: _jsxs(LabeledValueList, { children: [_jsx(LabeledValue, { label: "Maturity", value: _jsx(DateMaturity, { em: true, value: data.maturity }) }), _jsx(LabeledValue, { label: "Coupon", value: _jsx(AmountPercent, { em: true, value: data.couponPercent }) }), _jsx(LabeledValue, { label: "Price", value: _jsx(AmountPrice, { em: true, value: data.price }) })] }) })));
});
