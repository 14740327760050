import { API } from '@xbto/api-client';
import BigNumber from 'big.js';
import { EnrichedFxRate } from '../types';
import { getPercentageChange } from './get-percentage-change';
import { DEFAULTS } from '../constants';
import { formatters } from './formatters';

function formatPercentage(value: number) {
  return `${value < 0 ? '-' : '+'}${formatters.getAmount(BigNumber(value).abs(), 1)}%`;
}

function getFiatFormattedValue(
  amount: string | BigNumber | null | undefined,
  decimals: number
) {
  return formatters.getCurrency(amount, decimals, 'USD');
}

export const getFxRate = (
  ccyCode: string,
  fxRates: API.FxRate[],
  decimals: number
): EnrichedFxRate => {
  const fallbackResult: EnrichedFxRate = {
    _rate: '1',
    rate: BigNumber('1'),
    rateFormatted: formatters.getAmount(BigNumber('1'), DEFAULTS.DECIMAL_SCALE),
    rateWithCurrencyCodeFormatted: getFiatFormattedValue(
      '1',
      DEFAULTS.DECIMAL_SCALE
    ),

    rate24HoursAgo: BigNumber('1'),
    rate24HoursAgoFormatted: formatters.getAmount(
      BigNumber('1'),
      DEFAULTS.DECIMAL_SCALE
    ),
    rate24HoursAgoFormattedUsd: getFiatFormattedValue(
      '1',
      DEFAULTS.DECIMAL_SCALE
    ),
    rate24HoursAgoValueChange: BigNumber('0'),
    rate24HoursAgoValueChangeFormatted: `+${formatters.getAmount(BigNumber('0'), decimals)}`,
    rate24HoursAgoValueChangeFormattedUsd: `+${getFiatFormattedValue(
      '0',
      decimals
    )}`,
    rate24HoursAgoPercentageChange: getPercentageChange(1, 1),
    rate24HoursAgoPercentageChangeFormatted: formatPercentage(0),

    rate7DaysAgo: BigNumber('1'),
    rate7DaysAgoFormatted: formatters.getAmount(
      BigNumber('1'),
      DEFAULTS.DECIMAL_SCALE
    ),
    rate7DaysAgoFormattedUsd: getFiatFormattedValue(
      '1',
      DEFAULTS.DECIMAL_SCALE
    ),
    rate7DaysAgoValueChange: BigNumber('0'),
    rate7DaysAgoPercentageChange: getPercentageChange(1, 1),

    rate1MonthAgo: BigNumber('1'),
    rate1MonthAgoFormatted: formatters.getAmount(
      BigNumber('1'),
      DEFAULTS.DECIMAL_SCALE
    ),
    rate1MonthAgoFormattedUsd: getFiatFormattedValue(
      '1',
      DEFAULTS.DECIMAL_SCALE
    ),
    rate1MonthAgoValueChange: BigNumber('0'),
    rate1MonthAgoPercentageChange: getPercentageChange(1, 1),
  };

  if (ccyCode === 'USD') {
    return fallbackResult;
  }

  // Note: All rates are quoted in USD
  const match = fxRates.find(({ base }) => {
    return base === ccyCode;
  });

  if (!match) {
    return fallbackResult;
  }

  try {
    const rate = BigNumber(match.rate || 1);
    const rate24HoursAgo = match.rate24HoursAgo
      ? BigNumber(match.rate24HoursAgo)
      : null;
    const rate7DaysAgo = match.rate7DaysAgo
      ? BigNumber(match.rate7DaysAgo)
      : null;
    const rate1MonthAgo = match.rate1MonthAgo
      ? BigNumber(match.rate1MonthAgo)
      : null;

    const rate24HoursAgoPercentageChange = rate24HoursAgo
      ? getPercentageChange(rate24HoursAgo, rate)
      : null;

    const rate24HoursAgoValueChange = rate24HoursAgo
      ? rate.sub(rate24HoursAgo)
      : null;
    const rate24HoursAgoValueChangeSign = rate24HoursAgoValueChange?.lt(0)
      ? '-'
      : '+';

    return {
      _rate: match.rate || '1',
      rate,
      rateFormatted: formatters.getAmount(rate.toFixed(), decimals),
      rateWithCurrencyCodeFormatted: getFiatFormattedValue(
        rate.toFixed(),
        decimals
      ),

      rate24HoursAgo,
      rate24HoursAgoFormatted: formatters.getAmount(
        rate24HoursAgo?.toFixed(),
        decimals
      ),
      rate24HoursAgoFormattedUsd: getFiatFormattedValue(
        rate24HoursAgo?.toFixed(),
        decimals
      ),
      rate24HoursAgoValueChange,
      rate24HoursAgoValueChangeFormatted: `${rate24HoursAgoValueChangeSign}${formatters.getAmount(
        rate24HoursAgoValueChange?.abs().toFixed(),
        decimals
      )}`,
      rate24HoursAgoValueChangeFormattedUsd: `${rate24HoursAgoValueChangeSign}${getFiatFormattedValue(
        rate24HoursAgoValueChange?.abs().toFixed(),
        decimals
      )}`,
      rate24HoursAgoPercentageChange,
      rate24HoursAgoPercentageChangeFormatted: formatPercentage(
        rate24HoursAgoPercentageChange ?? 0
      ),

      rate7DaysAgo,
      rate7DaysAgoFormatted: formatters.getAmount(
        rate7DaysAgo?.toFixed(),
        decimals
      ),
      rate7DaysAgoFormattedUsd: getFiatFormattedValue(
        rate7DaysAgo?.toFixed(),
        decimals
      ),
      rate7DaysAgoValueChange: rate7DaysAgo ? rate.sub(rate7DaysAgo) : null,
      rate7DaysAgoPercentageChange: rate7DaysAgo
        ? getPercentageChange(rate7DaysAgo, rate)
        : null,

      rate1MonthAgo,
      rate1MonthAgoFormatted: formatters.getAmount(
        rate1MonthAgo?.toFixed(),
        decimals
      ),
      rate1MonthAgoFormattedUsd: getFiatFormattedValue(
        rate1MonthAgo?.toFixed(),
        decimals
      ),
      rate1MonthAgoValueChange: rate1MonthAgo ? rate.sub(rate1MonthAgo) : null,
      rate1MonthAgoPercentageChange: rate1MonthAgo
        ? getPercentageChange(rate1MonthAgo, rate)
        : null,
    };
  } catch (error) {
    console.error(`Error: getFxRate > error with fraction conversion`, error);
    return fallbackResult;
  }
};
