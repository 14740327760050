import { FC, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import {
  useComputedConfig,
  useSystemConfig,
  getThemeForTenant,
  AppTenant,
} from 'common';
import { useLocation } from 'react-router-dom';
import { appStorage } from '../utils/app-storage';
import { AppStore, DataStore } from '../store';

import { AppRoot } from './root';
import { APP_ROUTES } from '~/routes';

export const AppHydrator: FC = () => {
  /**
   * Store
   */
  const setCurrentViewName = DataStore.useStoreActions(
    s => s.setCurrentViewName
  );
  const accountIds = DataStore.useStoreState(s => s.portfolio.accountIds);
  const identity = DataStore.useStoreState(s => s.user.identity);
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);

  const initPusher = AppStore.useStoreActions(_ => _.initPusher);
  const { getIdentity, setAccessToken } = DataStore.useStoreActions(a => ({
    getIdentity: a.user.getIdentity,
    setAccessToken: a.user.setAccessToken,
  }));

  const { pusherCluster, pusherKey } = useSystemConfig();
  const { envName } = useSystemConfig();

  /**
   * State
   */
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);
  const [isHydrated, setIsHydrated] = useState(false);
  const [isPusherInitialized, setIsPusherInitialized] = useState(false);
  const isReady = isThemeLoaded && isHydrated && isPusherInitialized;

  /**
   * Vars
   */
  const location = useLocation();
  const { tenant } = useComputedConfig();

  /**
   * Effects
   */
  useEffectOnce(() => {
    async function hydrate() {
      try {
        const localAccessToken = await appStorage.getAccessToken();

        if (localAccessToken) {
          setAccessToken(localAccessToken);

          // Get identity before marking as hydrated
          await getIdentity();
        } else {
          setAccessToken(null);
        }
      } catch (err) {
        // 401
        setAccessToken(null);
      } finally {
        setIsHydrated(true);
      }
    }

    // Init
    hydrate();
  });

  useEffect(() => {
    if (!tenant) {
      return;
    }
    const themeName = getThemeForTenant(tenant as AppTenant);
    import(`common/dist/theme/${themeName}/index.scss`)
      .then(() => {
        setIsThemeLoaded(true);
      })
      .catch(error => {
        console.error(`Could not load theme for tenant - ${tenant}: ${error}`);
      });
  }, [tenant]);

  useEffect(() => {
    if (isHydrated) {
      if (isAuthenticated && !!identity && !!accountIds.length) {
        initPusher({
          pusherCluster,
          pusherKey,
          env: envName,
          accountIds,
          userId: identity.id,
        });
      }
      setIsPusherInitialized(true);
    }
  }, [isAuthenticated, isHydrated, identity, accountIds, initPusher]);

  useEffect(() => {
    const viewName = location.pathname;
    if (viewName === APP_ROUTES.AUTH_MARKETS) {
      setCurrentViewName('markets-list');
      return;
    } else if (viewName.includes(APP_ROUTES.AUTH_ASSET)) {
      setCurrentViewName('asset-details');
      return;
    }
    setCurrentViewName('other');
  }, [location.pathname, setCurrentViewName]);

  /**
   * DOM
   */
  if (!isReady) {
    return null;
  }
  return <AppRoot />;
};
