import { FC } from 'react';
import { AppIcon, Balances, Note, useCopy } from 'common';
import { FeeInfoTooltip } from './fee-info-tooltip';
import { BalanceBreakdownProps } from '~/components/portfolio/balance-breakdown';

interface Props {
  availableToTitle?: string;
  balances: Balances | null;
  isTransfer?: boolean;
}

export const AssetBalanceBreakdown: FC<
  Props & Pick<BalanceBreakdownProps, 'title' | 'breakdownType'>
> = ({
  availableToTitle = 'Available to send now',
  balances,
  breakdownType,
  isTransfer,
}) => {
  /**
   * Hooks
   */
  const {
    portfolio: { tradingNotice },
  } = useCopy();

  /**
   * DOM
   */
  if (!balances) {
    return null;
  }
  const formattedValue =
    breakdownType === 'pendingIncoming'
      ? balances.formattedTradable
      : isTransfer
        ? balances.formattedTransferable
        : balances.formattedWithdrawable;
  const shouldRender = balances.hasPendingBalance || formattedValue;
  if (!shouldRender) {
    return null;
  }
  return (
    <Note cls="flex justify-between justify-center items-center">
      <div className="flex flex-col">
        {formattedValue && (
          <span className="text-grey-darker">
            {availableToTitle}: <b className="text-primary">{formattedValue}</b>
          </span>
        )}
      </div>
      <FeeInfoTooltip
        tooltipWidth={354}
        icon={
          <>
            {balances?.hasPendingBalance && (
              <AppIcon
                size={20}
                bg="bg-transparent"
                icon="information-circle"
              />
            )}
          </>
        }
        title={tradingNotice?.title}
        content={tradingNotice.content}
      />
    </Note>
  );
};
