import { TradeFormValues, TradeFormValuesErrors } from './types';
import { ERROR_MESSAGES } from '../../constants';
import { API } from '@xbto/api-client';
import { BigOrZero } from '../../utils';

const checkValues = (
  values: TradeFormValues
): {
  hasErrors: boolean;
  errors: TradeFormValuesErrors;
  firstError: string | null;
} => {
  const { fromAsset, fromAmount, toAmount, side, toAsset } = values;

  const errors: TradeFormValuesErrors = {
    fromAmount: null,
    toAmount: null,
  };

  const isSell = side === API.Side.Sell;

  // from fund
  if (isSell && fromAsset) {
    if (!fromAsset.hasBalance) {
      errors.fromAmount = ERROR_MESSAGES.INSUFFICIENT_BALANCE;
    }
  }

  if (!isSell && toAsset) {
    if (!toAsset.hasBalance) {
      errors.fromAmount = ERROR_MESSAGES.INSUFFICIENT_BALANCE;
    }
  }

  /**
   * TODO
   * There are some bugs with understanding mobile app number that comes in to be parsed for validations
   */
  if (isSell && fromAmount !== null && BigOrZero(fromAmount).lte(0)) {
    errors.fromAmount = ERROR_MESSAGES.AMOUNT_MORE_THAN_0;
  }

  if (!isSell && toAmount !== null && BigOrZero(toAmount).lte(0)) {
    errors.toAmount = ERROR_MESSAGES.AMOUNT_MORE_THAN_0;
  }

  const hasErrors = Object.values(errors).some(e => !!e);
  const [firstError = null] = Object.entries(errors)
    .map(([, v]) => v)
    .filter(v => v);
  return { hasErrors, errors, firstError };
};

const hasRequiredValues = (values: TradeFormValues) => {
  const { fromAsset, toAsset, side } = values;

  if (fromAsset && toAsset && side) {
    return true;
  }
  return false;
};

export const tradeValidations = {
  hasRequiredValues,
  checkValues,
};
