import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { _KytWrapper } from './_kyt-wrapper';
import { Button, Col, TextInput } from '../../atoms';
import { useInputValueManager } from '../../hooks';
import { defaultWidthForInputsAndButtons } from './shared';
import { StyleSheet } from 'react-native';
import { sizes } from '../../theme';
import { nameSchema } from '../../utils/validation/validate-schema';
import { ValidationProvider } from '../../utils/validation/validation-context';
import { KytLearnMore } from '../../organisms/kyt-learn-more';
const KytOwnerIndividualInner = ({ onNext, }) => {
    /**
     * Hooks
     */
    const { value: firstName, onValueChanged: onFirstNameChanged } = useInputValueManager();
    const { value: lastName, onValueChanged: onLastNameChanged } = useInputValueManager();
    /**
     * Methods
     */
    const handleOnNext = useCallback(() => {
        onNext({ firstName, lastName });
    }, [firstName, lastName, onNext]);
    /**
     * DOM
     */
    return (_jsx(Col, Object.assign({ style: $.container }, { children: _jsx(ValidationProvider, { children: ({ validateField, hasErrors }) => {
                const isNextDisabled = firstName.length <= 0 || lastName.length <= 0 || hasErrors;
                return (_jsxs(_Fragment, { children: [_jsx(TextInput, { placeholder: "First name", style: defaultWidthForInputsAndButtons, value: firstName, onValueChange: onFirstNameChanged, validate: validateField, schema: nameSchema }), _jsx(TextInput, { placeholder: "Last name", style: defaultWidthForInputsAndButtons, value: lastName, onValueChange: onLastNameChanged, validate: validateField, schema: nameSchema }), _jsx(Button, { variant: "secondary", label: "Next", disabled: isNextDisabled, onPress: handleOnNext })] }));
            } }) })));
};
export const KytOwnerIndividual = ({ onNext }) => {
    /**
     * DOM
     */
    return (_jsxs(_KytWrapper, Object.assign({ title: "Enter the name of the wallet owner", subtitle: "To adhere to rules within your jurisdiction we need to know who owns the wallet you are withdrawing to" }, { children: [_jsx(KytOwnerIndividualInner, { onNext: onNext }), _jsx(KytLearnMore, {})] })));
};
const $ = StyleSheet.create({
    container: {
        marginBottom: sizes.xxl,
        rowGap: sizes.xs,
    },
});
