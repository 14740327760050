import { LOCAL_STORAGE_KEYS, AppStorage } from 'common';

export const appStorage: AppStorage = {
  async getAccessToken() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  },
  async setAccessToken(token: string | null) {
    if (token) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    }
  },
  alerts: {
    get: () => localStorage.getItem('stablehouse-alerts'),
    set: (alerts: string) => localStorage.setItem('stablehouse-alerts', alerts),
    remove: () => localStorage.removeItem('stablehouse-alerts'),
  },
};
