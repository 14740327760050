import { API } from '@xbto/api-client';
import { Enriched } from '../../types';
import { formatters } from '../formatters';
import BigNumber from 'big.js';
import { activitySign } from '../get-activity-sign';

export const enrichFiatDeposit = (
  deposit: API.FiatDeposit | null
): Enriched.FiatDeposit | null => {
  if (!deposit || !deposit.amount || !deposit.currency?.code) {
    return null;
  }

  const amountSent = BigNumber(deposit.amount).toFixed();
  const amountWithCurrencyCode = formatters.getCurrency(
    amountSent,
    deposit.currency.decimals,
    deposit.currency.displayCode ?? deposit.currency.code,
    true
  );

  return {
    ...deposit,
    formatted: {
      amount: formatters.getAmount(deposit.amount, deposit.currency.decimals),
      amountWithCurrencyCode,
      amountUsdWithCurrencyCode: formatters.getBaseCurrency(amountSent),
      amountWithCurrencyCodeAndSign: formatters.forceSign(
        amountWithCurrencyCode,
        activitySign[API.ActivityType.FiatDeposit]
      ),
    },
  };
};
