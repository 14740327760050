import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { theme } from '@xbto/design-system';
import { sizes } from '../../theme';
export function ListItem({ children, disabled = false, id, onPress }) {
    // Handlers
    const handlePress = useCallback(() => {
        onPress === null || onPress === void 0 ? void 0 : onPress(id);
    }, [id, onPress]);
    // Render
    return (_jsx(Pressable, Object.assign({ disabled: disabled && !onPress, onPress: handlePress, style: ({ pressed }) => [
            $.container,
            pressed && $.pressed,
            disabled && $.disabled,
        ] }, { children: children })));
}
const $ = StyleSheet.create({
    container: {
        backgroundColor: theme.color.background.white,
        borderBottomWidth: 1,
        borderColor: theme.color.border.primary,
        padding: sizes.m,
    },
    pressed: {
        backgroundColor: theme.color.background.tertiary,
    },
    disabled: {
        opacity: 0.4,
    },
});
