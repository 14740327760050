import { API } from '@xbto/api-client';
import { Enriched } from '../../types';
import { format as formatDateFns, addMinutes } from 'date-fns';
import { DATE_FORMATS } from '../../constants';
import { BigOrNull, BigOrZero } from '../big';

export function formatDate(date: Date, format: string, toUtc?: boolean) {
  if (toUtc) {
    return formatDateFns(addMinutes(date, date.getTimezoneOffset()), format);
  }
  return formatDateFns(date, format);
}

export function enrichListAssetDetails(
  assets: API.ListAssetDetailsResponse[]
): Enriched.MarketDataRow[] {
  const result: Enriched.MarketDataRow[] = [];

  for (const asset of assets) {
    if (
      !asset.code ||
      !asset.displayCode ||
      !asset.name ||
      !asset.type ||
      !asset.data
    ) {
      // TODO(@jey) - log to DD, so we can notify BE of a wrongly configured asset
      continue;
    }

    const cryptoMarketDetail = asset.data?.cryptoMarketDetail;
    const fiatMarketDetail = asset.data?.fiatMarketDetail;
    const fundMarketDetail = asset.data?.fundMarketDetail;
    const bondMarketDetail = asset.data?.bondMarketDetail;

    // handle Pusher sending string instead of Date
    const valueAsOf =
      typeof asset.valueAsOf === 'string'
        ? new Date(asset.valueAsOf)
        : asset.valueAsOf;

    const data: Enriched.MarketDataRow['data'] = {
      cryptoMarketDetail: !cryptoMarketDetail
        ? null
        : {
            price: BigOrZero(cryptoMarketDetail.formattedPrice),
            change24hPercent: BigOrZero(cryptoMarketDetail.change24hPercent),
            volume24h: BigOrNull(cryptoMarketDetail.volume24h),
            marketCap: BigOrNull(cryptoMarketDetail.marketCap),
          },
      fiatMarketDetail: !fiatMarketDetail
        ? null
        : {
            price: BigOrZero(fiatMarketDetail.formattedPrice),
            change24hPercent: BigOrZero(fiatMarketDetail.change24hPercent),
          },
      fundMarketDetail: !fundMarketDetail
        ? null
        : {
            ...fundMarketDetail,
            mtdRoR: BigOrZero(fundMarketDetail.mtdRoR),
            ytdRoR: BigOrZero(fundMarketDetail.ytdRoR),
            itdRoR: BigOrZero(fundMarketDetail.itdRoR),
            maxMonthlyDrawdown: BigOrZero(fundMarketDetail.maxMonthlyDrawdown),
          },
      bondMarketDetail: !bondMarketDetail
        ? null
        : {
            ...bondMarketDetail,
            yieldPercent: BigOrZero(bondMarketDetail.yieldPercent),
            couponPercent: BigOrZero(bondMarketDetail.couponPercent),
            price: BigOrZero(bondMarketDetail.formattedPrice),
            issuanceAmount: BigOrNull(bondMarketDetail.issuanceAmount),
          },
    };

    result.push({
      apy: BigOrZero(asset.apy),
      valueAsOf: valueAsOf
        ? 'Value as of ' + formatDate(valueAsOf, DATE_FORMATS.DD_MMM_YYYY, true)
        : '',
      code: asset.code,
      name: asset.name,
      displayCode: asset.displayCode,
      data,
      type: asset.type,
      isTrending: asset.isTrending,
    });
  }

  return result;
}
