import { API } from '@xbto/api-client';
import { format as formatDate, startOfMonth } from 'date-fns';
import { EnrichedCurrencyInformation, EnrichedInvoice } from '../../types';
import BigNumber from 'big.js';
import { formatters } from '../formatters';

export function createEnrichedInvoice(
  _invoice: API.Invoice,
  currencies: EnrichedCurrencyInformation[]
): EnrichedInvoice {
  const amount = _invoice.amount || '0';

  const quoteCurrencyCode = _invoice.quoteAssetCode;
  const quoteCurrency = quoteCurrencyCode
    ? currencies.find(_currency => _currency.code === quoteCurrencyCode)
    : undefined;

  const formattedAmount = quoteCurrency
    ? formatters.getCurrency(
        amount,
        quoteCurrency.decimals,
        quoteCurrency.displayCode
      )
    : formatters.getBaseCurrency(amount);

  return {
    ..._invoice,
    amount: BigNumber(amount),
    formattedAmount,
    formattedDueMonth: formatDate(
      startOfMonth(new Date(_invoice.dueDate)),
      'MMMM yyyy'
    ),
    isPayable: _invoice.status === API.InvoiceStatus.WaitingPayment,
    isPaid: _invoice.status === API.InvoiceStatus.Paid,
  };
}
